//////////////////////////////////
/// PAGE: LOGIN, SIGNUP PAGES ///
//////////////////////////////////
.auth-bg {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    &::after {
        content: '';
        display: block;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        z-index: 1;
        height: 100%;
        background-color: rgba(#3E3435, .9);
    }
}

.auth-outer-wrapper {
    padding: 60px 0;
    position: relative;
    z-index: 9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.auth-wrapper {
    width: 420px;
    max-width: 90%;
    margin: auto;

    &.signup-wrapper {
        width: 450px;
    }

    .auth-header {
        background-color: #fff;
        padding: 20px 30px;
        border-bottom: 4px solid $primary-color;
        text-align: center;
        @include border-radius(4px 4px 0 0);
        @include box-shadow(0 2px 10px 2px rgba(black, 0.22));

        .auth-logo {
            display: inline-block;
            width: 130px;
            vertical-align: middle;
        }
    }

    .auth-content-block {
        padding: 20px 30px 35px;
        background-color: #fff;
        @include border-radius(0 0 4px 4px);
        @include box-shadow(0 2px 10px 2px rgba(black, 0.22));
    }

    .welcome-block {
        text-align: center;
        margin-bottom: 20px;

        .welcome-title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 5px;
        }

        .welcome-para {
            font-size: 14px;
            color: #777;
            max-width: 350px;
            margin: auto;
        }
    }

    .auth-form-inner {
        border: solid 1px #dddddd;
        @include border-radius(6px);
        margin-bottom: 20px;

        .form-item {
            border-bottom: solid 1px #dddddd;
            padding: 6px 20px;
            position: relative;
            text-align: left;

            &:last-child {
                border-bottom: none;
            }

            &.has-prefix-icon {
                padding: 6px 20px 6px 65px;

                .input-label {
                    padding-left: 65px;
                }
            }

            .icon-block {
                position: absolute;
                left: 20px;
                top: 50%;
                margin-top: -10px;
                font-size: 20px;
                color: $sub-font-color;
            }

            .input-block {
                border: none;
                height: 44px;
                line-height: 34px;
                width: 100%;
                background-color: transparent;
                outline: none;
                position: relative;
                z-index: 2;
                padding-top: 18px;
                @include box-shadow(none);
                outline: none;

                &:-webkit-autofill,
                &:-webkit-autofill:hover,
                &:-webkit-autofill:focus,
                &:-webkit-autofill:active {
                    box-shadow: 0 0 0 30px white inset !important;
                    -webkit-box-shadow: 0 0 0 30px white inset !important;
                }
            }

            .input-label {
                position: absolute;
                left: 0;
                top: 50%;
                padding-left: 20px;
                margin-top: -10px;
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                z-index: 1;
                color: $sub-font-color;
                @include transition(all 0.2s linear);
            }

            .mandatory {
                color: #ed1c24;
                font-weight: 500;
            }

            .input-block:focus+.input-label,
            .input-block:valid+.input-label,
            .input-block.has-value+.input-label {
                margin-top: -18px;
                font-size: 12px;
                z-index: 3;
            }

            .show-password-btn {
                position: absolute;
                right: 10px;
                top: 50%;
                width: 30px;
                height: 30px;
                margin-top: -15px;
                font-size: 20px;
                color: rgba($sub-font-color, .5);
                z-index: 2;

                &:hover,
                &.active {
                    color: $sub-font-color;
                }
            }

            .password-input-block {
                padding-right: 25px;
            }
        }

        .grid-2 {
            font-size: 0;

            .form-item {
                display: inline-block;
                width: 50%;
                font-size: 14px;
                vertical-align: top;

                +.form-item {
                    border-left: 1px solid #dddddd;
                    border-bottom: 1px solid #dddddd;
                }
            }
        }
    }

    .auth-error-msg {
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 15px;
        margin-top: -10px;
        color: $error-color;
    }

    .c-checkbox {
        font-size: 13px;
        color: $sub-font-color;
    }

    .fogot-password-btn {
        font-size: 13px;
        // float: right;
        margin-top: 3px;
        color: $sub-font-color;

        &:hover {
            text-decoration: underline;
            color: #666;
        }
    }

    .submit-btn {
        margin-top: 30px;
        font-size: 16px;
        height: 46px;
        font-weight: 700;
        width: 100%;
    }

    .auth-alternative {
        padding-top: 30px;
        font-size: 14px;
        color: $sub-font-color;
        text-align: center;
    }

    .auth-footer {
        text-align: center;
        font-size: 12px;
        color: #fff;
        padding: 15px 5px 0;
        font-weight: normal;

        .copy-right-text,
        .seperator,
        .copy-right-link {
            display: inline-block;
            vertical-align: middle;
            margin: 0 2px;
        }

        .copy-right-link {
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }
    .captcha-wrapper{
        iframe {
            height: 90px;
        }
    }
}
.ant-modal-confirm-info{
    .ant-modal-confirm-title{
        font-weight: 600;
        font-size: 17px;
    }
}
.welcome-popup{
    margin-left: -35px;
    padding-top: 15px;
    p{
        font-size: 14px;
    }
    h3{
        font-weight: 600;
        font-size: 16px;
        margin: 15px 0;
    }
    .welcome-image {
        padding: 15px;
        background-color: #efefef;
        margin-bottom: 30px;
        @include border-radius(10px);
        border: solid 1px #eaeaea;
    }
    ul{
        margin-bottom: 25px;
        li{
            padding-left: 25px;
            margin-bottom: 15px;
            position: relative;
            &:before{
                width: 7px;
                height: 7px;
                display: block;
                content: "";
                position: absolute;
                left: 0;
                top: 6px;
                background: rgba(0,0,0,.65);
                @include border-radius(6px);
            }
        }
    }
}

// @media (min-width: 1500px){
//     .auth-bg{ 
//         .auth-outer-wrapper{ 
//             margin: 0;  
//             height: 100%;
//         }
//         .auth-wrapper{
//             position: fixed;
//             max-height: 90%; 
//             left: 50%;
//             top: 50%;
//             @include transform(translateX(-50%) translateY(-50%));
//         }
//     }
/// }

//OTP MFA
.otp-form-wrapper{
    .otp-wrapper{
        display: flex;
        align-items: center;
    }
    .otp-input-field{
        flex: 1;
        margin: 0 4px;
        height: 50px;
        font-size: 20px;
    }
    .otp-counter{
        font-size: 16px;
        color: $primary-color;
        margin-top: 20px;
        margin-bottom: -20px;
    }
}