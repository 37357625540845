/////////////////////////////////////////
/// Components: Todo List Widget ///
/////////////////////////////////////////
 
.todo-list-widget{
    padding: 20px;
    .todo-list-wrap{
        position: relative;
        &::before{
            content: '';
            position: absolute;
            left: 8px;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: #ddd;
        }
    }
    .todo-list-item{
        position: relative;
        padding-bottom: 10px;
        padding-left: 35px;
        padding-right: 15px;
        &:last-child{
            padding-bottom: 3px;
            &::after{
                content: '';
                width: 12px;
                height: 12px;
                @include border-radius(50%);
                border: solid 2px #fff; 
                background-color: #ddd;
                position: absolute;
                left: 3px;
                bottom: 0;
            }
        }
        .timeline-pin{
            width: 18px;
            height: 18px;
            @include border-radius(50%);
            border: solid 2px #ddd;
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 0;
            font-size: 6px;
            color: $sub-font-color;
            line-height: 1.3;
            text-align: center;
            padding-top: 2px;
            cursor: pointer;
            &:hover{
                span::before{
                    content: "\e91c";
                }
            }
            .hidden-input{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                @include visibility(hidden);
                &:checked + span::before{
                    content: "\e91c";
                }
            }
            span::before{
                content: "";
                @include transition(all 0.2s linear);
                @include iconFont();
                font-size: 8px;
                line-height: 1.3;
            }
        }
        .highlighted{
            border-color: $primary-color;
            color: $primary-color;
        }
        .date-block{
            font-size: 12px;
            font-weight: 500;
            font-family: $semibold-font;
            display: block;
            position: relative;
            top: -3px;
            color: $sub-font-color;
        }
        .date-today{
            color: #5cb400 ;
        }
        .later-week{
            color: #ff9400 ;
        }
        .after-week{
            color: #0886ca ;
        }
        .todo-list-content{
            margin-bottom: 7px;
            position: relative;
            padding-left: 30px;
            display: block;
            color: $default-font-color;
            &:hover{
                text-decoration: none;
                color: #222;
            }
            .icon-block{
                position: absolute;
                left: 0;
                top: 4px;
                font-size: 16px;
                color: $sub-font-color;
            }
            .text-block{
                font-size: 14px;
            }
        }
    }
}