//fonts
$FSMe                   : "FS Me", 'Helvetica Neue', "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;
$HelveticaNeue          : 'Helvetica Neue', "Chinese Quote", -apple-system, BlinkMacSystemFont, "Segoe UI", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "Helvetica Neue", Helvetica, Arial, sans-serif,
"Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default; 


// ============== Define colors ============

//base colors   
$primary-color          : #E20612 !default;  
$primary-color-dark     : #da3731 !default;  

//text colors
$default-font-color     : #2c2c2c !default; 
$sub-font-color         : #888 !default;
$title-font-color       : #272727 !default; 
$link-color             : #E20612 !default;
$border-color           : #e4e4e4 !default;
$text-color             : #586581 !default;
$error-color            : #fb7474 !default;
  
//Fonts
$font-family-base       : $FSMe !default;
$title-font             : $FSMe !default; 
$paragraph              : $FSMe !default; 
$semibold-font          : $FSMe !default; 
$icon-font              : 'jointlyIcons' !default;


//background color
$bg-color               : #f7feff !default; 
$bg-dark                : #efefef !default; 

//button colors
$primary-button         : $primary-color !default;
$secondary-button       : #fff !default;

//radius
$radius                 : 4px !default;
$radius-large           : 5px !default;
$radius-small           : 3px !default; 


//header settings
$header-size            : 50px;
