///////////////////////////////////
/// Components: Comments Widget ///
///////////////////////////////////

.comments-widget {
    padding: 20px;

    .total-comments {
        font-size: 14px;
        font-weight: 600;
        padding-bottom: 10px;
        border-bottom: 1px solid #dddddd;
    }

    .comments-header{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .wrap-sort-comments-by{
        display: flex;
        align-items: center;
        .text-block{
            font-size: 12px;
            color: $sub-font-color;
            @include max-xs {
                display: none;
            }
        }
    }
    .comments-sort-select{
        display: inline-block;
        vertical-align: middle;
        width: auto;
        max-width: 180px;
        cursor: pointer;

        .ant-select-selection {
            height: 26px;
            border: none;
            @include border-radius(0);
            @include box-shadow(none);
            background-color: transparent;
        }

        .ant-select-selection__rendered {
            line-height: 26px;
            margin-left: 8px;
            margin-right: 0;
            font-size: 12px;
            font-family: $title-font;
            font-weight: bold;
        }

        .ant-select-selection__placeholder {
            color: #888888;
        }

        .ant-select-selection-selected-value {
            color: $title-font-color;
            padding-right: 15px;
        }

        .ant-select-arrow {
            right: 0;
            color: #888888;
            font-size: 10px;
            margin-top: -5px;
        }
    }

    .write-comments {
        padding: 20px 0;

        .avatar-block {
            float: left;
        }

        .right-block {
            margin-left: 55px;
            position: relative;
        }

        .comment-text-area {
            padding: 10px 10px;
            resize: none;
            font-size: 14px;
            padding-right: 35px;
        }

        .send-btn {
            position: absolute;
            top: 50%;
            right: 10px;
            font-size: 18px;
            margin-top: -9px;
            color: $sub-font-color;

            &:hover {
                color: #666;
            }
        }
    }

    .comments-lists {
        margin: 0;

        .comment-item {
            padding: 10px 0;
        }

        .user-avatar-block {
            float: left;
            border: solid 1px #d5d5d5;
        }

        .reply-comment {
            border-top: solid 1px #eee;
            margin-top: 15px;
            padding-top: 5px;
            // .reply-comment{
            //     margin-left: -55px;
            // }
        }

        li {
            .right-block {
                margin-left: 0;
            }
        }

        .comment-right-content {
            margin-left: 55px;
            position: relative;
            overflow: hidden;
        } 
        .comment-dropdown-btn{
            font-size: 16px;
            color: rgba($sub-font-color, 0.5);
            width: 28px;
            height: 28px;
            text-align: center;
            position: absolute;
            right: -5px;
            top: 5px;
            font-weight: normal;
            &:hover {
                color: $sub-font-color;
            }

            &.active {
                color: $primary-color;
            }
        }

        .comment-header {
            padding-bottom: 5px;
            padding-right: 30px;
            display: flex;
            flex-direction: column;
            .commented-user {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
                font-weight: bold;
                color: $primary-color;
                margin-bottom: 0;
                margin-right: 5px;
            }

            .date-block {
                color: #777;
                font-size: 13px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
            }
            .comment-header-right-block{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
            }
            @include min-sm{
                flex-direction: row;
                align-items: center;
            }
        }

        .edited-comment-label{
            .icon-block{
                font-size: 14px;
                color: #A7B4BA;
                margin-right: 5px;
            }
            .text-block{
                font-size: 13px;
                color: #A7B4BA;
            }
        }

        .para-block {
            font-size: 15px;
            max-width: 500px;
            color: #3f4549;
            margin-bottom: 0;
            white-space: pre-wrap;

            +.para-block {
                margin-top: 10px;
            }
        }

        .reply-btn {
            margin-top: 10px;
            font-size: 13px;
            color: #00273b;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    .no-loading-items {
        padding: 15px 20px;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        color: $sub-font-color;
    }
}