//////////////////////////////////
/// PAGE: DEVICE LISTING PAGE ///
//////////////////////////////////

.device-listing-widget {
    .para-text {
        font-size: 16px;
    }

    .date-block {
        margin: 0 0 3px;
    }

    .tag-item {
        margin-top: 5px;
        font-size: 13px;
        height: 24px;
        line-height: 22px;
    }
}