////////////////////////////////////////////////
/// Components: Activities Wall Widget ///
////////////////////////////////////////////////
.activities-wall-widget {
    .wall-item {
        background-color: #fff;
        border: solid 1px #fff;
        border-bottom: 1px solid #dddddd;
        padding: 6px 20px;
        @include transition(all 0.2s linear);
        position: relative;

        &:hover {
            //border-color: rgba($primary-color, .5);
            //background-color: rgba($primary-color, .01);
            background-color: #f5f5f5;
        }

        .wall-user-avatar {
            float: left;
            width: 32px;
            height: 32px;
            line-height: 30px;
            border: solid 1px #e4e4e4;
        }

        .wall-user-avatar1 {
            float: left;
            width: 32px;
            height: 32px;
            line-height: 30px;
            color: red;
        }

        .right-content-block {
            margin-left: 50px;
            padding-right: 70px;
        }

        .wall-username {
            font-size: 14px;
            font-weight: bold;
            font-family: $title-font;
            margin: 0;
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }

        .activity-type {
            display: inline-block;
            vertical-align: middle;
            color: $sub-font-color;

            .icon-block {
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 5px;
            }

            .text-block {
                font-size: 13px;
                display: inline-block;
                vertical-align: middle;
            }

            &:hover {
                color: $primary-color;
            }
        }

        .date-block {
            display: block;
            font-size: 13px;
            color: $sub-font-color;
        }

        .hover-options {
            position: absolute;
            right: 20px;
            top: 30px;

            .btn {
                color: $sub-font-color;
                margin-left: 10px;
            }

            .edit-btn {
                font-size: 13px;
                font-weight: 500;

                &:hover {
                    color: #666;
                }
            }

            .delete-btn {
                font-size: 16px;

                &:hover {
                    color: $error-color;
                }
            }

            .view-btn {
                top: -15px;

                .icon-block {
                    margin-right: 5px;
                }

                .icon-block,
                .text-block {
                    display: inline-block;
                    vertical-align: middle;
                }

                .text-block {}

                &:hover {
                    color: #666;
                }
            }
        }

        .wall-item-content {
            padding-top: 10px;

            p {
                font-size: 16px;
                line-height: 1.5;
                margin-bottom: 0;

                +p {
                    margin-top: 5px;
                }
            }

            span,
            p {
                white-space: pre-line;
            }

            .wall-item-content-deleted {
                background-color: $error-color;
                color: #fff;
                padding: 3px 6px;
                font-size: 14px;
            }
        }

        .wall-media {
            margin-top: 15px;
            margin-bottom: 0;

            img {
                display: inline-block;
                border: solid 1px #dcdcdc;
                max-width: 100%;
            }
        }

        .wall-item-content--overview {
            padding-top: 5px;
            padding-left: 32px;
        }
    }

    .wall-item-loader {
        padding: 10px 20px;
        background-color: #fff;
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-lg {
        .wall-item {
            &:hover {
                .hover-options {
                    @include visibility(visible);
                }
            }

            .hover-options {
                @include visibility(hidden);
            }
        }
    }
}