////////////////////////
// Simple form
////////////////////////

.simple-form {

    // .ant-form-item {
    //     margin-bottom: 25px;
    // }
    .ant-form-item {
        margin-bottom: 5px;
    }

    .ant-form-item-children {
        display: block;
    }

    .ant-form-item-label,
    .ant-form-item-label label {
        font-size: 14px;
        font-weight: 500;
        color: $default-font-color;
        font-family: $semibold-font;
        padding: 0 0 5px;

        &::before {
            font-weight: normal;
        }
    }

    .ant-form-item-label .ant-form-item-required {
        &::before {
            display: none;
        }

        &::after {
            display: inline-block;
            content: '*';
            color: inherit;
            top: 0;
            vertical-align: middle;
        }
    }

    .ant-form-item-label label::after {
        display: none;
    }

    .ant-input-group-addon {
        background-color: #e2e4e7;
        font-size: 13px;
        padding-right: 20px;
        color: #354266;
        font-weight: 400;
        border: none;
        @include border-radius(2px 0 0 2px);
    }

    .ant-calendar-picker {
        width: 100%;
    }

    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    input[type=number] {
        -moz-appearance: textfield;
    }

    .ant-time-picker {
        width: 100%;

        .ant-time-picker-icon {
            height: 18px;
            margin-top: -9px;
        }
    }

    .ant-input-number {
        width: 100%;
    }

    .ant-input,
    .ant-time-picker-input,
    .ant-input-number {
        font-size: 14px;
        @include border-radius(4px);
        background-color: #fff;
        height: 34px;
        font-weight: 500;

        &:not(:focus) {
            border: solid 1px #b1b1b1;
        }

        &:focus {
            border-color: #999;
            @include box-shadow(0 0 0 2px rgba(0, 0, 0, 0.2));
        }

        &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $text-color;
            opacity: 0.7;
            font-weight: normal;
        }

        &::-moz-placeholder {
            /* Firefox 19+ */
            color: $text-color;
            opacity: 0.7;
            font-weight: normal;
        }

        &:-ms-input-placeholder {
            /* IE 10+ */
            color: rgba($text-color, 0.7);
            /*color: $text-color;
            opacity: 0.7;*/
            font-weight: normal;
        }

        &::-ms-input-placeholder {
            /* Firefox 18- */
            color: $text-color;
            opacity: 0.7;
            font-weight: normal;
        }

        &:-moz-placeholder {
            /* Firefox 18- */
            color: $text-color;
            opacity: 0.7;
            font-weight: normal;
        }
    }

    .ant-select-selection-selected-value {
        font-weight: 500;
    }

    .c-item-label {
        min-height: 26px;
        font-size: 13px;
        display: inline-block;
        font-weight: 500;
        color: $default-font-color;
        font-family: $semibold-font;
    }

    .timekeeper-pops {
        position: absolute;
        left: 0;
        top: 100%;
        z-index: 99;
        width: 100%;
    }

    .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
        border-color: #999;
    }

    .has-error{
        .ant-input:not([disabled]),
        .ant-input-number-input:not([disabled]),
        .ant-select:not(.ant-select-disabled) .ant-select-selection{
            border-color: #f5222d;
            @include box-shadow(0 0 0 2px rgba($primary-color, .2));
        }
        .ant-input-number{
            border-color: #f5222d;
        }
    } 

    .ant-select-selection--single,
    .ant-select .ant-select-selection {
        height: 34px;
        @include border-radius(4px);
        background-color: #fff;
        font-size: 14px;

        &:not(:focus) {
            border: solid 1px #b1b1b1;
        }

        .ant-select-arrow {
            font-size: 12px;
            margin-top: -5px;
        }
        &.ant-select-selection--multiple{
            min-height: 34px;
            height: auto;
        }
    }
    .ant-select .ant-select-selection { 
        &:not(:focus) {
            border: solid 1px #b1b1b1;
        }
    }

    .ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled) {
        border: solid 1px #b1b1b1;

    }

    .ant-select-selection-selected-value {
        .c-checkbox .ant-checkbox {
            display: none;

            +span {
                padding: 0;
            }
        }
    }

    .ant-select-focused .ant-select-selection,
    .ant-select-open .ant-select-selection {
        border-color: #999;
        @include box-shadow(0 0 0 2px rgba(0, 0, 0, 0.2));
    }

    .has-error .ant-select-selection,
    .has-error .ant-select-selection:hover {
        border-color: #f5222d;
    }
    .ant-select-selection__placeholder{
        color: $text-color;
        opacity: 0.7;
    }

    .ant-form-explain--info {
        bottom: -30px;
        color: $text-color;
        font-size: 12px;
        position: absolute;
        left: 0;
        width: 100%;
    }

    .ant-form-explain-holder {
        bottom: -20px;
        font-size: 12px;
        position: absolute;
        left: 0;
        width: 100%;

        .ant-form-explain {
            position: static;
        }
    }

    .ant-form-explain {
        bottom: -16px;
        font-size: 12px;
        position: absolute;
        left: 0;
        width: 100%;
    }

    .ant-form-explain--custom {
        bottom: -8px;
        font-size: 12px;
        position: absolute;
        left: 0;
        width: 100%;
        color: #f5222d;

        &.time-err-val{
            bottom: auto;
            line-height: 1;
            top: -35px;
        }

        &.b-20 {
            bottom: -20px;
        }
    }

    

    .ant-select-selection__rendered {
        line-height: 32px;
    }

    .ant-calendar-picker-icon {
        font-size: 18px;
        margin-top: -10px;
    }

    .radio-group .ant-form-item-control {
        line-height: normal
    }

    // .c-checkbox{
    //     .ant-checkbox-input{
    //         width: 100%;
    //         height: 100%; 
    //     }
    //     .ant-checkbox-inner{
    //         width: 20px;
    //         height: 20px;
    //         background-color: #ebedf0;
    //         border-color: #ebedf0;
    //         @include border-radius(20px);
    //         &::after{
    //             position: absolute;
    //             top: 4px;
    //             left: 6px;
    //         }
    //     }
    //     .ant-checkbox{
    //         &::after{
    //             @include border-radius(20px);
    //         }
    //     }
    //     .ant-checkbox + span{
    //         padding-left: 16px;
    //         color: $text-color;
    //         font-weight: 600;
    //         font-size: 12px;
    //         a {
    //             text-decoration: underline;
    //             color: $text-color;
    //             &:hover{
    //                 color: $primary-color;
    //             }
    //         }
    //     }
    //     .ant-checkbox-checked .ant-checkbox-inner{
    //         background-color: $primary-color;
    //         border-color: $primary-color;
    //     }
    // } 
    .datepicker-block {
        font-size: 18px;
        color: #aeb8bd;
    }

    .label-has-tooltip {
        display: inline-block;

        .label-text,
        .help-icon {
            display: inline-block;
            vertical-align: middle;
        }

        .help-icon {
            margin-left: 5px;
            font-size: 16px;
            color: #b3b3b3;
            @include transition(all 0.2s linear);

            &:hover {
                color: #999;
            }
        }
    }

    .hide-required-star {
        .ant-form-item-required::after {
            display: none;
        }
    }

    .form-footer {
        margin-top: 10px;
        margin-left: -20px;
        margin-right: -20px;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 20px;
        padding-bottom: 20px;
        border-top: 1px solid #dddddd;

        &.clear-padding {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .submit-btn {
            min-width: 100px;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
        }

        .reset-btn {
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 10px;
            @include border-radius(0);
        }

        .cancel-btn {
            font-size: 14px;
            color: $sub-font-color;
            font-weight: 700;
            height: 32px;

            &:hover {
                color: #666;
            }
        }
    }

    .create-new-sub-btn {
        font-size: 13px;
        font-weight: 500;
        position: relative;
        z-index: 9;
        color: $default-font-color;
        font-family: $semibold-font;
        margin-top: 3px;

        &:hover .text-block {
            text-decoration: underline;
        }

        .icon-block {
            color: $primary-color;
            font-size: 10px;
            margin-right: 5px;
        }
    }

    //ReactQuill
    .editor-input {
        padding-bottom: 5px;

        .ql-container.ql-snow,
        .ql-toolbar.ql-snow {
            border-color: #b1b1b1;
        }

        .ql-container.ql-snow {
            @include border-radius(0 0 4px 4px);
        }

        .ql-toolbar.ql-snow {
            @include border-radius(4px 4px 0 0);
        }

        .ql-editor {
            min-height: 100px;
        }

        p {
            font-size: 15px;
        }
        &.has-error{
            .ql-toolbar.ql-snow{
                border-color: #f5222d;
                @include box-shadow(0 0 0 2px rgba($primary-color, .2));
            }
            .ql-container.ql-snow{
                border-color: #f5222d;
                @include box-shadow(0 0 0 2px rgba($primary-color, .2));
            }
        }
    }

    //auto complete
    .ant-select-auto-complete {
        .ant-input.ant-select-search__field {
            border: none;
            @include box-shadow(none !important);
            height: 34px;
            font-size: 14px;
            padding-right: 30px;
        }

        .ant-select-selection__clear {
            font-size: 16px;
            margin-top: -8px;
        }
    }

    //cards form field
    .card-form-group {
        .ant-input-affix-wrapper .ant-input:not(:first-child) {
            padding-left: 45px;
        }

        .ant-input-prefix {
            left: 10px;
        }

        .icon-block {
            //font-size: 20px;
            //color: rgba(black, .4); 
            width: 28px;
            height: 28px;
            background-position: left center;
            background-repeat: no-repeat;
            display: inline-block;
            vertical-align: middle;
            background-size: contain;
        }
    }

    .has-timer-convention {
        .ant-input-group-addon {
            background-color: #F7F7F7;
            width: 100%;
            padding: 0;
        }

        .ant-input-group .ant-select {
            width: 100%;

            .ant-select-selection-selected-value {
                color: rgba(black, .65);
            }
        }

        .ant-select-selection-selected-value {
            padding-right: 5px;
        }

        .ant-select-selection--single .ant-select-arrow {
            right: 10px;
        }
    }
}

//dropdown menu
.ant-select-dropdown-menu-item {

    &:hover,
    &-active {
        background-color: #eee;
    }
}

.simple-form .form-footer.form-footer--fixed-xs {
    @include max-xs {
        position: fixed;
        left: 0;
        width: 100%;
        margin: 0;
        bottom: 0;
        z-index: 1056;
        display: flex;
        flex-direction: row;
        background-color: #fff;
        padding: 0;
        border-top: solid 1px $primary-color;
        z-index: 1000;

        .submit-btn,
        .cancel-btn {
            flex: 1 1;
            height: 58px;
            line-height: normal;
            display: flex;
            flex-direction: row;
            align-items: center;
            white-space: normal;
            justify-content: center;
            margin: 0;
            padding: 0 10px;

            .icon-block {
                font-size: 20px;
                display: inline-block;
                vertical-align: middle;
                position: relative;
                top: -1px;
            }

            .text-block {
                display: inline-block;
                vertical-align: middle;
                text-align: left;
            }
        }

        .cancel-btn {
            background-color: #fff;
        }
    }
}

.emergency-button {
    .ant-checkbox{
        font-size: 14px;
        opacity: 0.5;
        background-color: #fff;
        height: 34px;
        @include transition(all 0.2s linear);
        .ant-checkbox-input{
            height: 34px;
            width:70px;
        }
        .ant-checkbox-inner{
            height: 34px;
            width: 70px;
            border: solid 1px $primary-color ;
            @include border-radius(4px);
            &:before{
                font-family: $icon-font;
                content: "\e949";
                position: absolute;
                left: 12px;
                top: 7px;
                z-index: 2;
                font-size: 18px;
                color: $primary-color ;
                @include transition(all 0.2s linear);
            }
            &:after{
                top: 7px;
                left: 58%;
                border: none;
                font-size: 17px;
                font-family: $icon-font;
                content: "\e909";
                color: $primary-color ;
                opacity: 1;
                @include transition(all 0.2s linear);
                @include transform(none);
            }
        }
        &:hover{
            opacity: 1;
        }
    }
    .ant-checkbox-checked{
        opacity: 1;
        .ant-checkbox-inner{
            
            &:before, &:after{
                color: #fff ;
            }
        }
    }
}