//////////////////////////////////
/// PAGE: TASK LISTING PAGE ///
//////////////////////////////////
.task-listing-widget {
    .task-listing-item {
        padding: 10px 20px;
        border: solid 1px #fff;
        border-bottom: 1px solid #dddddd;
        @include transition(all 0.2s linear);
        position: relative;
        cursor: pointer;

        &:hover {
            //border-color: rgba($primary-color, .5);
            //background-color: rgba($primary-color, .01);
            background-color: #f5f5f5;
        }
    }

    .task-list-header {
        .task-user-avatar {
            float: left;
            margin-top: 5px;

            .ant-avatar-string {
                padding-top: 2px;
            }
        }

        .right-content {
            margin-left: 44px;
            position: relative;
            padding-top: 2px;
            padding-right: 200px;
            word-break: break-word;
            min-height: 34px;
        }

        .task-username {
            font-size: 13px;
            font-weight: 700;
            margin-bottom: 0;
            color: $title-font-color;

            .link-text {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .sub-label {
                margin-left: 5px;
                font-weight: normal;
                font-size: 14px;
                color: $sub-font-color;
                font-family: $font-family-base;
            }
        }

        .date-block {
            font-weight: normal;
            font-size: 13px;
            color: $sub-font-color;
            top: -8px;
            position: relative;
        }

        .right-option-block {
            position: absolute;
            right: 0;
            top: 5px;

        }

        .mid-content-block {
            position: absolute;
            right: 80px;
            top: 8px;
        }

        .more-option-btn {
            font-size: 18px;
            margin-left: 10px;
            color: $sub-font-color;

            &:hover {
                color: #666;
            }

            &.active {
                color: $primary-color;
            }
        }

        .c-checkbox,
        .more-option-btn {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .task-title {
        font-size: 16px;
        font-family: $semibold-font;
        color: $title-font-color;
        margin: 8px 0;
        font-weight: 500;
        display: inline-block;

        &:hover {
            color: $primary-color;
        }
    }

    .total-task-comments {
        color: $sub-font-color;
        font-size: 13px;
        font-weight: 700;

        &:hover {
            color: #666;
        }

        .icon-block {
            margin-right: 5px;
        }

        .icon-block,
        .text-block {
            display: inline-block;
            vertical-align: middle;
        }
    }

    .tasks-tab-wrapper {
        .ant-tabs-bar {
            margin-bottom: 5px;
        }

        .ant-tabs-nav .ant-tabs-tab {
            padding: 12px 10px;
            margin: 0 15px 0 0;
            font-family: $title-font;
            font-weight: bold;
        }

        .c-collapse .ant-collapse-content-box {
            padding-left: 0;
        }
    }

    .task-list-tab {
        padding: 10px 20px 30px;

        .task-listing-item {
            padding-left: 0;
            padding-right: 0;
            cursor: pointer;

            &:hover {
                background-color: transparent;
            }
        }
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        .tasks-tab-wrapper {
            .ant-tabs-nav .ant-tabs-tab {
                padding: 12px 20px;
            }
        }

        .task-title {
            margin-bottom: 0;
            margin-top: 5px;
        }

        .task-list-header {
            .task-user-avatar {
                margin-top: 0;
            }

            .date-block {
                top: -2px;
            }
        }
    }

    @include min-md {
        .task-list-header .right-option-block {
            @include visibility(hidden);
        }

        .task-listing-item:hover {
            .task-list-header .right-option-block {
                @include visibility(visible);
            }
        }
    }

    @include max-xs-medium {
        .tasks-tab-wrapper .mid-content-block {
            position: initial;
            max-width: initial !important;
        }

        .tasks-tab-wrapper .right-content {
            padding-right: 60px;
        }
    }

    @include max-xs {
        .tasks-tab-wrapper .mid-content-block {
            max-width: 124px;
        }

        &.hide-tabs-xs .ant-tabs-bar {
            display: none;
        }
    }

    @include min-lg {
        .task-list-header {
            .more-option-btn {
                margin-left: 25px;
            }
        }
    }
}