//////////////////////////////////
/// PAGE: COMMON PAGE STYLES ///
//////////////////////////////////

/////////////////////////////
/// Debug settings ///
/////////////////////////////
$debug: false; // true | false

////////////////////////////////////
/// checking debug operations ///
////////////////////////////////////
@if ($debug) {
  body {
    border: solid 10px $primary-color;
  }
}

/////////////////////////////
/// RESET STYLES ///
/////////////////////////////
*,
*:after,
*:before {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

a,
input[type="radio"],
input[type="checkbox"],
button,
select,
.btn,
.text-field {
  @include transition(all 0.2s ease-in-out);
  outline: none;

  &:hover,
  &:focus,
  &:focus {
    text-decoration: none;
    outline: none !important;
    outline-style: none;
    outline-width: 0;
  }
}

html {
  -webkit-text-size-adjust: none;
}

* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); //removing highlight color
  -webkit-overflow-scrolling: touch; //touch smooth
}

html,
body {
  min-height: 100%;
}

html {
  //overflow: auto;
  -webkit-overflow-scrolling: touch;
}

//iOS phone number detection prevent
a[href^="tel"] {
  color: inherit;
}

p {
  font-size: 14px;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $title-font;
  color: $title-font-color;
  font-weight: normal;
}

//for mozilla
button::-moz-focus-inner {
  border: 0;
}

select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #000;
}

button {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
  display: block;

  &.inline {
    display: inline-block;
  }
}

//safari browser additional inner shadow remove
input[type="text"],
input[type="email"],
input[type="search"],
input[type="password"] {
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* mobile firefox too! */
}

.uppercase {
  text-transform: uppercase;
}

.capitalize {
  text-transform: capitalize;
}

ul {
  list-style: none;
}

img {
  max-width: 100%;
}

textarea.form-control {
  resize: vertical;
}

*::-moz-selection {
  background: $primary-color;
  color: #fff;
  text-shadow: none;
}

*::selection {
  background: $primary-color;
  color: #fff;
  text-shadow: none;
}

.radius {
  @include border-radius($radius);
}

.clear-radius {
  @include border-radius(0);
}

.clearfix {
  @include clearfloat();
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.ant-empty-description {
  font-size: 14px;
  color: $sub-font-color;
}

pre {
  white-space: pre-wrap;
  /* css-3 */
  white-space: -moz-pre-wrap;
  /* Mozilla, since 1999 */
  white-space: -pre-wrap;
  /* Opera 4-6 */
  white-space: -o-pre-wrap;
  /* Opera 7 */
  word-wrap: break-word;
  /* Internet Explorer 5.5+ */
}

a:not([disabled]) {
  color: $link-color;

  &:hover,
  &:focus,
  &:active {
    color: darken($link-color, 10%);
    text-decoration: none;
  }

  &:hover {
    text-decoration: underline;
  }
}

p {
  color: $default-font-color;
  margin-bottom: 10px;
  font-size: 16px;
  font-family: $paragraph;
}

.text-bold {
  font-family: $semibold-font;
  font-weight: 500;
}

//margin sets
.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 10px;
}

.mb-2 {
  margin-bottom: 20px;
}

.mb-3 {
  margin-bottom: 30px;
}

.ml-1 {
  margin-left: 10px;
}
.mr-1 {
  margin-right: 10px;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 10px !important;
}
.mt-2 {
  margin-top: 15px !important;
}
.mt-3 {
  margin-top: 20px !important;
}
.mt-4 {
  margin-top: 25px !important;
}
.mt-5 {
  margin-top: 30px !important;
}
.fz-12 {
  font-size: 12px !important;
}
.fz-14 {
  font-size: 14px !important;
}
.pl-3 {
  padding-left: 15px;
}
.pr-3 {
  padding-right: 15px;
}
.pt-1 {
  padding-top: 10px !important;
}
.pt-2 {
  padding-top: 15px !important;
}
.pb-1 {
  padding-bottom: 10px !important;
}
.pb-2 {
  padding-bottom: 15px !important;
}
.position-relative {
  position: relative !important;
}

@include max-xs {
  .mt-4-max-xs {
    margin-top: 20px !important;
  }
}

@include min-sm {
  .mr-4-sm {
    margin-right: 20px !important;
  }
  .float-md-right {
    float: right !important;
  }
  .mt-sm-minus-20 {
    margin-top: -20px;
  }
}
/////////////////////////////
/// COMMON STYLES ///
/////////////////////////////
body {
  font-family: $font-family-base;
  color: $default-font-color;
  background-color: $bg-dark !important;
  font-size: 14px;

  @include min-sm {
    font-size: 16px;
  }
}

.ant-alert {
  font-size: 12px;
  font-weight: bold;

  .ant-alert-close-icon {
    border: none;
    background: white;
  }
}

.container {
  max-width: 1160px;
  width: 90%;
  margin: auto;
}

.container-fluid {
  padding: 0 15px;
}

@include min-sm {
  .container-fluid {
    padding: 0 30px;
  }
}

@include min-md {
  .container-fluid {
    padding: 0 50px;
  }
}

@include min-lg {
  .container-fluid {
    padding: 0 70px;
  }
}

//Right main content
.main-right-container {
  padding: 0 15px;
  margin-left: 60px;

  &.no-sidebar {
    margin-left: 0;
  }
}

@include min-sm {
  .main-right-container {
    margin-left: 75px;
    padding: 0 30px;
  }
}

@include min-md {
  .main-right-container {
    padding: 0 50px;
    margin-left: 245px;

    &.no-sidebar {
      margin-left: 0;
      padding: 0 50px;

      //grid reset for no sidebar
      .grid-col-xxl-18 {
        width: 65%;
      }

      .grid-col-xxl-6 {
        width: 35%;
      }
    }
  }
}

//grid reset for no sidebar
// @media (min-width: $screen-md-min) and (max-width: $screen-md-max){
//     .main-right-container{
//         &.no-sidebar{
//             .grid-col-xxl-18{
//                 width: 65%;
//             }
//             .grid-col-xxl-6{
//                 width: 35%;
//             }
//         }
//     }
// }

@include min-lg {
  .main-right-container {
    padding: 0 70px;

    &.no-sidebar {
      padding: 0 70px;

      //grid reset for no sidebar
      .grid-col-xxl-18 {
        width: 70%;
      }

      .grid-col-xxl-6 {
        width: 30%;
      }
    }
  }
}

//Grid for XXL medium
@include min-xlg {
  .grid-col-xxl-18 {
    width: 70%;
  }

  .grid-col-xxl-6 {
    width: 30%;
  }

  .main-right-container {
    &.no-sidebar {
      .grid-col-xxl-18 {
        width: 70%;
      }

      .grid-col-xxl-6 {
        width: 30%;
      }
    }
  }
}

//Grid for XXL large
@media (min-width: 1800px) {
  .grid-col-xxl-18 {
    width: 75%;
  }

  .grid-col-xxl-6 {
    width: 25%;
  }

  .main-right-container {
    &.no-sidebar {
      .grid-col-xxl-18 {
        width: 75%;
      }

      .grid-col-xxl-6 {
        width: 25%;
      }
    }
  }
}

.app-body {
  //min-height: 90vh;
  min-height: calc(100vh - 61px);
}

//loader

.center-progress {
  text-align: center;
  padding: 60px 25px;
}

//table no data
.ant-table-placeholder {
  color: rgba(black, 0.2);
  font-weight: bold;
  font-size: 14px;
}

//single line
.single-line {
  white-space: nowrap;
  display: block;
}

//main content area
.main-content-area {
  padding: 60px 0;
}

//link
.item-link {
  color: $primary-color;

  &:hover {
    text-decoration: underline;
  }
}

.main-inner-wrapper {
  padding: ($header-size + 15px) 0 70px;
  min-height: 100vh;
}

.top-buttons-header {
  margin-bottom: 20px;

  .main-btn {
    @include border-radius(0);
    height: 40px;
    line-height: 36px;
    padding: 0 15px;

    .icon-block {
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      font-size: 20px;
      position: relative;
      top: -2px;
    }

    .text-block {
      display: inline-block;
      line-height: normal;
      vertical-align: middle;
    }
  }

  @include max-xs-medium {
    .main-btn {
      display: block;

      // +.main-btn {
      //     margin-top: 10px;
      // }
    }
  }
  &-align-center--sm {
    @include min-sm {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0;
      .wrap-btns,
      .category-filter-block {
        margin-bottom: 20px;
      }
    }
  }
  @include min-sm {
    &.header-flex-end {
      display: flex;
      justify-content: space-between;
    }
  }
  @include max-sm {
    display: flex;

    .category-filter-block {
      background: $primary-color;
      .filtered-block {
        display: none;
      }
      div.left-item-block {
        width: auto;
        display: flex;
        .ant-tag {
          display: none;
        }
      }
      .main-btn {
        display: none;
      }
      .filter-dropdown-btn {
        width: 40px;
        height: 49px;
        color: #fff;
        background: $primary-color;
        &.active {
          color: #fff;
        }
        .anticon {
          color: #fff !important;
        }
      }
    }
  }
}

.top-buttons-header--fixed-xs {
  @include max-xs {
    position: fixed;
    left: 0;
    width: 100%;
    margin: 0;
    bottom: 0;
    z-index: 1056;
    // display: flex;
    // flex-direction: row;
    .wrap-btns {
      display: flex;
      width: calc(100% - 25px);
      .text-block {
        white-space: nowrap;
      }
    }
    .main-btn,
    .widget-btn {
      flex: 1 1;
      height: 58px;
      line-height: normal;
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: normal;
      justify-content: center;

      .icon-block {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        position: relative;
        top: -1px;
      }

      .text-block {
        display: inline-block;
        vertical-align: middle;
        text-align: left;
      }
    }

    .widget-btn {
      background-color: #fff;
    }
    .category-filter-block {
      .left-item-block {
        width: 15%;
      }
    }
  }
}

.card-widget {
  background-color: #fff;
  @include box-shadow(0 0 3px rgba(black, 0.03));

  .card-widget-header {
    padding: 10px 20px 8px;
    border-bottom: 1px solid #dddddd;

    .title-text {
      font-size: 18px;
      color: $title-font-color;
      font-family: $title-font;
      font-weight: bold;
      display: inline-block;

      .highlight {
        display: inline-block;
        font-size: 10px;
        font-weight: 600;
        color: #fff;
        text-align: center;
        vertical-align: middle;
        padding: 0 6px;
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        background-color: $primary-color;
        @include border-radius(20px);
      }
    }
    &.no-right-header-option {
      .title-text {
        margin: 0;
      }
    }

    .title-text-small {
      font-size: 14px;
    }

    .title-text-medium {
      font-size: 16px;
    }

    .title-has-dropdown {
      cursor: pointer;

      .dropdown-arrow-block {
        margin-left: 5px;
        @include opacity(0.5);
        font-size: 12px;
        display: inline-block;
      }
    }

    &.has-positioned-options {
      position: relative;
      padding-right: 60px;

      .title-text {
        margin-bottom: 0;
      }

      .right-header-option {
        position: absolute;
        right: 20px;
        top: 50%;
        margin-top: 0;
        @include transform(translateY(-50%));
      }
    }

    .right-header-option {
      .main-btn {
        font-size: 13px;
        padding: 0 15px;
        height: 30px;

        .icon-block {
          margin-right: 3px;
          font-size: 10px;
        }
      }

      .circle-btn {
        width: 30px;
        height: 30px;
        padding: 0;
        margin-left: 10px;
        font-size: 16px;

        span {
          display: block;
        }
      }

      .cta-btn {
        height: 32px;
        font-family: $font-family-base;
        font-weight: 600;
        font-size: 13px;

        &.cta-btn--size-md {
          height: 28px;
          padding-bottom: 2px;
        }

        .icon-block {
          display: inline-block;
          vertical-align: middle;
          margin-right: 10px;
        }

        .text-block {
          display: inline-block;
          vertical-align: middle;
        }
      }

      .clear-filter-btn {
        width: 16px;
        height: 16px;
        @include border-radius(50%);
        background-color: $primary-color;
        color: #fff;
        font-size: 6px;
        position: relative;
        top: -3px;
        text-align: center;
        line-height: 16px;
        margin-left: 5px;

        &:hover {
          background-color: darken($primary-color, 10%);
        }
      }

      .filtered-block {
        font-size: 13px;
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;
        font-weight: 600;
        color: rgba($title-font-color, 0.7);
      }

      .filter-dropdown-btn {
        font-size: 18px;
        color: $sub-font-color;
        width: 28px;
        height: 28px;
        text-align: center;

        &:hover {
          color: #666;
        }

        &.active {
          color: $primary-color;
        }
      }

      .link-btn {
        font-size: 13px;
        font-weight: 500;
        color: $primary-color;

        &:hover {
          text-decoration: underline;
        }
      }

      .text-btn {
        font-size: 14px;
        font-weight: 500;
        color: $sub-font-color;
      }

      .text-icon-link {
        color: $sub-font-color;
        font-family: $semibold-font;
        font-weight: 500;

        .icon-block {
          display: inline-block;
          vertical-align: middle;
          font-size: 16px;
          margin-right: 5px;
          position: relative;
          top: -1px;

          &.icon-size-sm {
            font-size: 12px;
          }
        }

        .text-block {
          display: inline-block;
          vertical-align: middle;
          font-size: 13px;
          font-weight: 500;

          &.text-size-md {
            font-size: 14px;
          }
        }

        &:hover {
          color: #666;
        }
      }
    }
    .has-center-align {
      display: flex;
      align-items: center;
    }
  }
  .reminder-header {
    padding: 18px;
  }

  .has-complex-right-block {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.custom-header-for-mobile {
      flex-direction: column;
      align-items: start;
    }

    .title-text {
      margin-bottom: 0;
    }

    .right-header-option {
      display: inline-block;
      vertical-align: middle;
      flex: 1;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }

    .left-item-block {
      margin-left: 10px;
      display: inline-block;
    }

    .sort-select {
      width: auto;
      max-width: 125px;
      margin-left: 10px;
      position: relative;
      top: 2px;

      .ant-select-selection {
        border: none;
        height: 30px;
        @include box-shadow(none);
      }

      .ant-select-selection__rendered {
        margin-left: 0;
      }

      .ant-select-selection-selected-value {
        padding-right: 5px;
        font-weight: 600;
      }

      .ant-select-arrow {
        right: 0;
      }
    }

    .wrap-right {
      position: relative;
    }


    @include max-xs {
      &.has-filter-block {
        .right-header-option {
          justify-content: flex-end;
        }

        .ant-tag {
          display: none;
        }
      }

      &.custom-header-for-mobile {
        .title-text {
          margin-bottom: 10px;
        }
        .ant-calendar-picker {
          width: 180px;
          .ant-calendar-picker-input {
            padding: 4px;
          }
        }
        .left-item-block {
          margin-left: 3px;
        }
        .sort-select {
          margin-left: 3px;
        }
        .wrap-left {
          display: flex;
          align-items: center;
        }
        .ant-select-selection-selected-value {
          font-size: 12px;
        }
        .ant-calendar-range-picker-input {
          font-size: 12px;
          width: 40%;
        }
      }
    }

    @include min-sm {
      .right-header-option {
        justify-content: flex-end;
      }

      .wrap-right {
        display: none;
      }

      &.custom-header-for-mobile {
        flex-direction: row;
        align-items: center;
      }
      &.custom-header-for-mobile {
        .ant-calendar-picker {
          width: 210px;
        }
      }
    }
  }
  .flex-right-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .title-text {
      margin-bottom: 0;
    }
    @include max-xs {
      .back-btn {
        display: none;
      }
    }
  }

  .task-list-select {
    width: 100px;
  }

  .card-widget-content {
    &.has-padding {
      padding: 15px 20px 20px;
    }

    .no-items {
      text-align: center;
      font-size: 13px;
      font-weight: 700;
      letter-spacing: 0.02em;
      color: $sub-font-color;
    }
  }

  ///////////////////////
  /// Media queries ///
  ///////////////////////
  @media (min-width: 640px) {
    .right-header-option {
      float: right;
      margin-top: -3px;
    }

    .card-widget-header {
      .title-text {
        margin-bottom: 0;
      }
    }
  }
  @include min-sm {
    .card-widget-header-align-center--sm {
      padding-bottom: 0 !important;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 0;
      .title-text,
      .category-filter-block {
        margin-bottom: 8px;
      }
    }
  }
}

.header-filter-dropdown {
  @include border-radius(0);
  left: auto !important;
  right: 0 !important;

  .ant-select-dropdown-menu-item {
    @include border-radius(0);
  }
}

.header-task-list-dropdown {
  @include border-radius(0);
  left: auto !important;
  right: 0 !important;

  .ant-select-dropdown-menu-item {
    @include border-radius(0);
    padding: 10px 20px;
  }
}

.subscription-bar-enabled {
  @include max-xs {
    .for--cover-subscription-bar {
      padding-bottom: 100px;
    }
  }
}

.primary-title-bb {
  font-weight: bold;
  font-size: 20px;
  position: relative;
  margin-bottom: 20px;

  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 4px;
    margin-top: 5px;
    background-color: $primary-color;
  }

  &.is-center {
    text-align: center;

    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.size-xl {
    font-size: 26px;
  }
}

.secondary-title-bb {
  font-weight: 600;
  font-size: 16px;
  position: relative;
  margin-bottom: 15px;
  font-family: $font-family-base;
  color: $primary-color;

  &::after {
    content: "";
    display: block;
    width: 26px;
    height: 3px;
    background-color: $primary-color;
  }

  &.is-center {
    text-align: center;

    &::after {
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.ant-avatar {
  font-weight: 500;
  font-size: 16px;
  background-color: #bbb;
  border-color: #bbb;
}

.subscription-has-expired {
  background-color: #fff;
  padding: 10px;
  border-top: 3px solid $primary-color;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999; // value should be below of ".ant-modal-wrap"

  .text-block,
  .btn-block {
    display: inline-block;
    vertical-align: middle;
  }

  .text-block {
    margin-right: 10px;
    font-size: 14px;
    font-weight: 500;
    font-family: $semibold-font;
    color: #838995;
  }

  .btn-block {
    height: 26px;
    line-height: 26px;
    padding: 0 20px;
    padding-bottom: 2px;
    font-size: 13px;
  }

  @media (max-width: 320px) {
    .text-block {
      margin-right: 0;
      margin-bottom: 10px;
    }
  }

  @include max-xs {
    &.header-cta-active {
      bottom: 58px;
    }
  }
}

.content-loader {
  width: 100%;
}

.iframe-wrapper {
  width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  height: 0;
  border: solid 2px #eee;

  //@include box-shadow(0 0 3px rgba(black, .03));
  .iframe-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ant-notification {
  z-index: 1060 !important;
}

.ant-message {
  z-index: 1060 !important;
}

.information-widget {
  p {
    font-size: 14px;
  }

  .btn-wrap {
    margin-top: 30px;

    .btn-block {
      height: 40px;
      line-height: 38px;
      padding: 0 30px;
    }
  }
}

.section-title {
  font-size: 26px;
  display: block;
  text-align: center;
  margin-bottom: 35px;
  color: #da3731;
  font-family: $title-font;

  @include min-md {
    font-size: 28px;
  }

  @include min-xlg {
    font-size: 32px;
  }
}

.section-spacer {
  padding: 45px 0;

  @include min-sm {
    padding: 60px 0;
  }

  @include min-md {
    padding: 70px 0;
  }

  @include min-lg {
    padding: 80px 0;
  }
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

@include min-sm {
  .pr-sm-0 {
    padding-right: 0 !important;
  }

  .pl-sm-0 {
    padding-left: 0 !important;
  }
}

.circle-date-block {
  display: block;
  font-size: 13px;
  padding: 20px;
  border-bottom: 1px solid #ddd;
}

.download-information-widget {
  padding: 30px 20px 20px;
  .sub-title-text {
    font-size: 16px;
    color: $title-font-color;
    font-family: $title-font;
    font-weight: bold;
    display: grid;
  }
  .item-block {
    margin-bottom: 15px;
    border-bottom: 1px solid #ddd;
  }
  .simple-form .ant-form-explain {
    position: static;
  }
  .last-update {
    font-size: 14px;
    color: $sub-font-color;
  }
  // .highlighted{
  //     color: $default-font-color;
  // }
}

.category-filter-block {
  display: flex;
  align-items: center;
  .main-btn {
    margin-right: 10px;
  }
  .clear-filter-btn {
    width: 16px;
    height: 16px;
    @include border-radius(50%);
    background-color: $primary-color;
    color: #fff;
    font-size: 6px;
    position: relative;
    top: -3px;
    text-align: center;
    line-height: 16px;
    margin-left: 5px;

    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
  .filtered-block {
    font-size: 13px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 5px;
    font-weight: 600;
    color: rgba($title-font-color, 0.7);
  }

  .filter-dropdown-btn {
    font-size: 18px;
    color: $sub-font-color;
    width: 28px;
    height: 28px;
    text-align: center;

    &:hover {
      color: #666;
    }

    &.active {
      color: $primary-color;
    }
  }
  @include min-sm {
    display: inline-flex;
  }
}

.gu-mirror {
  position: absolute !important;
}

.v-align-center {
  display: flex;
  align-items: center;
}
.device-head {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  .note-details-title {
    margin: 0;
  }
  .note-tags {
    div.tag-item {
      margin: 0 0 0 15px;
      white-space: nowrap;
    }
  }
}
.pb-0 {
  padding-bottom: 0 !important;
}

.drag-item-wrap {
  display: flex;
  position: relative;
  align-items: center;
  span.icon-drag {
    top: 35px;
    height: calc(100% - 35px);
    left: -30px;
    border: none;
    font-size: 18px;
    color: #c4c4c4;
    cursor: row-resize;
    opacity: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    @include transition(all 0.3s ease);
  }
  .wrap-input {
    width: 100%;
    .ant-form-item-label {
      left: 0;
    }
  }
  &:hover,
  &:focus {
    span.icon-drag {
      opacity: 1;
    }
  }
  .option-btn {
    font-size: 16px;
    color: #c4c4c4;
    font-weight: normal;
    margin-left: 10px;
    width: 32px;
    height: 34px;
    border: 1px solid #b1b1b1;
    @include border-radius(4px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    &:hover {
      color: $primary-color;
    }
  }
  .hover-option {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 85px;
    height: calc(100% - 35px);
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: center;
    right: -100px;
    opacity: 0;
    visibility: hidden;
    @include transition(all 0.2s linear);
  }
  &:hover,
  &:focus {
    .hover-option {
      @include visibility(visible);
    }
  }
}
.dragula-custom-field-item-block {
  &.dragula-custom-field-item-block2 {
    padding-left: 0;
  }
}
.red-icon {
  color: $primary-color;
}
.note-tags-single {
  .tag-item {
    border: solid 1px #a7acb2;
    //@include border-radius(20px);
    padding: 1px 10px;
    font-size: 11px;
    font-weight: 600;
    font-family: $title-font;
    display: inline-block;
    vertical-align: middle;
    color: #666666;
    text-decoration: none;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  a.tag-item {
    cursor: pointer;

    &:hover {
      color: #fff;
      @include box-shadow(0 3px 7px rgba($primary-color, 0.49));
      background-color: $primary-color;
      border-color: $primary-color;
    }
  }
}
.ant-select-dropdown,
.ant-select-selection {
  .menu-list-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.category-dropdown {
  .ant-select-selection-selected-value {
    width: 100%;
  }
}
.circle-settings-tab-widget {
  .item-block {
    .ant-select-selection.ant-select-selection--multiple {
      min-height: 34px;
      height: auto;
      padding: 2px;
    }
  }
}
.more-text {
  color: $primary-color;
  cursor: pointer;
}

@include max-xs {
  // custom date picker style for mobile screen
  .ant-calendar-range {
    min-width: 375px;
    width: 100%;
    .ant-calendar-date-input-wrap {
      margin-left: 0;
    }
    .ant-calendar-range-middle {
      margin-left: -20px;
    }
  }
}

// notes-display padding fix
.note-details-wrapper .note-details-content .para-text.note-class ol,
.note-details-wrapper .note-details-content .para-text.note-class ul {
  padding-left: 24px;
  font-size: 14px;
}
//access-token-expiry-group
.access-token-expiry-group {
  display: flex;
  .expiry-type {
    flex: 1 1 60%;
  }
  .expiry-value {
    margin-left: 10px;
    flex: 1 1 40%;
  }
}

//category button color
.category-item-btn {
    padding: 3px 10px;
    font-size: 11px;
    font-weight: 600;
    font-family: $title-font;
    display: inline-block;
    vertical-align: middle;
    color: currentColor;
    text-decoration: none;
    margin-right: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
    @include transition(all 0.2s ease-in-out);
    .bg-block{
      background-color: currentColor;
      @include opacity(0.3);
      // @include box-shadow(0 3px 7px currentColor); 
      @include transition(all 0.2s ease-in-out);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
    &:not([disabled]):hover{
      text-decoration: none;
    }
    &:hover{
      @include opacity(0.8);
      .bg-block{
        @include opacity(0.5);
      }
    }
    &:not(a):hover .bg-block {
      @include opacity(0.3);
    }
  }
  .category-block{
    border: 1px solid ;
    border-radius: 65px;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 8px;
}
  @media (max-width: 640px) {
    .circle-visibility-btn{
      margin-top: 10px;
    }
  }