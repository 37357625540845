////////////////////////////////////////////////
/// Components: CIRCLE MEMBERS TAB WIDGET ///
////////////////////////////////////////////////


.circle-members-tab-widget {
    background-color: #fff;
    @include box-shadow(0 0 3px rgba(black, .03));
    display: none;

    .circle-members-tabs {
        background-color: #f4f4f4;
        padding: 0 10px;
        border-bottom: 1px solid #ddd;

        .circle-members-tab-item {
            background-color: #f4f4f4;
            color: #838995;
            font-family: $semibold-font;
            font-weight: 500;
            font-size: 12px;
            cursor: pointer;
            display: inline-block;
            margin-bottom: -1px;
            @include transition(all 0.2s linear);
            vertical-align: top;
            padding: 10px 10px;
            border: 1px solid #ddd;

            &:hover,
            &.active {
                background-color: #fff;
                color: $title-font-color;
                border-bottom-color: #fff;
            }

            +.circle-members-tab-item {
                border-left-color: #fff;
            }
        }

        .circle-members-tab-option {
            float: right;
            font-size: 13px;
            font-weight: 500;
            color: $sub-font-color;
            margin-top: 10px;
            margin-left: 5px;

            .icon-block,
            .text-block {
                display: inline-block;
                vertical-align: middle;
            }

            .icon-block {
                margin-right: 5px;
                font-size: 16px;
            }

            &:hover {
                color: #666;
            }
        }
    }

    .circle-members-tab-content {
        padding: 10px 0;
        font-size: 0;
        border-top: 10px solid $bg-dark;
    }

    .circle-member-item {
        border-bottom: solid 1px #ddd;
        display: inline-block;
        vertical-align: top;
        // width: 33.333%;
        font-size: 14px;
        padding: 0 10px;
        text-align: right;
        position: relative;
        width: 100%;
        margin-bottom: 5px;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            .hover-block {
                @include visibility(visible);
            }
        }

        .circle-member-pic {
            display: block;
            float: left;
            width: 46px;
            margin-right: 10px;
            height: 46px;
            line-height: 42px;
            // display: inline-block;
            margin-bottom: 5px;
            border: 2px solid #fff;
            @include transition(all 0.2s linear);
        }

        &.active {
            .circle-member-pic {
                border-color: #ED1C24;
            }
        }

        .member-name-block {
            display: block;
            text-align: left;
            padding: 14px 25px 0px 0px;
            font-size: 14px;
            font-family: $semibold-font;
            font-weight: 500;
            @include ellipsis();
            color: $title-font-color;
        }

        .member-info-block {
            font-size: 12px;
            text-align: left;
            @include ellipsis();
            color: #838995;
        }

        .invite-by-user {
            padding: 0px 0px 10px 0px;
            text-align: left;
            color: $title-font-color;
            font-size: 12px;
            font-weight: 500;
            font-family: $semibold-font;
            @include ellipsis();
            display: block;
        }

        a.invite-by-user {
            &:hover {
                color: $primary-color;
                text-decoration: underline;
            }
        }

        &:last-child {
            border-right: none;
        }

        .hover-block {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(#fff, .6);
            @include visibility(hidden);
            @include transition(all 0.2s linear);
            padding: 10px 12px;

            .icon-block {
                display: inline-block;
                margin: 0 5px;
                width: 34px;
                height: 34px;
                text-align: center;
                color: #fff;
                font-size: 16px;
                @include border-radius(50%);
                @include opacity(0.7);
                background-color: $primary-color;

                &:hover {
                    @include opacity(1);
                }
            }

            .icon-size-lg {
                font-size: 18px;
            }
        }
    }

    .no-member-list {
        padding: 15px 20px;
        text-align: center;
        font-size: 13px;
        color: $sub-font-color;
        font-family: $semibold-font;
        font-weight: 500;
    }

 

    @include min-sm {

        .circle-member-item {
            width: 100%;
            padding: 10px;

            .member-info-block {
                padding: 11px 0px 0px;
            }

            &:last-child {
                border-bottom: none;
            }
        }
    }

    @include min-lg {
        display: block;

        .circle-member-item {
            width: 100%;
            padding: 0 10px;

            // border-bottom: none; 
            &:last-child {
                border-right: none;
            }
        }
    }
}
.bt-primary-10{
    border-top: 10px solid $bg-dark;
}