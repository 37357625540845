//////////////////////////
/// Header ///
////////////////////////// 

.header-has-sticky {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1055;
}

.main-header {
    background: $primary-color-dark;
    //padding: 12px 0;
    position: relative;
    height: $header-size;
    z-index: 99;

    .menu-btn {
        font-size: 24px;
        color: rgba(#fff, .9);
        margin-right: 20px;

        &:hover,
        &.active {
            color: #fff;
        }

        @include min-md {
            display: none;
        }
    }

    .logo-block {
        display: inline-block;
        vertical-align: middle;
        width: 100px;
        padding: 8px 0;
    }

    .brought-block {
        padding-left: 15px;
        display: inline-block;
        vertical-align: middle;

        .header-text {
            font-size: 10px;
            color: #fff;
            display: block;
            font-family: $title-font;
        }

        .brought-image {
            max-width: 80px;
            display: block;
        }
    }

    .search-block {
        display: inline-block;
        vertical-align: middle;
        width: 300px;
        margin-left: 20px;
        position: relative;

        .wrap-search-box{
            position: relative;
        }

        .icon-block {
            position: absolute;
            right: 0;
            top: 0;
            width: 36px;
            height: 100%;
            font-size: 14px;
            color: #b6b6b6;
            background-color: #eee;
            z-index: 2;
            @include border-radius(0 7px 7px 0);
            font-weight: normal;
            &:hover{
                background-color: rgba($primary-color, .1);
                color: $primary-color;
            }
        }

        .search-field {
            padding-right: 60px;
            z-index: 1;
            border: none;
            height: 36px;
            @include border-radius(7px);
        }

        .clear-search-btn {
            width: 24px;
            height: 24px;
            line-height: 26px;
            position: absolute;
            right: 36px;
            text-align: center;
            font-size: 10px;
            color: $sub-font-color;
            top: 50%;
            margin-top: -12px;
            z-index: 2;

            &:hover {
                color: #666;
            }
        }

        .search-category-wrap{
            position: absolute;
            left: 0;
            top: 100%;
            width: 100%;
            display: flex;
            background-color: #fff;
            @include border-radius(7px);
            margin-top: 2px;
            @include visibility(hidden);
            margin-top: -5px;
            @include transition(all 0.2s linear);

            &.open {
                @include visibility(visible);
                margin-top: 2px;
            }

            &::after{
                content: '';
                width: 1px;
                height: 100%;
                position: absolute;
                top: 0;
                left: 50%;
                background-color: #eee;
            }
            .search-selectbox{
                flex: 1 1 50%;
                .ant-select-selection--single{ 
                    @include box-shadow(none);
                    height: 38px;
                    .ant-select-selection__rendered{
                        line-height: 40px;
                    }
                }
            }
        }

        .search-dropdown {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            @include border-radius(0 0 3px 3px);
            background-color: #fff;
            @include box-shadow(0 2px 8px rgba(black, .17));
            border-top: 3px solid $primary-color;
            @include visibility(hidden);
            margin-top: -10px;
            z-index: 9;
            padding: 5px 0;

            &.open {
                @include visibility(visible);
                margin-top: -2px;
            }

            li {
                padding: 10px 20px;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
                position: relative;
                font-size: 14px;
                font-weight: 500;
                font-family: $semibold-font;
                cursor: pointer;
                padding-left: 60px;
                @include transition(all 0.2s linear);

                &:hover {
                    background-color: #f5f5f5;
                }

                &:last-child {
                    @include border-radius(0 0 3px 3px);
                    border-bottom: none;
                }
            }

            .list-icon-block {
                position: absolute;
                left: 20px;
                top: 50%;
                @include transform(translateY(-50%));
                font-size: 18px;
                color: $sub-font-color;

                &.icon-size-lg {
                    font-size: 22px;
                }

                &.icon-size-xl {
                    font-size: 24px;
                }
            }
        }
    }

    .header-right-menu {
        float: right;
    }

    .mobile-search-btn {
        font-size: 16px;
        color: rgba(#fff, .9);
        display: inline-block;
        vertical-align: middle;
        display: none;
        height: $header-size;
        padding: 12px 10px;

        &:hover {
            color: #fff;
        }

        &.active {
            color: #fff;
        }
    }

    .header-menu-item {
        display: inline-block;
        vertical-align: middle;
        padding: 12px 7px;
        height: $header-size;
        cursor: pointer;
        color: rgba(#fff, .9);

        &:hover {
            color: #fff;
        }

        &.active {
            color: #fff;
        }

        .icon-block {
            font-size: 20px;
            display: inline-block;
            vertical-align: middle;
            //margin-right: 10px;
            @include transition(all 0.2s linear);
        }

        .text-block {
            display: none;
            vertical-align: middle;
            margin-left: 10px;
            font-family: $title-font;
            font-size: 14px;
            @include transition(all 0.2s linear);
        }
    }

    .header-text-menu {
        font-family: $title-font;
        display: inline-block;
        margin-left: 20px;
        font-size: 16px;
        margin-top: 14px;
        color: rgba(#fff, .9);

        &.active,
        &:hover {
            color: #fff;
        }
    }

    .circle-menu-item {
        position: relative;

        .circle-badge {
            position: absolute;
            left: 25px;
            top: 8px;

            p {
                font-size: 10px;
                font-weight: 600;
                color: #fff;
            }
        }
    }

    .notification-icon-wrap {
        position: relative;
        cursor: pointer;
        .badge-block {
            position: absolute;
            left: 10px;
            top: -7px;
            border: none;
            @include border-radius(50%);

            .ant-badge-count {
                height: 16px;
                padding: 0;
                line-height: 16px;
                font-size: 10px;
                color: $primary-color;
                font-weight: 500;
                font-family: $semibold-font;
                background-color: #fff;
                @include box-shadow(none);
            }

            .ant-scroll-number-only {
                height: 16px;
            }

            p {
                font-size: 10px;
                font-weight: 500;
                height: 16px;
                font-family: $semibold-font;
                color: $primary-color;
            }
        }
    }

    .my-profile-menu {
        display: inline-block;
        vertical-align: middle;
        margin-left: 5px;
        cursor: pointer;

        .dp-block {
            width: 33px;
            height: 33px;
            line-height: 33px;
            display: inline-block;
            border: none;
            vertical-align: middle;
            overflow: hidden;
            @include border-radius(50%);

            img {
                display: block;
                width: 100%;
                height: 100%;
                @include border-radius(50%);
            }
        }

        .drop-arrow-block {
            display: inline-block;
            vertical-align: middle;
            font-size: 14px;
            margin-left: 10px;
            color: rgba(#fff, .9);
            @include transition(all 0.2s linear);
        }

        &:hover {
            .drop-arrow-block {
                color: #fff;
            }
        }
    }


    .lang-select {
        display: inline-block;
        vertical-align: middle;
        margin-left: 25px;
        cursor: pointer;

        .ant-select-selection {
            height: 28px;
            border: solid 1px #fff;
            @include border-radius(0);
            background-color: transparent;
        }

        .ant-select-selection__rendered {
            line-height: 26px;
            margin-left: 8px;
            margin-right: 0;
        }

        .ant-select-selection__placeholder {
            color: rgba(white, .5);
        }

        .ant-select-selection-selected-value {
            color: #fff;
        }

        .ant-select-arrow {
            right: 8px;
            color: #fff;
            font-size: 10px;
            margin-top: -5px;
        }

        @include max-xs {
            margin-left: 5px;

            .ant-select-selection-selected-value {
                font-size: 10px;
            }

            &.lang-select--has-positioned {
                position: absolute;
                right: 65px;
                top: 10px;
            }
        }

    }

    .header-right-block {
        float: right;
        margin-top: 10px;

        .toggle-btn-right {
            vertical-align: middle;
            margin-left: 20px;
            font-size: 26px;
            color: #fff;
            display: none;
        }

        .header-right-menu {
            float: none;
            display: inline-block;
            vertical-align: middle;

            .header-text-menu {
                margin-top: 0;
            }
        }

        @include max-xs {
            .toggle-btn-right {
                display: inline-block;
            }

            .header-right-menu {
                position: absolute;
                right: 0;
                top: 100%;
                background-color: $primary-color-dark;
                padding: 10px 0;
                width: 150px;
                border-top: 1px solid #fff;
                @include visibility(hidden);
                @include transition(all 0.2s linear);
                margin-top: -5px;

                &.open {
                    margin-top: 0;
                    @include visibility(visible);
                }

                .header-text-menu {
                    display: block;
                    margin: 0;
                    padding: 10px 10px;
                    border-bottom: 1px solid darken($primary-color-dark, 10%);
                    text-align: center;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }
        }
    }
    .notification-menu-item{
        cursor: default;
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include max-xs {

        .logo-block {
            width: 90px;
            padding: 12px 0;
        }

        .circle-menu-item,
        .my-profile-menu .drop-arrow-block {
            display: none;
        }

        .search-block {
            position: absolute;
            left: 0;
            top: 100%;
            margin-left: 0;
            width: 100%;
            @include transform(translateY(-10));
            @include transition(all 0.2s linear);
            @include visibility(hidden);
            padding: 10px 15px;
            background-color: #fff;
            z-index: 9;
            @include box-shadow(0 3px 5px rgba(black, .1));

            &.has-open {
                @include transform(translateY(0));
                @include visibility(visible);
            }

            .icon-block {
                top: 1px;
                right: 1px;
                height: calc(100% - 2px);
            }

            .clear-search-btn {
                margin-top: -13px;
            }
            .search-category-wrap{
                padding: 0 15px 15px;
                @include border-radius(0);
                &::after{
                    display: none;
                }
                .ant-select-selection--single{ 
                    .ant-select-selection__rendered{
                        line-height: 38px;
                    }
                }
                .search-selectbox{
                    margin-right: 5px;
                    + .search-selectbox{
                        margin-left: 5px;
                        margin-right: 0;
                    }
                }
            }
            .search-category-wrap.open{
                margin-top: 0;
            }
        }

        .mobile-search-btn {
            display: inline-block;
        }

        .header-menu-item {
            vertical-align: top;

            .icon-block {
                font-size: 16px;
            }
        }

        .circle-menu-item {
            .circle-badge {
                left: 10px;
                top: 5px;
            }
        }

        .my-profile-menu {
            cursor: pointer;

            .dp-block {
                width: 24px;
                height: 24px;
                line-height: 24px;
            }

            .drop-arrow-block {
                font-size: 12px;
                margin-left: 5px;
            }
        }
    }

    @include max-xs-medium {
        .logo-block {
            width: 80px;
        }

        .mobile-search-btn,
        .header-menu-item {
            padding: 12px 7px;
        }
    }

    @include max-xs-small {
        .menu-btn {
            font-size: 20px;
            margin-right: 10px;
        }

        .logo-block {
            width: 70px;
        }

        .mobile-search-btn,
        .header-menu-item {
            padding: 12px 5px;
        }
    }

    @include min-sm {
        .header-menu-item {
            padding: 12px 10px;
        }

        .my-profile-menu {
            margin-left: 15px;
        }

        .search-block {
            .search-field {
                &:not(:focus) {
                    border: none;
                }
            }
            .search-category-wrap {
                @include box-shadow(0 2px 8px rgba(black, .17));
                .search-selectbox .ant-select-selection--single{
                    border: none;
                }
            }
        }
        

        .brought-block {
            padding-left: 15px;

            .header-text {
                font-size: 12px;
                margin-right: 10px;
                display: inline-block;
            }

            .brought-image {
                max-width: 100px;
                display: inline-block;
            }
        }
    }

    @include min-md {
        .search-block {
            width: 300px;
        }

        .header-menu-item {
            padding: 12px 20px;
        }

        .my-profile-menu {
            margin-left: 20px;
        }
    }

    @include min-lg {
        .header-menu-item {
            padding: 12px 10px;

            .text-block {
                display: inline-block;
            }
        }
    }

    @media (min-width: 1280px) {
        .search-block {
            margin-left: 45px;
            width: 400px;
        }
    }

    @media (min-width: 1366px) {
        .search-block {
            width: 350px;
        }
    }

    @media (min-width: 1500px) {
        .search-block {
            width: 420px;
        }
    }

    @include min-xlg {
        .header-menu-item {
            padding: 12px 20px;
        }
    }
}

//Header my profile dropdown
.header-my-profile-dropdown {
    border-top: 3px solid $primary-color;
    min-width: 240px;
    margin-top: 8px;

    .ant-dropdown-menu-item {
        padding: 0;
        background-color: transparent;
    }

    .user-profile-drop-menu {
        padding: 10px 20px;
        padding-left: 55px;
        font-family: $title-font;
        font-weight: bold;
        color: #474747;
        position: relative;
        margin: 0;
        border-bottom: 1px solid #dddddd;
        background-color: #fff;
        @include transition(all 0.2s linear);

        &:hover {
            background-color: #f5f5f5;
            color: #474747;
            text-decoration: none;
        }

        .dropdown-menu-icon {
            position: absolute;
            left: 20px;
            top: 50%;
            font-size: 18px;
            margin-top: -9px;
            color: $sub-font-color;
        }
    }

    .ant-dropdown-menu-item:last-child {
        .user-profile-drop-menu {
            border-bottom: none;
        }
    }

    .user-details-menu {
        padding-left: 20px;

        .dp-block {
            width: 33px;
            height: 33px;
            float: left;
            border: solid 1px #d5d5d5;
            display: block;
            overflow: hidden;
            margin-top: 5px;
            @include border-radius(50%);

            img {
                display: block;
                width: 100%;
                height: 100%;
                @include border-radius(50%);
            }
        }

        .profile-right-block {
            margin-left: 45px;
        }

        .user-name {
            font-size: 14px;
            font-weight: 600;
            font-family: $title-font;
            margin: 0;
        }

        .user-mail {
            font-size: 12px;
            color: $sub-font-color;
            display: block;
            margin-top: -3px;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}

//header-language-dropdown
.header-language-dropdown {
    min-width: 154px;
    max-width: 300px;
    @include border-radius(0);
    @include box-shadow(0 2px 8px rgba(black, .17));
    background-color: #fff;
    margin-top: 5px;
    z-index: 1056;
    position: absolute;

    &::before {

        bottom: 100%;
        left: 10px;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        border-width: 7px;

    }

    .ant-select-dropdown-menu-item {
        padding: 8px 10px;
        border-bottom: 1px solid #DDDDDD;

        &:last-child {
            border-bottom: none;
        }
    }

    .ant-select-dropdown-menu-item-active {
        color: $primary-color-dark;
    }
}

//header-notification-dropdown
.header-notification-dropdown {
    border-top: 3px solid $primary-color;
    min-width: 265px;
    max-width: 100vw;
    @include border-radius(3px);
    //overflow-y: auto;
    //max-height: 250px;
    @include box-shadow(0 2px 8px rgba(black, .17));
    background-color: #fff;

    .ant-dropdown-menu-item {
        padding: 0;
        cursor: default;
        background-color: transparent;
    }

    .notification-menu-header {
        padding: 5px 20px;
        padding-right: 30px;
        position: relative;
        border-bottom: 1px solid #dddddd;
    }

    .total-notification {
        color: #757b89;
        font-size: 13px;
        font-weight: normal;

        b {
            font-weight: 500;
            font-family: $semibold-font;
        }
    }

    .notification-right-options {
        position: absolute;
        right: 10px;
        top: 50%;
        @include transform(translateY(-50%));
        .btn{
            margin: 3px 0 5px 5px;
        }
    }

    .notification-option-btn {
        font-size: 13px;
        font-weight: 500;
        color: #757b89;
        display: inline-block;
        margin-left: 10px;
        vertical-align: middle;

        &:hover {
            color: $primary-color;
        }
    }

    .read-all-notification-btn {
        width: 11px;
        height: 11px;
        border: solid 2px $sub-font-color;
        @include border-radius(50%);
        background-color: #fff;

       
    }

    .notification-drop-menu {
        margin: 0;
        text-decoration: none;
        border-bottom: 1px solid #dddddd;
        background-color: #fff;
        @include transition(all 0.2s linear);
        white-space: normal;

        &:hover {
            background-color: #f5f5f5;
        }

        .text-bold {
            font-weight: normal;
        }

        .notification-drop-menu-content {
            border-top: 2px solid #fff;
            border-bottom: 2px solid #fff;
            padding: 5px 20px;
            padding-right: 60px;
            position: relative;
        }

        .notification-text {
            color: rgba(#474747, .8);
            font-size: 13px;
            margin-bottom: 0;
            font-weight: normal;
            display: block;

            &:hover {
                text-decoration: none;
            }
        }
        .notification-circle-name{
            color: $primary-color;
            font-weight: 600;
        }
        .is-clickable:hover {
            color: $primary-color;
            cursor: pointer;
        }

        .date-block {
            font-size: 12px;
            font-weight: 500;
            color: $sub-font-color;
            display: inline-block;
            vertical-align: middle;
        }

        .item-option-wrap {
            display: block;
            margin-top: 8px;

            .item-option-btn {
                display: inline-block;
                vertical-align: middle;
                font-size: 11px;
                padding-bottom: 1px;
                margin-right: 5px;

                .icon-block {
                    display: inline-block;
                    margin-right: 5px;
                    vertical-align: middle;
                    font-size: 10px;
                    @include transition(none);
                }

                .icon-close {
                    font-size: 8px;
                }

                .text-block {
                    font-family: $semibold-font;
                    font-weight: 500;
                    font-size: 12px;
                    display: inline-block;
                    margin-left: 0;
                    @include transition(none);
                    vertical-align: middle;
                }
            }
        }

        .read-this-notification-btn {
            position: absolute;
            right: 20px;
            top: 50%;
            @include transform(translateY(-50%));
            width: 12px;
            height: 12px;
            border: solid 2px $primary-color;
            @include box-shadow(0 0 0 2px white);
            @include border-radius(50%);
            background-color: transparent;

            &:hover {
                border-color: $primary-color;
                background-color: $primary-color;
            }
        }
        .icon-delete-red{
            position: absolute;
            right: 45px;
            top: 50%;
            @include transform(translateY(-50%));
            @include border-radius(50%);
            color: $primary-color;
            font-weight: normal;
            opacity: 0;
            &:hover {
            }
        }

        &.unread {
            background-color: #E8EFF6;
            .notification-text {
                color: rgba(#474747, 1);
            }
            .is-clickable:hover {
                color: $primary-color;
                cursor: pointer;
            }
            .text-bold {
                font-weight: 500;
            }
        }
        &:hover {
            border-color: $title-font-color;
            .icon-delete-red{
                opacity: 1;
            }
        }
    }

    .loader {
        .logo-block {
            width: 30px;
        }

        .text-block {
            margin-left: 10px;
        }
    }

    @include min-sm {
        width: 350px;
    }
}

//header-quick-access-dropdown
.header-quick-access-dropdown {
    border-top: 3px solid $primary-color;
    max-width: 320px;
    width: 100%;
    //max-height: 350px;
    //overflow-y: auto; 
    @include border-radius(3px 3px 0 0);
    @include box-shadow(0 2px 8px rgba(black, .17));

    .header-block {
        padding: 8px 20px;
        color: #757B89;
        font-family: $title-font;
        font-size: 13px;
        font-weight: 600;
        background-color: #fff;
        border-bottom: 1px solid #ddd;
    }

    .quick-access-item-wrap {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;

        .quick-access-item {
            background-color: #fff;
            font-size: 14px;
            text-decoration: none;
            width: 50%;
            padding: 20px;
            text-align: center;
            vertical-align: top;
            border-bottom: 1px solid #ddd;

            &:nth-child(2n+1) {
                border-right: 1px solid #ddd;
            }

            &:hover {
                background-color: #f5f5f5;
            }
        }

        .icon-block {
            height: 48px;
            width: 48px;
            font-size: 20px;
            color: $primary-color;
            border: 2px solid $primary-color;
            line-height: 44px;
            @include border-radius(50%);
            display: inline-block;
            background-color: #fff;

            &.icon-size-lg {
                font-size: 22px;
            }

            &.icon-size-xl {
                font-size: 24px;
            }
        }

        .text-block {
            color: $title-font-color;
            font-size: 13px;
            display: block;
            font-weight: 500;
            font-family: $semibold-font;
            margin-top: 10px;
        }
    }

    .footer-block {
        padding: 12px 20px 10px;
        color: #757B89;
        font-family: $title-font;
        font-size: 13px;
        font-weight: 600;
        display: block;
        text-align: center;
        text-decoration: none;
        background-color: #fff;
        @include border-radius(0 0 3px 3px);

        &:hover {
            background-color: #f5f5f5;
        }

        .icon-block {
            display: inline-block;
            vertical-align: top;
            font-size: 16px;
            color: $primary-color;
            margin-right: 10px;
        }

        .text-block {
            color: $title-font-color;
            font-size: 13px;
            font-weight: 600;
            display: inline-block;
            vertical-align: bottom;
        }
    }
}

.header-circle-dropdown {
    border-top: 3px solid $primary-color;
    min-width: 200px;
    width: 100%;
    @include border-radius(3px 3px 0 0);
    @include box-shadow(0 2px 8px rgba(black, .17));

    &.mt-0 {
        margin-top: 0;
    }

    .header-block {
        padding: 8px 20px;
        color: #757B89;
        font-family: $title-font;
        font-size: 13px;
        font-weight: 600;
        background-color: #fff;
        border-bottom: 1px solid #ddd;

        .highlight {
            color: $primary-color;
            font-family: $font-family-base;
            font-weight: 600;
        }
    }

    .header-circle-item {
        background-color: #fff;
        font-size: 14px;
        text-decoration: none;
        display: block;
        padding: 10px 20px;
        border-bottom: 1px solid #ddd;
        @include transition(all 0.2s linear);

        &:hover {
            background-color: #f5f5f5;
        }

        &.active {
            background-color: #f5f5f5;

            .circle-name {
                color: $primary-color;
            }
        }

        &:last-child {
            border-bottom: none;
            @include border-radius(0 0 3px 3px);
        }

        .circle-avatar {
            width: 24px;
            height: 24px;
            line-height: 24px;
            float: left;
        }

        .circle-name {
            margin-left: 35px;
            color: $title-font-color;
            display: block;
            font-size: 12px;
            font-weight: bold;
            padding-top: 3px;
            font-family: $title-font;
        }
    }
}

//Header site settings dropdown
.header-site-settings-dropdown {
    border-top: 3px solid $primary-color;
    min-width: 240px;

    .ant-dropdown-menu-item {
        padding: 0;
        background-color: transparent;
        position: relative;

        .icon-block {
            position: absolute;
            left: 20px;
            top: 50%;
            font-size: 18px;
            margin-top: -9px;
            color: $sub-font-color;
        }

        .text-block {
            margin: 0;
            display: block;
        }
    }

    .site-settings-drop-menu {
        padding: 10px 20px;
        padding-left: 55px;
        font-family: $title-font;
        color: #474747;
        position: relative;
        margin: 0;
        border-bottom: 1px solid #dddddd;
        background-color: #fff;
        @include transition(all 0.2s linear);

        &:hover {
            background-color: #f5f5f5;
            color: #474747;
            text-decoration: none;
        }
    }

    .ant-dropdown-menu-item:last-child {
        .site-settings-drop-menu {
            border-bottom: none;
        }
    }
}

.outer-main-header {
    .header-login-link {
        display: none;
        color: #fff;
        text-decoration: none;
    }

    @include max-xs {
        .brought-block {
            display: none;
        }

        .header-login-link {
            display: inline-block;
            vertical-align: middle;
        }

        .header-right-block .header-right-menu .header-text-menu.dropdown-login {
            display: none;
        }
    }
}

.header-search-category-dropdown{
    border-top: 3px solid $primary-color;
    .ant-select-dropdown-menu-item {
        position: relative;
        padding-left: 38px;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ddd;
        .icon-block {
            position: absolute;
            left: 10px;
            top: 50%;
            font-size: 18px;
            color: $sub-font-color;
            width: 18px;
            height: 18px;
            background-color: transparent;
            margin-top: -10px;
        }

        .text-block {
            margin: 0;
            display: block;
            font-size: 12px;
            font-weight: bold;
            white-space: pre-wrap;
        }
    }
}

.search-selectbox{
    .ant-select-selection-selected-value{
        width: 100%; 
        .icon-block{
            background-color: transparent;
            position: relative;
            font-size: 18px;
            margin-right: 10px;
            top: 2px; 
        }
        .circle-avatar{
            display: inline-block;
            margin-right: 10px;
            width: 24px;
            height: 24px;
            line-height: 28px;
            font-size: 14px;
            vertical-align: middle;
        }
        .text-block{
            display: inline-block;
            max-width: 120px;
            @include ellipsis();
            vertical-align: middle;
        }
    }
}

.header-search-circle-dropdown{
    border-top: 3px solid $primary-color;
    .ant-select-dropdown-menu-item {
        position: relative; 
        padding-top: 10px;
        padding-bottom: 10px;
        display: flex;
        border-bottom: 1px solid #ddd;
        .circle-avatar {
            width: 24px;
            height: 24px;
            line-height: 24px;
            float: left;
            flex: 1 1 24px;
            max-width: 24px;
            font-size: 14px;
        }

        .text-block {
            margin-left: 8px;
            color: $title-font-color;
            display: block;
            font-size: 12px;
            font-weight: bold;
            padding-top: 3px;
            white-space: pre-wrap;
            font-family: $title-font;
        }
    }
}


// .lang-select-dd {
//     display: inline-block;
//     vertical-align: middle;
//     width: auto;
//     max-width: 100px;
//     cursor: pointer;

//     .ant-select-selection {
//         height: 26px;
//         border: none;
//         @include border-radius(0);
//         @include box-shadow(none);
//         background-color: transparent;
//     }

//     .ant-select-selection__rendered {
//         line-height: 26px;
//         margin-left: 8px;
//         margin-right: 3px;
//         font-size: 12px;
//         font-family: $title-font;
//         font-weight: bold;
//     }

//     .ant-select-selection__placeholder {
//         color: #000;
//     }

//     .ant-select-selection-selected-value {
//         color: $title-font-color;
//     }

//     .ant-select-arrow {
//         right: 8px;
//         color: #000;
//         font-size: 10px;
//         margin-top: -5px;
//     }

// }
