//////////////////////////////////
/// PAGE: TASK DETAILS PAGE ///
//////////////////////////////////
.task-details-wrapper {
    .task-details-header {
        padding: 10px 20px 0;

        .task-title {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 0;
        }

        .date-block {
            font-size: 14px;
            color: $sub-font-color;
            margin-bottom: 10px;
        }

        .complete-task-btn {
            font-size: 12px;
            font-weight: 600;
            padding: 0 20px;
            height: 26px;
        }
    }

    .task-details-content {
        padding: 10px 20px 20px;

        .para-text {
            font-size: 14px;
            line-height: 1.5;
            max-width: 900px;
            margin-bottom: 15px;

            p {
                font-size: 14px;
                line-height: 1.5;
            }
        }
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        .task-details-content {
            .para-text {
                font-size: 16px;

                p {
                    font-size: 16px;
                }
            }
        }
    }

    @include min-md {
        .task-details-header {
            .date-block {
                margin-bottom: 0;
            }

            .complete-task-btn {
                float: right;
            }
        }
    }
}

.task-details-option {
    .task-details-option-item {
        display: inline-block;
        vertical-align: middle;

        .label-block {
            font-size: 14px;
            color: #666;
            margin-bottom: 5px;
        }
    }

    .card-block {
        background-color: #fff;
        border: solid 1px #b3b3b3;
        height: 58px;
        min-width: 175px;
        padding: 8px 15px;
        @include box-shadow(0 3px 6px rgba(black, .24));
        @include border-radius(4px);
        margin-right: 15px;
        margin-bottom: 15px;

        .in-days {
            font-size: 14px;
            font-weight: 500;
            color: inherit;
            line-height: 1.2;
            padding-top: 2px;
            display: block;
        }

        .date-block {
            font-size: 15px;
            font-family: $title-font;
            font-weight: bold;
            display: block;
            color: $primary-color;
        }

        .avatar-block {
            float: left;
            border: solid 1px #d5d5d5;
        }

        .username-block {
            margin-left: 50px;
            font-size: 15px;
            font-weight: 600;
            padding-top: 8px;
        }
    }
}

.task-details-footer {
    padding: 15px 20px 0;
    border-top: 1px solid #dddddd;
    border-bottom: 1px solid #dddddd;

    .footer-btn {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
        font-weight: 500;
        color: $sub-font-color;
        margin-right: 20px;
        margin-bottom: 15px;

        .icon-block {
            margin-right: 5px;
        }

        .icon-block,
        .text-block {
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            color: #666;
        }
    }

    .delete-btn {
        &:hover {
            color: $error-color;
        }
    }
}