////////////////////////////////////////////////
/// Components: Compose Post Widget ///
////////////////////////////////////////////////

.compose-post-widget{
    background-color: #fff;
    .compose-post-header{
        padding: 15px 20px 0;
        margin-bottom: 10px;
        position: relative;
        .compose-title{
            font-size: 15px;
            padding-right: 25px;
            margin-bottom: 0;
            font-weight: 600;  
        }
        .compose-settings-btn{
            position: absolute;
            right: 20px;
            top: 20px;
            font-size: 16px; 
            color: #B3B3B3;
            &:hover{
                color: #666;
            }
        }
    }
    .simple-form{
        padding-bottom: 20px;
    }
    .compose-content-area{
        padding: 0 20px;
        position: relative; 
        .compose-user-avatar{
            position: absolute;
            left: 30px;
            top: 50%;
            width: 40px;
            height: 40px;
            line-height: 40px;
            font-size: 18px;
            margin-top: -20px;
            z-index: 2;
        }
        .form-group{
            margin-bottom: 0;
            padding-bottom: 0; 
        }
        .compose-text-area{
            position: relative;
            z-index: 1;
            padding: 10px 10px;
            margin-bottom: 0;
            resize: none;
            padding-left: 60px;
            @include border-radius(0);
            &:not(:focus){
                border-color: #e1e1e1;
            }
        }
    }
    .compose-post-footer{
        padding: 20px 20px 15px; 
        margin-top: -20px;
        .compose-post-option-btn{
            display: inline-block;
            font-size: 13px;
            vertical-align: middle;
            margin-right: 20px;
            font-weight: normal;
            font-weight: 500;
            font-family: $semibold-font;
            .icon-block{
                color: #B4B4B4;
                font-size: 16px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 8px;
                @include transition(all 0.2s linear);
            }
            .text-block{
                display: inline-block;
                vertical-align: middle;
            }
            &:hover{
                color: #000;
                .icon-block{
                    color: #666;
                }
            }
        }
        .compose-post-btn{
            min-width: 80px;
            height: 26px;
            line-height: 24px;
            margin-top: 15px;
            font-size: 13px;
            font-weight: 600; 
        }
        .preview-thumb-wrap{
            display: inline-block;
            vertical-align: middle;
            .preview-thumb{
                width: 30px;
                height: 30px;
                @include border-radius(3px);
                position: relative;
                border: solid 1px #eee;
                &:hover{
                    border-color: $primary-color;
                    .remove-item-btn{
                        //@include visibility(visible);
                        background-color: $error-color;
                        border-color: $error-color;
                        color: #fff;
                        &:hover{
                            background-color: $primary-color;
                            border-color: $primary-color;
                            color: #fff;
                        }
                    }
                }
                img{
                    width: 100%;
                    @include border-radius(3px);
                    height: 100%;
                    object-fit: cover;
                }
            }
            .remove-item-btn{
                position: absolute;
                width: 16px;
                height: 16px;
                text-align: center;
                line-height: 14px;
                border: solid 1px #eee;
                right: -8px;
                top: -8px;
                background-color: #fff;
                @include border-radius(50%);
                font-size: 6px;
                color: $sub-font-color;
                @include box-shadow(0 2px 2px rgba(black, .1));
                //@include visibility(hidden);
            }
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm { 
        .compose-post-footer{
            .compose-post-options{
                display: inline-block;
                min-height: 26px;
            }
            .compose-post-btn{
                float: right;
                margin-top: 0;
            }
        }
    }
}