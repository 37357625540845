////////////////////////////////////// 
/// PAGE: MEICATION RELATED PAGE ///
////////////////////////////////// /////
.end-date-picker {
    .ant-calendar-picker .anticon-close-circle {
        padding: 2px;
        top: 14px;
        width: 20px;
        height: 18px;
        right: 7px;
    }
}

.doses-wrapper {
    position: relative;

    .delete-item-col {
        margin-bottom: 23px;
        text-align: right;
    }

    .delete-item-button {
        width: 34px;
        height: 34px;
        @include border-radius(6px);
        border: solid 1px $sub-font-color;
        font-size: 16px;
        color: $sub-font-color;

        &:hover {
            color: $error-color;
            border-color: $error-color;
        }
    }

    @media (min-width: 576px) {
        padding-right: 45px;

        .delete-item-col {
            position: absolute;
            top: 50%;
            margin-top: -15px;
            right: -5px;
            margin-bottom: 0;
            text-align: left;
            width: auto;

            &.not-first {
                margin-top: -29px;
            }
        }
    }
}

.medication-listing-widget {
    .note-listing-item .list-item-media {
        margin-top: 10px;
        float: none;

        @include min-sm {
            margin-bottom: 0;
            margin-top: 0;
        }
    }

    .all-medication-list {
        .note-listing-item {
            cursor: pointer;

            &:hover {
                background-color: #fff;
            }
        }
    }

    .loading-completed,
    .no-loading-items {
        padding-bottom: 0;
    }

    @include min-sm {
        .medication-dosage-text {
            margin-top: 22px;
        }
    }
}