////////////////////////////////////////////////
/// Components: Upcoming List widget ///
////////////////////////////////////////////////
.upcoming-list-loader {
    padding: 20px 30px;
}

.upcoming-list-widget {
    .upcoming-list-item {
        padding: 10px 30px;
        position: relative;
        border-bottom: 1px solid #ddd;

        .no-item {
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: $sub-font-color;
        }

        &:last-child {
            border-bottom: none;
        }

        .icon-block {
            position: absolute;
            top: 50%;
            left: 30px;
            @include transform(translateY(-50%));
            font-size: 24px;
            color: $primary-color;

            &.size-large {
                font-size: 28px;
            }
        }

        .list-content-block {
            padding: 0 40px;
            position: relative;

            p {
                font-size: 14px;
                font-weight: 500;
                margin: 0;
                line-height: 1.5;
                font-family: $semibold-font;
            }

            .date-block {
                font-size: 13px;
                color: $sub-font-color;
                margin-top: 0;
            }
        }

        .list-action-btn {
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            font-size: 18px;
            color: $sub-font-color;

            &:hover,
            &.active {
                color: $primary-color;
            }
        }

        @include min-md {
            .list-action-btn {
                @include visibility(hidden);
            }

            &:hover {
                .list-action-btn {
                    @include visibility(visible);
                }
            }
        }
    }
}