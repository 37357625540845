/////////////////////////////////////////
/// Components: USER PREVIEW MODAL ///
/////////////////////////////////////////

.user-preview-modal {
    z-index: 99;

    .user-preview-header-btns {
        .ant-btn {
            margin-right: 5px;
            height: 34px;
            font-size: 12px;
        }
    }

    .user-info-block {
        border-bottom: 1px solid #DDDDDD;
        padding: 0 10px 20px;
        text-align: center;

        .member-name {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0;
            color: $title-font-color;
        }

        .since-on {
            font-size: 12px;
            color: #838995;
        }
    }

    .user-avatar-wrap {
        margin-bottom: 15px;
        position: relative;

        .member-pic {
            display: inline-block;
            width: 80px;
            height: 80px;
            font-size: 30px;
            line-height: 80px;
        }

        .edit-btn {
            display: inline-block;
            width: 28px;
            height: 28px;
            position: absolute;
            left: 50%;
            margin-left: 55px;
            top: 50%;
            margin-top: -14px;
            font-size: 12px;
        }
    }

    .information-block {
        .user-info-item {
            padding: 20px 0;
            padding-left: 35px;
            border-bottom: 1px solid #DDDDDD;
            position: relative;

            &.pl-0 {
                padding-left: 0;
            }

            &:last-child {
                border-bottom: none;
            }

            .email-text {
                color: rgba(0, 0, 0, .65);
            }
        }

        .icon-block {
            position: absolute;
            left: 0;
            font-size: 20px;
            color: $sub-font-color;
            top: 20px;
        }

        .content-block {
            font-size: 14px;
        }

        .title-block {
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .user-pic {
            display: inline-block;
            width: 44px;
            height: 44px;
            margin-right: 10px;
            margin-bottom: 12px;
            line-height: 44px;
        }
    }

    .create-new-btn {
        height: 30px;
        line-height: 28px;
        margin-top: 25px;
        font-size: 13px;
        font-weight: 500;
        padding: 0 25px;
    }
}