//////////////////////////
/// Component: Tags ///
//////////////////////////

.c-close-tag {
    height: 24px;
    line-height: 22px;
    width: auto;
    padding: 0 10px;
    border: 1px solid #B1B1B1;
    font-size: 12px;
    font-weight: 500;
    color: #838995;
    font-family: $title-font;
    @include border-radius(16px);
    cursor: pointer;
    margin-right: 7px;
    margin-bottom: 10px;

    .overflow-text {
        max-width: 120px;
        @include ellipsis();
        display: inline-block;
        vertical-align: middle;
    }

    .anticon-close {
        display: inline-block !important;
        vertical-align: middle !important;
        font-size: 14px !important;
        color: inherit;

        &:hover {
            color: inherit;
        }
    }

    &:hover {
        color: $primary-color;
        background-color: rgba($primary-color, .1);
        border-color: rgba($primary-color, 1);
    }
}

.c-clickable-tag {
    height: 24px;
    line-height: 22px;
    width: auto;
    padding: 0 10px;
    border: 1px solid #B1B1B1;
    font-size: 12px;
    font-weight: 600;
    color: #838995;
    font-family: $title-font;
    @include border-radius(16px);
    cursor: pointer;
    margin-right: 7px;
    margin-bottom: 10px;

    .icon-block {
        font-size: 11px;
        margin-left: 3px;

        &.icon-size-md {
            font-size: 10px;
        }
    }

    &:hover {
        color: $primary-color;
        background-color: rgba($primary-color, .1);
        border-color: rgba($primary-color, .0);
    }

    &.ant-tag-checkable-checked {
        color: #fff;
        background-color: $primary-color;
        border-color: $primary-color;
    }
}