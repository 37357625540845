////////////////////////////////////////////////
/// Components: Create a demo circle popup ///
////////////////////////////////////////////////
.create-demo-circle-modal{
    top: 300px;
    .right-warning-block{
        width: 100%;
        //background-color: rgba(black, .6);
        background-color: #303030;
        padding: 20px; 
        text-align: left;
        position: absolute;
        top: -255px;
        height: 245px;
        left: 0; 
        .question-block{
            font-weight: 500;
            color: #fff;
            font-size: 15px;
            margin-bottom: 15px;  
        }
        .para-block{
            font-size: 15px;
            color: rgba(#fff, .8);
        }
    } 
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm { 
        top: 80px;
        width: 700px !important;
        .ant-modal-content{
            width: calc(700px - 240px)
        }
        .right-warning-block{
            top: 0;
            left: auto; 
            right: -240px;
            width: 230px;
            height: auto;  
        }
    }
    @include min-md { 
        width: 930px !important;
        .ant-modal-content{
            width: calc(930px - 240px)
        } 
    } 
}