//////////////////////////
/// Component: Tooltip ///
//////////////////////////
.ant-tooltip{
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    .ant-tooltip-inner{
        text-align: center;
        padding: 10px 15px;
        @include border-radius(3px);
    }
}