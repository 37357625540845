////////////////////////////////////// 
/// PAGE: 404 PAGE ///
////////////////////////////////////// 

.not-found-wrapper{
    background-color: darken($primary-color, 5%);
    min-height: 100vh;
    .wrapper-block{
        width: 100%;
    }
    .block-header {
        background-color: #fff;
        padding: 15px 30px;
        text-align: center;
        height: 80px;
        .logo-block {
            display: inline-block;
            width: 110px;
            vertical-align: middle;
        }
    }

    .content-outer {
        padding: 50px 30px;
        text-align: center;
        display: flex;
        align-items: center;
        min-height: calc(100vh - 80px);
        justify-content: center;
    }
    .content-block {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .image-block{ 
        max-width: 640px;
    }
    .block-title {
        font-size: 28px;
        font-weight: 600;
        margin-bottom: 15px;
        color: #fff;
    }
    .block-para {
        font-size: 16px;
        color: #fff;
        margin: auto;
    }
    .back-btn{
        padding: 0 18px 0;
        height: 32px;
        margin-top: 25px;
        color: darken($primary-color, 5%);
        font-size: 14px;
        font-weight: 700;
        border: none;
        display: inline-flex;
        align-items: center;
        .btn-icon{
            @include visibility(hidden);
            margin-right: -10px;
        }
        .btn-text{
            padding-top: 2px;
        }
        &:hover{
            .btn-icon{
                @include visibility(visible);
                margin-right: 5px;
            }
        }
    }
}
