//////////////////////////////////
/// PAGE: CIRCLE SETTINGS PAGE ///
//////////////////////////////////

.circle-settings-tab-widget{
    background-color: #fff;
    @include box-shadow(0 0 3px rgba(black, .03)); 
    .circle-settings-tabs{
        background-color: #f4f4f4;
        border-bottom: 1px solid #ddd;
        padding-right: 20px;
        @include box-shadow(0 0 3px rgba(black, .03));
        &--circle-form{
            .circle-settings-tab-item{
                padding: 13px 30px;
            }
        } 
        .circle-settings-tab-item{
            padding: 13px 10px;
            background-color: #f4f4f4;
            color: #838995;
            font-weight: 500;
            font-family: $semibold-font;
            font-size: 13px;
            cursor: pointer;
            display: inline-block;
            margin-bottom: -1px;
            @include transition(all 0.2s linear);
            vertical-align: top;
            border-bottom: 1px solid #ddd;
            &:hover,
            &.active{
                background-color: #fff;
                color: $title-font-color;
                border-bottom-color: #fff;
            } 
        }
        .circle-settings-tab-option{
            float: right;
            font-size: 13px; 
            //color: $sub-font-color;
            margin-top: 11px;
            margin-right: 0;
            height: 26px;
            line-height: 24px;
            .icon-block,
            .text-block{
                display: inline-block;
                vertical-align: middle;
            }
            .icon-block{
                margin-right: 5px;
                font-size: 16px;
            } 
        }
    }
    .circle-settings-tab-content{
        padding: 15px 0;
        font-size: 0;
    }  
    @include max-xs-medium{
        .circle-settings-tabs{
            .circle-settings-tab-item{
                padding: 13px 5px; 
                font-size: 12px; 
            }
            &--circle-form{
                .circle-settings-tab-item{
                    padding: 13px 30px;
                }
            } 
            .circle-settings-tab-option{ 
                font-size: 12px; 
                margin-right: -15px;  
                .icon-block{ 
                    font-size: 14px;
                } 
            }
        }
    }
    @include min-sm{
        .circle-settings-tabs{ 
            .circle-settings-tab-item{
                padding: 13px 20px;
            }
        }
    }
}

.details-popup{
    p{
        font-size: 13px;
        color: #2c2c2c; 
    }
}
.create-demo-circle-wrapper{
    .more-head{
        width: 100%;
        cursor: pointer;
        text-align: left;
        padding: 0;
        border: none;
        color: $primary-color;
        font-size: 14px;
        font-weight: bold;
        background: transparent;
        position: relative;
        border-bottom: solid 1px #ccc;
        @include border-radius(0px);
        span{
            display: block;
        }
        span:after{
            content: "\e918";
            position: absolute;
            right: 0;
            top: 10px;
            font-size: 12px;
            font-family: 'jointlyIcons';
            z-index: 5;
            display: block;
            @include box-shadow(none);
            @include transition(all 0s linear);
        }
    }
    .more-head-c{
        width: 100%;
        cursor: pointer;
        text-align: left;
        padding: 0;
        border: none;
        color: $primary-color;
        font-size: 16px;
        background: transparent;
        position: relative;
        border-bottom: solid 1px #ccc;
        @include border-radius(0px);
        span{
            display: block;
        }
        span::after{ 
            content: "\e933";
            position: absolute;
            right: 0;
            top: 7px;
            font-size: 14px;
            z-index: 5;
            font-family: 'jointlyIcons';
            display: block;
            @include box-shadow(none);
            @include transition(all 0s linear);  
        }
    }
}