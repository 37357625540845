//////////////////////////
/// Component: Dropdown ///
//////////////////////////

.c-dropdown {
    @include border-radius(3px);
    background-color: #fff;
    @include box-shadow(0 2px 8px rgba(black, .17));
    border-top: 4px solid $primary-color;
    width: 260px;
    margin-top: 5px;

    &.c-dropdown-sm {
        width: 150px;
    }

    .c-dropdown-header {
        padding: 10px 20px;
        border-bottom: 1px solid #e3e3e3;

        .dropdown-title {
            font-size: 12px;
            font-weight: 600;
            color: #757b89;
            font-family: $title-font;
            display: block;
            line-height: normal;
        }

        .clear-btn {
            float: right;
            color: #757b89;
            font-size: 12px;
            font-weight: 700;

            &:hover {
                color: #666;
            }
        }

        &.has-positioned-options {
            position: relative;
            padding-right: 110px;

            .right-header-option {
                position: absolute;
                right: 20px;
                top: 50%;
                margin-top: 0;
                @include transform(translateY(-50%));
            }
        }

    }

    .c-dropdown-list {
        padding: 10px 0;

        &.p-t-b-0 {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .has-cursor-link {
        .c-dropdown-list-item {
            cursor: pointer;
        }
    }

    .icon-block {
        font-size: 16px;
        color: $sub-font-color;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
    }

    .list-item-text {
        font-size: 14px;
        font-family: $semibold-font;
        display: inline-block;
        font-weight: 500;
        vertical-align: middle;
        color: $title-font-color;
    }

    .has-bb {
        border-bottom: 1px solid #e3e3e3;
    }

    .c-dropdown-list-item {
        padding: 10px 20px;
        background-color: #fff;
        display: block;
        @include transition(all 0.2s linear);

        &:hover {
            background-color: #eee;
        }

        .c-checkbox {
            font-weight: 500;
        }
    }
}