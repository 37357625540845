//////////////////////////////////
/// PAGE: NOTE DETAILS PAGE ///
//////////////////////////////////
.note-details-wrapper {
    padding: 20px;

    .note-details-media {
        width: 100%;
        height: 200px;
        border: solid 4px white;
        @include box-shadow(0 2px 4px rgba(black, .27));
        @include border-radius(0);
        line-height: 158px;
        font-size: 48px;
        background-color: #e9e2e2;
        margin-bottom: 15px;
        color: #bab2b2;
        display: block;

        img {
            width: 100%;
            object-fit: contain;
            height: 100%;
        }
    }

    .no-image {
        img {
            object-fit: initial;
            width: 60px;
            height: auto;
            display: inline-block;
        }
    }

    .note-details-header {
        padding-top: 10px;

        .note-details-info {
            display: inline-block;
            vertical-align: middle;
            font-size: 12px;
            color: $sub-font-color;
            margin-right: 10px;
            margin-bottom: 5px;
        }

        .note-details-title {
            font-size: 22px;
            font-weight: 600;
            color: $title-font-color;
            font-family: $font-family-base;
            margin-bottom: 8px;
        }
    }

    .note-details-option {
        margin-bottom: 15px;

        .edit-btn {
            border: solid 1px #a7acb2;
            @include border-radius(20px);
            height: 22px;
            line-height: 20px;
            padding: 0 20px;
            font-size: 11px;
            font-weight: 600;
            font-family: $title-font;
            display: inline-block;
            vertical-align: middle;
            color: $sub-font-color;
            text-decoration: none;
            margin-right: 15px;

            &:hover {
                color: #fff;
                @include box-shadow(0 3px 7px rgba($primary-color, .49));
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }

        .delete-btn {
            display: inline-block;
            vertical-align: middle;
            color: $sub-font-color;
            font-size: 16px;

            &:hover {
                color: $error-color;
            }
        }
    }

    .note-tags {
        .tag-item {
            border: solid 1px #a7acb2;
            //@include border-radius(20px);
            padding: 1px 10px;
            font-size: 11px;
            font-weight: 600;
            font-family: $title-font;
            display: inline-block;
            vertical-align: middle;
            color: #666666;
            text-decoration: none;
            margin-right: 5px;
            margin-top: 10px;
        }

        a.tag-item {
            cursor: pointer;

            &:hover {
                color: #fff;
                @include box-shadow(0 3px 7px rgba($primary-color, .49));
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }

    .note-details-content {
        padding: 20px 0 0;

    }

    @include min-md {
        .top-wrapper {
            min-height: 200px;
        }

        .note-details-media {
            float: left;
            width: 250px;
        }

        .note-details-header {
            margin-left: 280px;
        }
    }
}


.note-form {
    .attachment-block {
        margin-top: 10px;
    }

    .compose-post-option-btn {
        display: inline-block;
        font-size: 13px;
        vertical-align: middle;
        margin-right: 20px;
        font-weight: normal;
        font-weight: 500;
        font-family: $semibold-font;

        .icon-block {
            color: #B4B4B4;
            font-size: 16px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;
            @include transition(all 0.2s linear);
        }

        .text-block {
            display: inline-block;
            vertical-align: middle;
        }

        &:hover {
            color: #000;

            .icon-block {
                color: #666;
            }
        }
    }

    .attachement-file-name {
        font-size: 12px;
        color: $sub-font-color;
        padding: 7px 0; 
        line-height: normal;
        word-break: break-all;

        .remove-item-btn {
            padding: 0;
            width: 24px;
            height: 24px;
            font-size: 12px;
            margin-left: 10px;
            line-height: 24px;
        }

        .file-name {
            display: inline-block;
            position: relative;
            padding-right: 32px;

            .remove-item-btn {
                position: absolute;
                right: 0;
                top: -4px;
            }
        }
    }
}
