//////////////////////////
/// Component: Select ///
//////////////////////////

.multi-tags-select{
    .ant-select-selection--multiple{
        border-color: #b1b1b1;
        .ant-select-selection__rendered > ul > .ant-select-selection__choice{
            @include border-radius(20px);
            border: 1px solid #B1B1B1;
            padding-bottom: 1px;
            margin-top: 4px;
        }
    }
}

.tags-dropdown{
    > div{
        margin-top: -4px;
    }
    .ant-empty{
        text-align: center;
        .ant-empty-image{
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
        .ant-empty-description{
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            font-size: 14px;
            color: $sub-font-color;
            font-weight: 500;
        }
    }
    .ant-select-dropdown-menu{
        border: solid 1px #b1b1b1;
        @include box-shadow (0 3px 6px 0 rgba(61,61,61,0.22));
        @include border-radius(0 0 4px 4px);
        .ant-select-dropdown-menu-item{
            padding: 10px 30px 10px 15px;
            background-color: #fff;
            border-bottom: 1px solid #D5D5D5;
            &:last-child{
                border-bottom: none;
            }
            .ant-select-selected-icon{
                font-size: 18px;
                color: $sub-font-color;
                @include transform(translateY(-50%));
            }
            &:hover{
                background-color: #eee;
                .ant-select-selected-icon{
                    color: $sub-font-color;
                }
            }
            &.ant-select-dropdown-menu-item-disabled{
                background-color: #fff;

            }
        }
    }
}

.default-dropdown{
    > div{
        margin-top: -4px;
    }
    .ant-empty{
        text-align: center;
        .ant-empty-image{
            display: inline-block;
            vertical-align: middle;
            margin: 0;
        }
        .ant-empty-description{
            display: inline-block;
            vertical-align: middle;
            margin-left: 10px;
            font-size: 14px;
            color: $sub-font-color;
            font-weight: 500;
        }
    }
    .ant-select-dropdown-menu{
        border: solid 1px #b1b1b1;
        @include box-shadow (0 3px 6px 0 rgba(61,61,61,0.22));
        @include border-radius(0 0 4px 4px);
        .ant-select-dropdown-menu-item{
            padding: 10px 15px;
            background-color: #fff;
            border-bottom: 1px solid #D5D5D5;
            &:last-child{
                border-bottom: none;
            }
            &:hover{
                background-color: #eee;
            }
            &.ant-select-dropdown-menu-item-disabled{
                background-color: #fff;
            }
        }
    }
}

.ant-select-dropdown-menu-item.ant-select-dropdown-menu-item-disabled{
    background-color: inherit;
}

