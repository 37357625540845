//////////////////////////////////
/// PAGE: CALENDAR PAGE ///
////////////////////////////////// 
.calendar-view-widget {
    background-color: transparent;
    @include box-shadow(none);

    .card-widget-header {
        background-color: #fff;
    }

    .card-widget-content {
        padding: 0;
    }
}

.calendar-listing-header {
    text-align: right;
    padding: 20px 20px 0;

    .calendar-switch {
        display: inline-block;
        text-align: left;
        font-size: 0;
        margin-bottom: 30px;

        .switch-btn {
            display: inline-block;
            vertical-align: middle;
            @include box-shadow(0 2px 6px rgba(0, 0, 0, 0));
            background: #fff;
            border: solid 1px #dcdcdc;
            color: #cfd5da;
            font-size: 14px;
            font-family: $title-font;
            font-weight: 500;
            padding: 0 7px;
            height: 30px;
            line-height: 28px;
            margin-left: -1px;

            &:first-child {
                @include border-radius(4px 0 0 4px);
            }

            &:last-child {
                @include border-radius(0 4px 4px 0);
            }

            &:hover,
            &.active {
                @include box-shadow(0 2px 6px rgba(0, 0, 0, 0.15));
                color: #838995;
            }
        }
    }
}

.calendar-list-wrapper {
    padding: 20px 0;

    @include min-sm {
        display: none;
    }

    .calendar-list-item {
        margin-bottom: 15px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .date-block {
            flex: 1 1 60px;
            text-align: center;
            max-width: 60px;
            font-size: 28px;
            line-height: 1;
            color: $title-font-color;
        }

        .day-name {
            display: block;
            font-size: 13px;
            padding-top: 5px;
            color: $sub-font-color;
        }

        .content-block {
            flex: 1;
        }


        .event-item {
            background-color: #fff;
            padding: 15px;
            margin-bottom: 10px;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
        }

        .dot-block {
            width: 11px;
            height: 11px;
            background-color: #eee;
            @include border-radius(50%);
            margin-right: 10px;
            position: relative;
            top: 4px;
        }


        .event-name {
            margin: 0;
            font-size: 14px;
            color: $title-font-color;
        }

        .time-block {
            display: block;
            padding-top: 3px;
            font-size: 13px;
            color: $sub-font-color;
        }

        .available-event {
            .dot-block {
                background-color: $primary-color-dark;
            }
        }

        .unavailable-event {
            .dot-block {
                background-color: #000;
            }
        }
    }
}

//calendar popover updates
.mbsc-event.mbsc-list-item{
    .click-whole-area{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
    }
    .popover-event-title span{
        color:$title-font-color ;
    }
    &:hover .popover-event-title span{
        text-decoration: underline;
        // color: $primary-color;
    }
}

.c-fullcalendar-full-loader{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, .80);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    max-height: 800px;
}
.mbsc-calendar-day-outer{
    .mbsc-calendar-label-wrapper,
    .mbsc-calendar-labels{
        @include opacity(0.3);
    }
}
.calendar-print-data{
    display: none;
    text-align: center;
    .logo-print{
        background-color: $primary-color;
        padding: 15px 5px;
        img{
            display: inline-block;
        }
    }
    .print-title-wrap{
    }
    .title{
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 0;
    }
    .circle-name{
        font-size: 14px;
    }
}
.event-note-print{
    display: none;
    padding-right: 20px;
}

//Calendar Print styles
@media print{
    body{
        background-color: white !important;
    }
    @page{
        size: A4;
        margin: 10mm;
    } 
    .ant-notification{
        display: none;
    }
    .calendar-page{
        // border: solid 10px red;
        background-color: white;
        .header-has-sticky,
        .main-header,
        .main-circle-sidebar,
        .calendar-header-block,
        .calendar-header .mbsc-segmented,
        .calendar-navigation .cal-header-prev,
        .calendar-navigation .cal-header-next,
        .mbsc-calendar-wrapper,
        .scroll-top-button,
        .subscription-has-expired{
            display: none;
        }
        .main-inner-wrapper,
        .main-right-container {
            padding: 0;
            margin: 0;
        }
        .circle-tabs-contents-wrapper{
            margin: 0;
        }
        .c-mobiscroll-calendar .mbsc-calendar-body .mbsc-calendar-row .mbsc-ios.mbsc-calendar-cell{
            border-color: black;
        }
        .calendar-print-data,
        .event-note-print{
            display: block;
        }
        .c-mobiscroll-calendar .mbsc-ios .mbsc-event-list{
            overflow: visible;
            max-height: none; 
            
        }
        // .c-mobiscroll-calendar .mbsc-ios .mbsc-event-list .mbsc-list,
        // .c-mobiscroll-calendar .mbsc-event-group{ 
        //     break-inside: avoid;
        // }
        .c-mobiscroll-calendar .mbsc-ios.mbsc-event.mbsc-list-item{ 
            break-inside: avoid;
        }
        .c-mobiscroll-calendar .mbsc-event-group .mbsc-ios.mbsc-event.mbsc-list-item{
            background-color: #eee;
        }
    }
}