.reminder-list-wrapper {
  // .circle-member-pic{
  //   border: solid 2px white;
  // }
  .reminder-name-col {
    word-break: break-word;
    min-width: 160px;
  }
  .reminder-name-row {
    display: flex;
    align-items: center;
    gap: 6px;
    &:empty {
      display: none;
    }
  }
  .count-badge {
    margin-left: 6px;
    .ant-badge-count {
      background-color: rgba($primary-color, 0.05);
      box-shadow: $primary-color 0px 0px 0px 1px inset;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: $primary-color;
    }
  }
  .reminder-type {
    margin-right: 6px;
    background-color: rgba($primary-color, 0.05);
    border: 1px solid $primary-color;
    border-radius: 65px;
    color: $primary-color;
    font-size: 11px;
    font-weight: 500;
    padding: 1px 8px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    .icon-block {
      margin-right: 3px;
      color: currentColor;
    }
  }
}
.reminder-view-wrapper {
  .reminder-sort-select {
    .ant-select-selection {
      border: none;
      background-color: transparent;
      outline: 0px;
      box-shadow: none;
      @include box-shadow(none);
    }
    &:focus {
      border: none;
      box-shadow: none;
    }
    .ant-select-arrow {
      font-size: 20px;
      right: 0;
      margin-top: -10px;
    }
  }
  .reminder-check {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(0, 0, 0, 0.2);
    @include border-radius(50%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .icon-tick {
    background-color: $primary-color;
    border-color: $primary-color;
    color: white;
  }
  .icon-untick {
    background-color: #fff;
    &:hover {
      border-color: rgba($primary-color, 0.5);
    }
  }
  .reminder-history-wrapper {
    padding: 16px;
    .activity-user {
      font-family: $title-font;
      font-weight: bold;
    }
  }
  .reminder-history-item {
    border-bottom: 1px solid rgba(209, 208, 208, 0.5);
    font-size: 14px;
    padding: 12px 0;
    &:last-child {
      border-bottom: none;
    }
  }
  .mark-completed {
    text-decoration: line-through;
  }
  .recurring-reminder-details {
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .recurring-item {
      font-size: 14px;
      margin-right: 15px;
      display: flex;
      align-items: center;
      padding-right: 15px;
      border-right: 1px solid #ddd;
      margin-top: 5px;
      &:last-child {
        border-right: none;
      }
    }
    .icon-block {
      color: $primary-color;
      margin-right: 8px;
      position: relative;
      top: -2px;
    }
    .reminder-tags {
      .tag-item {
          border: solid 1px #a7acb2;
          //@include border-radius(20px);
          padding: 1px 10px;
          font-size: 11px;
          font-weight: 600;
          font-family: $title-font;
          display: inline-block;
          vertical-align: middle;
          color: #666666;
          text-decoration: none;
          margin-right: 5px;
          margin-top: 10px;
      }

      a.tag-item {
          cursor: pointer;

          &:hover {
              color: #fff;
              @include box-shadow(0 3px 7px rgba($primary-color, .49));
              background-color: $primary-color;
              border-color: $primary-color;
          }
      }
  }
    
  }
  .reminder-expired{
    margin-right: 6px;
    background-color: rgba($primary-color, 0.05);
    border: 1px solid $primary-color;
    border-radius: 65px;
    color: $primary-color;
    font-size: 12px;
    font-weight: 500;
    padding: 3px 12px;
    display: inline-flex;
    align-items: center;
    white-space: nowrap;
    .icon-block {
      margin-right: 5px;
      color: currentColor;
    }
  }
}

.reminder-details-tab-widget {
  display: block;
  .circle-members-tabs {
    .circle-members-tab-item {
      margin-top: -1px;
      padding: 10px 20px;
    }
  }
}

.reminder-form-wrapper {
  .wrap-date-time {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    .at-seperate {
      padding: 0 10px;
      font-size: 14px;
      font-weight: 500;
      color: $text-color;
      padding-bottom: 20px;
    }

    .time-block {
      width: 120px;
    }
    .date-block {
      flex: 1;
    }
  }

  .timekeeper-pops {
    width: 260px;
    left: auto;
    right: 0;
  }

  .has-timer-convention {
    .ant-input-group .ant-input {
      min-width: 60px;
    }
  }
}
