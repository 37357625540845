//////////////////////////
/// Module: Sidebar ///
//////////////////////////
$sidebar-width: 245px;
$sidebar-width-xl: 280px;

.main-circle-sidebar {
    width: $sidebar-width;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    padding: 60px 5px 20px 15px;

    .circle-sidebar-information {
        //padding: 20px;
        padding: 20px 20px 20px 0;
    }

    .circle-listing-wrapper {
        display: block;
        min-height: 32px;
        position: relative;

        .circle-users {
            float: left;
            width: 32px;
            margin-top: 4px;

            .circle-user-item {
                display: inline-block;
                vertical-align: middle;
                font-size: 14px;
            }

            .cover-btn {
                display: inline-block;
                vertical-align: middle;
                width: 44px;
                height: 44px;
                background-color: #fff;
                @include border-radius(50%);
                margin-left: -4px;
                position: relative;
                padding: 4px;
                z-index: 1;
            }

            .circle-user-count {
                width: 36px;
                height: 36px;
                padding: 0;
                text-align: center;
                font-size: 14px;

                &:not(:hover) {
                    border-color: #757b89;
                    color: #757b89;
                }

                &:focus {
                    border-color: $primary-color;
                    color: #fff;
                }
            }
        }

        .circle-users-right-content {
            padding-left: 10px;
            margin-left: 32px;
            padding-right: 20px;

            .title-block {
                font-size: 16px;
                font-weight: 700;
            }

            .date-block {
                font-size: 12px;
                margin-top: -3px;
                color: #838995;
            }
        }

        .circle-settings-btn {
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -7px;
            font-size: 14px;
            @include opacity(0.5);

            span {
                display: block;
            }

            &:hover {
                color: $primary-color;
                @include opacity(1);
            }
        }
    }
    .circle-show-hidden-settings-btn{
        @include opacity(0.6);
        span {
            display: block;
        }

        &:hover {
            color: $primary-color;
            @include opacity(1);
        }
    }

    .right-sidebar-option {
        margin-top: 10px;
        padding: 5px 0 10px;
        border-top: 1px solid #ddd;
        border-bottom: 1px solid #ddd;

        .main-btn {
            font-size: 12px;
            font-weight: 500;
            position: relative;
            z-index: 9;
            font-family: $semibold-font;
            color: $default-font-color;
            margin-top: 3px;

            &:hover .text-block {
                text-decoration: underline;
                color: $primary-color;
            }

            .icon-block {
                color: $primary-color;
                font-size: 10px;
                margin-right: 5px;
            }
        }
    }

    .menu-scroll {
        overflow-x: hidden !important;

        >div {
            overflow-x: hidden !important;
        }
    }

    .thumb-vertical {
        background-color: rgba(0, 0, 0, 0.2);
        @include border-radius(20px);
    }

    @media (min-width: 768px) and (max-width: 991px) {
        padding: 60px 5px 20px 30px;
    }

    @include min-md {
        padding: 60px 5px 20px 50px;
        width: $sidebar-width-xl;
    }

    @include min-lg {
        padding: 60px 5px 20px 70px;

        .circle-sidebar-information {
            padding: 20px 0 20px 0;
        }
    }
}

.main-circle-tabs {
    padding-right: 10px;

    //padding-bottom: 40px;
    .main-circle-tab-item {
        padding: 7px 0;
        display: block;
        cursor: pointer;
        position: relative;
        @include transition(all 0.2s linear);
        color: $title-font-color;

        &:hover {
            color: $primary-color;
        }

        &:active,
        &.active {
            color: $primary-color;
        }

        .icon-block,
        .text-block {
            @include transition(all 0.2s linear);
        }

        .icon-block {
            font-size: 20px;
            position: absolute;
            left: 0;
            top: 5px;

            .ant-avatar-string {
                font-family: $title-font;
                font-size: 14px;
                line-height: 24px;
                font-weight: 600;
            }
        }

        .text-block {
            font-size: 14px;
            font-weight: bold;
            font-family: $title-font;
            display: block;
            padding-left: 40px;
            word-wrap: break-word;
        }
    }

    .sidebar-language-switch {
        padding: 2px 0 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1px solid rgb(221, 221, 221);
        margin-bottom: 15px;

        .text-block {
            font-size: 12px;
            color: #888888;
        }
    }

    .lang-select {
        display: inline-block;
        vertical-align: middle;
        width: auto;
        max-width: 100px;
        cursor: pointer;

        .ant-select-selection {
            height: 26px;
            border: none;
            @include border-radius(0);
            @include box-shadow(none);
            background-color: transparent;
        }

        .ant-select-selection__rendered {
            line-height: 26px;
            margin-left: 8px;
            margin-right: 3px;
            font-size: 12px;
            font-family: $title-font;
            font-weight: bold;
        }

        .ant-select-selection__placeholder {
            color: #888888;
        }

        .ant-select-selection-selected-value {
            color: $title-font-color;
        }

        .ant-select-arrow {
            right: 8px;
            color: #888888;
            font-size: 10px;
            margin-top: -5px;
        }

    }
}

.circle-tabs-contents-wrapper {
    margin-top: 10px;
}

//push menu for max 991px
.main-inner-wrapper {
    max-width: 100%;
    overflow: hidden;

    @include max-sm {
        // &:not(.menu-open) {
        //     .main-circle-sidebar {}
        // }

        .main-circle-sidebar {
            left: -($sidebar-width);
            @include transition(all 0.25s cubic-bezier(1, 0, 0.73, 1));
        }

        .main-right-container {
            margin-left: 0;
            left: 0;
            position: relative;
            @include transition(all 0.25s cubic-bezier(1, 0, 0.73, 1));
        }

        &.menu-open {
            .main-circle-sidebar {
                left: 0;
            }

            .main-right-container {
                left: $sidebar-width;
            }
        }
    }
}

//sidebar-language-dropdown
.sidebar-language-dropdown {
    min-width: 154px;
    max-width: 300px;
    @include border-radius(0);
    @include box-shadow(0 2px 8px rgba(black, .17));
    background-color: #fff;
    margin-top: 5px;
    z-index: 1056;
    position: absolute;

    // &::before {

    //     top: 100%;
    //     left: 10px;
    //     border: solid transparent;
    //     content: " ";
    //     height: 0;
    //     width: 0;
    //     position: absolute;
    //     pointer-events: none;
    //     border-color: rgba(255, 255, 255, 0);
    //     border-top-color: #fff;
    //     border-width: 7px;

    // }

    .ant-select-dropdown-menu-item {
        padding: 8px 10px;
        border-bottom: 1px solid #DDDDDD;

        &:last-child {
            border-bottom: none;
        }
    }

    .ant-select-dropdown-menu-item-active {
        color: $primary-color-dark;
    }
}