//////////////////////////////////////////////////
//Customization of: mobiscroll-react-v5
//////////////////////////////////////////////////

.c-mobiscroll-calendar {

    .mbsc-ios.mbsc-calendar{
        background: none;
    }
    
    //calendar header = month | week | day
    .calendar-header {
        background-color: #fff;
        padding: 15px 20px 8px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        width: 100%;

        .mbsc-segmented {
            text-align: center;
            background: none;
            margin: 0;
            margin-left: auto;
            @include min-sm {
                max-width: 300px;
            }
        }

        .mbsc-ios.mbsc-segmented {
            padding: 0;
            border: solid 1px $primary-color;
            @include border-radius(3.5px);
            .mbsc-segmented-item {
                min-width: 80px;
                cursor: pointer;
                &::before{
                    top: 0;
                    bottom: 0;
                    border-color: $primary-color;
                } 
                .mbsc-segmented-selectbox-animate{
                    display: none;
                }
                &:hover .mbsc-segmented-button {
                    background-color: rgba($primary-color, .2);
                }
                // input.mbsc-segmented-input:checked+.bsc-segmented-content {
                .mbsc-segmented-button.mbsc-selected {
                    background-color: $primary-color;
                    color: #fff;
                }
            }

            .mbsc-segmented-button {
                border: none;
                color: $primary-color;
                @include transition(all 0.2s linear);
            }
        }
    }

    //calandar navigations = prev, next and month name
    .calendar-navigation{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
        .mbsc-ios.mbsc-calendar-button.mbsc-button{
            color: $title-font-color;
        }
        .cal-header-nav{
            .mbsc-calendar-title {
                color: $title-font-color;
                font-size: 18px;
            }
        } 
        
    }

    //calendar cells
    .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-header, 
    .mbsc-ios.mbsc-eventcalendar .mbsc-calendar-wrapper{
        border: none;
        background-color: white;
    }
 
    .mbsc-calendar-wrapper-fixed .mbsc-calendar-body {
        padding: 0 20px 10px;
        // background-color: #fff;
        border: none;
        // @include box-shadow(0 0 3px rgba(black, .03));
    }

    .mbsc-calendar-body{

        .mbsc-calendar-table .mbsc-calendar-week-days{
            color: #B8B8B8;
            font-weight: 500;
            text-transform: uppercase;
        } 
        .mbsc-calendar-table .mbsc-calendar-week-days .mbsc-calendar-week-day{
            border-top: 1px solid #eee;
            background-color: white;
            font-size: 14px;
            padding: 10px 8px;
            &.mbsc-ltr{
                text-align: center;
            }
        }

        .mbsc-calendar-row{ 
            .mbsc-ios.mbsc-calendar-cell{
                border-color: #eee;
                .mbsc-calendar-cell-inner{
                    padding: 10px;
                    min-height: 50px;
                    @include max-xs {
                        padding: 5px 10px;
                        .mbsc-ios.mbsc-calendar-marks{
                            margin-top: 5px;
                        }
                    }
                    @include min-sm {
                        min-height: 100px;
                    }
                }
                .mbsc-calendar-cell-text,
                .mbsc-calendar-month-name{
                    color: #666;
                }
                .mbsc-calendar-cell-text{
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border: none;
                    @include max-xs {
                        margin: 0;
                    }
                }
                &.mbsc-ltr{
                    text-align: center;
                }
                &:first-child{
                    border-left: none;
                }
                &.mbsc-hover .mbsc-calendar-cell-text{
                    background-color: rgba($primary-color, .2);
                    color: $primary-color;
                }
                &.mbsc-selected .mbsc-calendar-cell-text{
                    background-color: $primary-color;
                    color: white;
                }
            }
            .mbsc-calendar-label-hover .mbsc-ios.mbsc-calendar-label-background{
                // background-color: inherit;
                // color: ;
                @include opacity(0.5);
            }
            .mbsc-ios.mbsc-calendar-label-background{
                // background-color: rgba($primary-color, 0.2);
                // border-left: 4px solid rgba(0,0,0,0.7);
                @include border-radius(4px);
                @include opacity(0.3);
            }
            .mbsc-calendar-text-more .mbsc-ios.mbsc-calendar-label-text{
                color: $primary-color;
            }
            .mbsc-calendar-label-hover.mbsc-calendar-text-more{
                .mbsc-ios.mbsc-calendar-label-text{
                    text-decoration: underline;
                }
            }
            .mbsc-ios.mbsc-calendar-label-text{
                color: #fff;
                font-weight: 500;
                font-size: 12px;
                padding-left: 10px;
            }
            .mbsc-calendar-text{
                padding: 0;
                height: 24px;
                line-height: 22px;
            }
            .mbsc-calendar-label-hover,
            .mbsc-calendar-label-active{
                &:not(.mbsc-calendar-text-more){
                    text-decoration: underline;
                }
            }
            .mbsc-calendar-label-hover,
            .mbsc-calendar-label-active{
                // color: $primary-color;
                @include opacity(0.8);
                &::before{
                    display: none;
                }
            }
        }
    
        .mbsc-ios .mbsc-cal-mark {
            background-color: $primary-color;
            @include equal-size(4px);
            @include border-radius(4px);
        }
    }

    .mbsc-ios.mbsc-event-list-empty {
        font-size: 18px;
        font-weight: normal;
        @include opacity(0.5);
    }

    //calendar bottom events
    .mbsc-ios .mbsc-event-list {
        margin-top: 20px;
        background: none;
        padding: 0;
        padding-left: 20px;
        max-height: 500px;
        overflow-y: auto;
        .mbsc-event-time{
            .mbsc-event-sep{
                display: block;
                margin: 0 3px;
            }
        }
    }
    .mbsc-event-group{
        position: relative;
        padding-left: 130px;
        @include min-sm {
            padding-left: 150px;
        }
        .mbsc-ios.mbsc-event-day.mbsc-list-header{
            position: absolute;
            left: 0;
            top: 5px;
            background: none;
            border: none;
            width: 90%;
            padding: 0;
            font-size: 14px;
            font-weight: 500;
            color: #707070;
        }
        .mbsc-ios.mbsc-event.mbsc-list-item{
            margin-bottom: 10px;
            border: none;
            padding: 10px;
            padding-left: 45px;
            background: #fff;
            border: none;
            @include box-shadow(0 0 3px rgba(black, .03));
            display: flex;
            position: relative;
            flex-direction: column;
            line-height: normal;
            &::before,
            &::after {
                display: none;
            }

            .mbsc-event-color {
                position: absolute;
                left: 20px;
                top: 16px;
                margin: 0;
                width: 12px;
                height: 12px;
                @include border-radius(50%);
                background-color: $primary-color;
            }

            .mbsc-event-time {
                width: auto;
                text-align: left;
                line-height: normal;
                font-size: 12px;
                padding-top: 3px;
                color: #888888;
                display: flex;
            }

            .mbsc-event-text {
                font-size: 14px;
                font-weight: normal;
                color: $title-font-color;
            }
            .mbsc-event-until{
                padding-right: 3px;
            }
            .mbsc-ios.mbsc-event-end{
                @include opacity(1);
            }
        }
    }
    .calendar-view--day{
        .mbsc-event-list{
            padding-left: 0;
        }
        .mbsc-event-group{
            padding-left: 0;
        }
        .mbsc-ios.mbsc-schedule-date-header{
            display: none;
        }
    }

}


//calendar more popsover
.mbsc-ios.mbsc-popup-round .mbsc-popup {
    .mbsc-fr-w {
        border: none;
        @include border-radius(0);
        max-width: 300px;
        width: 100% !important;
    }

    .mbsc-popup-body{
        @include box-shadow(0 0.125em 1em rgba(0, 0, 0, 0.2));
        @include border-radius(0);
    }

    .mbsc-ios.mbsc-event.mbsc-list-item{
        background: #FBFBFB;
        position: relative;
        border-bottom: solid 3px #FBFBFB;
        padding: 5px 15px;
        display: flex;
        flex-direction: column;
        @include transition(all 0.2s linear);
        &:hover {
            background-color: #eee;
        }

        &:last-child {
            border-bottom: none;
        }

        &::before,
        &::after {
            display: none;
        }

        .mbsc-event-color {
            position: absolute;
            left: 0;
            top: 0;
            width: 3px;
            margin: 0;
            height: 100%;
            background-color: $primary-color;
        }
    } 

    .mbsc-event-content {
        display: flex;
        flex-direction: column;
        font-size: 14px;
    }

    .mbsc-event-time {
        order: 2;
        margin: 0;
        font-size: 12px;
        color: #aaa;
        width: 100%;
        position: relative;
        text-align: left;
        .mbsc-event-until{
            position: absolute;
            top: 0;
            right: 0;
        }
        .mbsc-event-end{
            @include opacity(1);
        }
    }
}