////////////////////////////
/// Components: No Data ///
///////////////////////////

.no-data-found{
    text-align: center;
    padding: 60px 15px;
    .no-data-media{
        margin-bottom: 20px;
        img{
            display: inline-block; 
            max-width: 150px;
        }
    }
    .no-data-text{
        font-size: 14px;
        font-weight: 500;
        font-family: $semibold-font;
        color: $title-font-color;
        margin-bottom: 0;
    }
    .no-data-btn{
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        height: 34px;
        margin-top: 25px;
        padding: 0 20px;
        line-height: normal !important;
        .icon-block{
            font-size: 16px;
            margin-right: 6px;
            display: inline-block;
            vertical-align: middle;
            position: relative;
            top: -1px;
        }
        .text-block{
            display: inline-block;
            vertical-align: middle;
        }
    }
    a.no-data-btn{ 
        line-height: 32px !important;
    }

    @include min-sm{
        padding: 120px 15px;
        .no-data-text{
            font-size: 18px;
        }
        .no-data-media{
            img{
                display: inline-block; 
                max-width: 100%;
            }
        }
    }
}