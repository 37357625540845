//////////////////////////////////////////////////////////////////////////////
/// PAGE: TERMS, PRIVACY POLICY, GLANCE, VIDEO, COOKIES< FAQ, ETC PAGES ///
//////////////////////////////////////////////////////////////////////////////
.cms-content-block {
    background-color: #fff;
    padding: 30px;
    @include border-radius(3px);
    @include box-shadow(0 0 3px rgba(black, .03));

    h2,
    h3,
    h4,
    h5,
    h6 {
        font-weight: 600;
        margin-bottom: 10px;
        margin-top: 35px;
    }

    h1 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 20px;
    }

    h2 {
        font-size: 20px;
    }

    h3 {
        font-size: 18px;
    }

    h4 {
        font-size: 16px;
    }

    h5 {
        font-size: 14px;
    }

    h6 {
        font-size: 12px;
    }

    p {
        font-size: 14px;
        margin-bottom: 15px;
        word-break: break-word;
        word-wrap: break-word;

        @include min-sm {
            font-size: 16px;
        }
    }

    a {
        white-space: normal;
        word-break: break-all;
    }

    b {
        font-weight: 500;
        font-family: $semibold-font;
        display: inline;
    }

    ul {
        margin: 0 0 15px;
        list-style: none;

        li {
            position: relative;
            padding-left: 20px;
            margin-bottom: 10px;
            font-size: 14px;

            b {
                font-weight: 500;
                font-family: $semibold-font;
            }

            @include min-sm {
                font-size: 16px;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 6px;
                width: 8px;
                height: 8px;
                @include border-radius(50%);
                background-color: #333;
            }
        }
    }

    .simple-table {
        margin-bottom: 15px;

        td {
            color: $text-color;
            font-size: 15px;
        }
    }

    .c-collapse {
        .title-text {
            margin-top: 0;
        }
    }

    .stepper-wrapper {
        margin-top: -5px;

        .stepper-content {
            margin-top: 30px;
        }

        .ant-steps-dot {
            .ant-steps-item-tail {
                margin-left: 0;
            }

            .ant-steps-item-icon {
                margin-left: 0;
            }

            .ant-steps-item-content {
                width: 20%;
            }
        }

        .submit-btn {
            min-width: 100px;
            height: 38px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
        }

        .glance-media-block {
            img {
                width: 100%;
                max-width: 80%;
                margin: auto;
            }
        }

        @include max-xs {
            margin-top: 30px;

            .media-last-xs {
                order: 2;
            }
        }
    }

    .stepper-footer {
        margin-top: 15px;

        .ant-btn {
            min-width: 100px;
            height: 38px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
        }

        @include max-xs-medium {
            .ant-btn {
                margin-right: 5px;
                margin-bottom: 10px;
            }
        }
    }
}