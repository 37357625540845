//////////////////////////////////
/// PAGE: ANALYTICS ///
//////////////////////////////////

.analytics-wrapper{
    padding: 15px 20px;
    .filter-block{
        border-bottom: 1px solid #ddd;
        margin-bottom: 20px;
        .submit-btn{
            height: 34px;
            width: 100%;
            margin-bottom: 15px;
            @include min-sm{
                margin-bottom: 0;
                margin-top: 26px;
            }
        }
    }
    .site-card-wrapper{
        .export-btn{
            margin-bottom: 20px;
            display: flex;
            justify-content: flex-end;
            .icon-block{
                margin-right: 5px;
            }
            > a{
                display: flex;
                align-items: center;
            }
        }
    }
    @include min-lg{
        .col-xl-equal{
            width: 20%;
        }
    }
    .analytics-card-item{
        border: 1px solid #ddd;
        background-color: #fff;
        padding: 25px 25px 20px;
        @include box-shadow(0 0 3px rgba(black, .03));
        @include border-radius($radius);
        @include transition(all 0.2s linear);
        cursor: pointer;
        &:hover{
            border-color: rgba($primary-color, .49);
            @include box-shadow(0 2px 10px rgba(black, 0.1));
            .count-title{
                color: $primary-color;
            }
            .icon-block{
                @include visibility(visible);
            }
        }
        .title-text{
            font-weight: normal;
            color: $default-font-color;
            font-size: 16px;
            margin-bottom: 10px;
            color: $text-color;
            @include opacity(0.8);
        }
        .wrap-count{
            display: flex;
            align-items: center;
        }
        .count-title{
            font-size: 24px;
            color: $title-font-color;
            font-weight: 700;
            @include transition(all 0.2s linear);
            @include min-sm{
                font-size: 32px;
            }
        }
        .icon-block{
            color: $primary-color;
            margin-left: 10px;
            font-size: 14px;
            @include visibility(hidden);
        }
    }
}

.analytics-list-wrapper{
    background-color: #fff;
    .card-widget-header{
        .wrap-left{
            padding-bottom: 15px;
        }
        @include min-sm{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .wrap-left{
                padding-bottom: 0;
                padding-right: 15px;
            }
        }
        .right-header-option{
            float: none;
            margin: 0;
            display: flex;
            align-items: center;
        }
        .export-btn{
            display: flex;
            align-items: center;
            max-width: 170px;
            justify-content: center;
            margin-left: 10px;
            .icon-block{
                margin-right: 5px;
            } 
        } 
    }
    .filter-options{
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5px;
        p{
            @include opacity(0.8);
            margin-bottom: 0;
            margin-top: 5px;
            font-size: 14px;
            margin-right: 15px;
        }
    }

    .analytics-tab-wrapper {
        .ant-tabs-bar {
            margin-bottom: 0;
        }

        .ant-tabs-nav .ant-tabs-tab {
            padding: 12px 20px;
            margin: 0 15px 0 0;
            font-family: $title-font;
            font-weight: bold;
        }

        .c-collapse .ant-collapse-content-box {
            padding-left: 0;
        }
        ///////////////////////
        /// Media queries ///
        ///////////////////////
        @include min-sm {
            .tasks-tab-wrapper {
                .ant-tabs-nav .ant-tabs-tab {
                    padding: 12px 20px;
                }
            }
        }
    }
}
.data-nil{
    @include opacity(0.5);
}