/////////////////////////////////////////
/// Components: App Invite Widget ///
/////////////////////////////////////////

.app-invite-widget {
    background-color: #fff;
    padding: 10px 30px;
    position: relative;

    .media-block {
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        text-align: right;

        img {
            padding: 5px 20px 20px;
            display: inline-block;
            max-height: 100%;
        }
    }

    .widget-content {
        //padding-right: 100px;
        position: relative;
        z-index: 2;
    }

    .invite-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;

        .highlighted {
            font-weight: 700;
            color: $primary-color;
        }

        .break-word {
            display: block;
        }
    }

    .para-text {
        font-size: 15px;
        line-height: 1.5;
        margin-bottom: 10px;
    }

    .invite-btn {
        height: 26px;
        line-height: 26px;
        font-size: 13px;
        font-weight: 600;
        min-width: 80px;
        margin-top: 5px;
    }
}


.invite-member-wrapper {
    .invite-member-wrapper-fixed-height {
        height: 190px;
        margin-right: -5px;
    }

    .invite-member-item {
        margin-bottom: 5px;
        position: relative;
    }

    .circle-permission-input-block {
        display: inline-block;
        vertical-align: middle;

        .ant-checkbox-group-item {
            display: inline-block;
            vertical-align: middle;
            position: relative;
            width: auto;
            margin-right: 10px;
            padding-left: 46px;
            border: solid 1px #b1b1b1;
            height: 34px;
            @include border-radius(4px);
        }

        .ant-checkbox {
            float: right;
            margin-top: 8px;
            margin-right: 10px;
        }

        .icon-block {
            position: absolute;
            color: $sub-font-color;
            left: 10px;
            top: 50%;
            @include transform(translateY(-50%));
        }

        .icon-admin {
            font-size: 28px;
        }

        .icon-pencil {
            font-size: 18px;
        }

        .icon-spy {
            font-size: 20px;
        }
    }

    .delete-item-button-wrap {
        display: inline-block;
        vertical-align: middle;

        .delete-item-button {
            width: 34px;
            @include border-radius(4px);
            height: 34px;
            border: solid 1px $primary-color;
            color: $primary-color;

            &:hover {
                background-color: $primary-color;
                color: #fff;
            }
        }
    }

    .invite-member-add-options {
        margin-top: 0;

        .option-btn {
            font-size: 12px;
            display: inline-block;
            vertical-align: middle;
            font-weight: 500;
            color: $primary-color;

            .icon-block {
                font-size: 11px;
                margin-right: 5px;
                display: inline-block;
                vertical-align: middle;
            }

            .text-block {
                display: inline-block;
                vertical-align: middle;
                color: $title-font-color;
            }

            &:hover .text-block {
                text-decoration: underline;
            }
        }

        .or-block {
            // width: 24px;
            // height: 24px;
            // @include border-radius(50%);
            // border: solid 1px $primary-color;
            font-size: 12px;
            font-weight: 600;
            margin: 0 10px;
            color: $sub-font-color;
            //color: $primary-color;
            text-align: center;
            display: inline-block;
            vertical-align: middle;
            line-height: 22px;
        }
    }

    .error-msg {
        font-size: 12px;
        font-weight: 500;
        padding-bottom: 15px;
        margin-top: -15px;
        color: #f5222d;
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
    }

    @include min-sm {
        .invite-member-wrapper-fixed-height {
            height: 260px;
        }

        .email-input-block,
        .full-name-input-block {
            width: 45%;
            width: calc(50% - 5px);
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }

        .full-name-input-block {
            margin-right: 0;
        }

        &.invite-member-wrapper--full-width {

            .email-input-block,
            .full-name-input-block {
                width: 100%;
            }
        }
    }

    @include min-md {
        .invite-member-wrapper-fixed-height {
            height: 288px;
        }

        .email-input-block,
        .full-name-input-block {
            width: 195px;
        }

        .full-name-input-block {
            margin-right: 10px;
        }
    }
}