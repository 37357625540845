////////////////////////////////////////////////
/// Components: Your Circle List widget ///
////////////////////////////////////////////////  
.your-circle-list-loader{
    padding: 20px 30px; 
}
.your-circle-list-widget{
    .your-circle-list-item{
        padding: 10px 20px;
        position: relative;
        border-bottom: 1px solid #ddd;
        .no-item{
            font-size: 14px;
            font-weight: 500;
            text-align: center;
            color: $sub-font-color;
        }
        &:last-child{
            border-bottom: none;
        }
        .icon-block{
            position: absolute;
            top: 50%;
            left: 30px;
            @include transform(translateY(-50%));
            font-size: 24px;
            color: $primary-color;
            &.size-large{
                font-size: 28px;
            }
        }
        .circle-avatar{
            float: left;
            display: block;
            margin-top: 2px;
        }
        .list-content-block{ 
            position: relative;
            display: block;
            margin-left: 50px;
            .circle-user-name{
                cursor: pointer;
                &:hover{
                    color: $primary-color;
                }
            }
            p{
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5; 
                font-family: $semibold-font;
            }
            .date-block{
                font-size: 13px;
                color: $sub-font-color; 
                margin-top: -10px;
            }
        }
        .list-action-btn{
            position: absolute;
            top: 50%;
            right: 0;
            @include transform(translateY(-50%));
            font-size: 18px;
            color: $sub-font-color;
            &:hover,
            &.active{
                color: $primary-color;
            }
        }
        @include min-sm{
            .circle-avatar{ 
                margin-top: 0;
            }
            .list-content-block{  
                @include clearfloat();
                padding-top: 6px;
                .circle-user-name{
                    float: left;
                    margin-bottom: 0;
                    width: 60%;
                } 
                .date-block{ 
                    margin-top: 0;
                    width: 40%;
                    text-align: right;
                    float: right;
                }
            }

        }
        @include min-md{
            .list-action-btn{
                @include visibility(hidden);
            }
            &:hover{
                .list-action-btn{
                    @include visibility(visible);
                }
            }
        }
    }
}