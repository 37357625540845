//////////////////////////////////
/// PAGE: CONTACTS PAGE ///
////////////////////////////////// 
.contacts-table-widget {
    .card-widget-header {
        padding: 10px 20px 8px;
    }
}

.contacts-table-wrapper {
    .c-table .ant-checkbox-wrapper {
        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }
    }
    .table-action-btns{
        white-space: nowrap;
        .action-btn{
            margin-right: 10px;
            &:last-child{
                margin-right: 0;
            }
        }
    }
    .red-icon{
        color: $primary-color;
    }
    .min-sm {
        display: none;
    }

    @include min-sm {
        .max-xs {
            display: none;
        }

        .min-sm {
            display: block;
        }
    }
}

@include min-sm {
    .contact-image-upload-wrapper {
        .upload-profile-picture {
            margin-top: 26px;
        }
    }
}