//////////////////////////////////
/// PAGE: MY PROFILE PAGE ///
//////////////////////////////////
.my-profle-header-widget{
    margin-bottom: 10px;
    .card-widget-content{
        padding: 15px 20px;
    }
    .my-account-info{
        min-height: 45px;
        display: inline-block;
        .avatar-block{
            margin-top: 2px;
            float: left;
            border: solid 1px #d5d5d5;
        }
        .right-profile-details{
            margin-left: 50px;
            padding-top: 0;
        }
        .user-name{
            font-size: 16px; 
            font-weight: 500;
            margin-bottom: 0;
            font-family: $semibold-font; 
        }
        .user-email{
            font-size: 14px;
            color: #838995;
            display: block;
            margin-top: -2px;
        }
    }
    .your-circle-wrapper{
        margin-top: 15px;
        .circle-label{
            display: inline-block;
            font-size: 13px;
            font-weight: 600;
            font-family: $title-font;
            color: #757b89;
            vertical-align: middle;
            margin-right: 20px;
        }  
        .circle-lists{
            display: inline-block;
            vertical-align: middle;
        }  
        .circle-list-item{
            display: inline-block;
            vertical-align: middle;
            margin-right: 10px;
        }
        .circle-list-item-count{
            display: inline-block;
            vertical-align: middle;
            width: 32px;
            height: 32px;
            line-height: 30px;
            border: solid 1px $sub-font-color;
            @include border-radius(50%);
            text-align: center;
            font-size: 13px;
            font-weight: 700;
            color: $sub-font-color;
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm { 
        .your-circle-wrapper{
            margin-top: 7px;
            float: right;
        }
    }
}

.edit-my-info-widget{ 
    margin-bottom: 10px;
    .card-widget-content{
        padding: 15px 20px;
    }
}