////////////////////////////////////////////////
/// Components: Upload Profile Picture ///
////////////////////////////////////////////////
.upload-profile-picture{
    padding: 20px 15px;
    border: solid 1px #b1b1b1;
    @include border-radius(4px);
    height: 118px;
    margin-bottom: 30px;
    position: relative;
    &:hover{ 
        .remove-item-btn{
            //@include visibility(visible);
            background-color: $error-color;
            border-color: $error-color;
            color: #fff;
            &:hover{
                background-color: $primary-color;
                border-color: $primary-color;
                color: #fff;
            }
        }
    }
    .preview-thumb-wrap{
        position: absolute;
        left: 85px;
        top: 30px;
        @media (max-width: 479px){
            left: 50%;
            top: 20px;
        }
    }
    .remove-item-btn{
        $size: 20px;
        position: absolute;
        width: $size;
        height: $size;
        text-align: center;
        line-height: ($size - 2);
        border: solid 1px #eee;
        right: -($size/2);
        top: -($size/2);
        background-color: #fff;
        @include border-radius(50%);
        font-size: 7px;
        color: $sub-font-color;
        @include box-shadow(0 2px 2px rgba(black, .1));
        //@include visibility(hidden);
    }
    .avatar-block{
        border: solid 1px #d5d5d5; 
        float: left;
    }
    .edit-pic-btn-wrap{
        display: block;
    }
    .ant-upload-list{ 
        .ant-upload-list-item{
            margin: 0;
        }
        .ant-upload-list-item-info{
            padding: 0 10px;
            @include ellipsis();
        }
    }
    .upload-profile-right-block{
        margin-left: 94px; 
        padding-top: 10px;
        .upload-profile-title{
            font-size: 14px;
            font-family: $semibold-font;
            font-weight: 500;
            margin-bottom: 8px;
            color: $default-font-color;
        }
        .edit-pic-btn-wrap{
            display: inline-block;
            vertical-align: middle; 
        }
        .edit-pic-btn{
            font-size: 13px;
            padding: 0 15px;
            height: 30px;
        }
        .delete-pic-btn{
            width: 30px;
            height: 30px;
            padding: 0;
            margin-left: 5px;
            font-size: 14px;
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include max-xs-medium { 
        height: auto; 
        text-align: center;
        .avatar-block{
            float: none;
        }
        .upload-profile-right-block{
            margin-left: 0; 
        }
    }
    @include min-sm { 
        .ant-upload-list{
            margin-top: -5px;
            margin-left: 94px; 
        }
    }
    @include min-md { 
        margin-top: 28px;
        margin-bottom: 0;
    }
}