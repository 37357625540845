////////////////////////////////////////////////
/// Components: Create a new circle popup ///
////////////////////////////////////////////////
.create-new-circle-modal{
    top: 370px;
    .right-free-access-block{
        width: 100%;
        //background-color: rgba(black, .6);
        background-color: #303030;
        padding: 30px;
        text-align: center;
        position: absolute;
        top: -280px;
        height: 270px;
        left: 0;
        .media-block{
            margin-bottom: 35px;
            img{
                display: inline-block;
            }
        }
        .question-block{
            font-weight: 500;
            color: #fff;
            font-size: 15px;
            margin-bottom: 25px;  
        }
        .para-block{
            font-size: 15px;
            color: #fff;
        }
    }
    .circle-security-wrapper{
        padding-top: 10px;
        .ant-col{
            margin-top: 7px;
        }
    }
    .circle-security-item{
        text-align: center;
        border: solid 1px #979797;
        @include border-radius(4px);
        padding: 15px;
        height: 100%;
        .media-block{
            margin-bottom: 10px;
            height: 45px;
            display: -webkit-flex;
            display: -ms-flex; 
            display: flex; 
            justify-content: center; 
            -ms-align-items: flex-end; 
            align-items: flex-end; 
            
        }
        p{
            font-size: 13px;
            color: #757b89;
            margin: 0; 
        }
    }
    .payment-wrapper{
        text-align: center;
        padding-top: 30px;
        img{
            display: inline-block;
            vertical-align: middle;
            margin: 4px;
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm { 
        top: 80px;
        width: 700px !important;
        .ant-modal-content{
            width: calc(700px - 240px)
        }
        .right-free-access-block{
            top: 0;
            left: auto; 
            right: -240px;
            width: 230px;
            height: auto;  
        }
    }
    @include min-md { 
        width: 930px !important;
        .ant-modal-content{
            width: calc(930px - 240px)
        } 
    } 
}