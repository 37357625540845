//////////////////////////
/// Component: Checkbox ///
//////////////////////////
.c-checkbox,
.c-table .ant-checkbox-wrapper,
.c-checkbox-group .ant-checkbox-wrapper {
    &.cb-hidden {
        @include visibility(hidden);
    }

    &.size-md {
        .ant-checkbox-inner {
            width: 18px;
            height: 18px;
        }
    }

    &.size-lg {
        .ant-checkbox-inner {
            width: 20px;
            height: 20px;
        }
    }

    .ant-checkbox-inner {
        @include border-radius(3px);
        border-color: #aeb1b5;
        background-color: #fff;

        &::after {
            @include box-shadow(inset 0 0 0 1px #fff);
            height: 10px;
            width: 6px;
            left: 50%;
            margin-left: -4px;
        }
    }

    .ant-checkbox-indeterminate {
        .ant-checkbox-inner {
            position: relative;

            &::after {
                @include box-shadow(none);
                height: 70%;
                width: 70%;
                max-width: 10px;
                max-height: 10px;
                margin-left: 0;
                margin-top: 0;
            }
        }
    }

    .ant-checkbox-checked .ant-checkbox-inner {
        border-color: $primary-color;
        background-color: #fff;
        @include box-shadow(inset 0 0 2px rgba($primary-color, .5));

        &::after {
            border-color: $primary-color;
        }
    }

    .ant-checkbox-disabled .ant-checkbox-inner {
        border-color: #ccc;
        background-color: #fff;
        @include box-shadow(inset 0 0 2px rgba(#ccc, .5));

        &::after {
            border-color: #ccc;
        }
    }
}


.c-checkbox-group.size-md {
    .ant-checkbox-wrapper {
        .ant-checkbox-inner {
            width: 18px;
            height: 18px;
        }
    }
}

.c-checkbox {
    &.align-left {
        position: relative;
        padding-left: 26px;

        .ant-checkbox {
            position: absolute;
            left: 0;
            top: 0;

            +span {
                padding: 0;
            }
        }

        &.size-md {
            padding-left: 28px;
        }

        &.size-lg {
            .ant-checkbox-inner {
                padding-left: 30px;
            }
        }
    }
}