/////////////////////////////////////////
/// Components: Upload Button Widget ///
/////////////////////////////////////////

.upload-btn-widget {
    position: relative;

    .upload-media {
        width: 100%;
        height: 112px;
        border: solid 4px white;
        @include box-shadow(0 2px 4px rgba(black, .27));
        @include border-radius(0);
        line-height: 104px;
        font-size: 48px;
        background-color: #e9e2e2;
        margin-bottom: 20px;
        color: #bab2b2;
        display: block;

        img {
            object-fit: contain;
        }
    }

    .ant-upload-select {
        display: block;
    }

    .upload-btn {
        height: 28px;
        padding: 0 20px;
        font-size: 12px;
        font-weight: 600;
    }

    .upload-main-wrap {
        position: relative;
        display: inline-block;
        max-width: 158px;
        width: 100%;
        text-align: center;

        &:hover {
            .remove-item-btn {
                //@include visibility(visible);
                background-color: $error-color;
                border-color: $error-color;
                color: #fff;

                &:hover {
                    background-color: $primary-color;
                    border-color: $primary-color;
                    color: #fff;
                }
            }
        }

        .preview-thumb-wrap {
            position: absolute;
            right: 0;
            top: 0;
        }

        .remove-item-btn {
            $size: 20px;
            position: absolute;
            width: $size;
            height: $size;
            text-align: center;
            line-height: ($size - 2);
            border: solid 1px #eee;
            right: -($size/2);
            top: -($size/2);
            background-color: #fff;
            @include border-radius(50%);
            font-size: 7px;
            color: $sub-font-color;
            @include box-shadow(0 2px 2px rgba(black, .1));
            //@include visibility(hidden);
        }
    }

    .attachement-file-name {
        font-size: 12px;
        color: $sub-font-color;
        margin-bottom: 10px;
        margin-top: -5px;
        line-height: normal;
        word-break: break-all;
    }
}

.attachment-add-btn {
    font-weight: 600;
    color: #666;
    white-space: normal;
    text-align: left;
    position: relative;
    padding-left: 25px;
    word-wrap: break-word;

    .icon-block {
        font-size: 20px;
        display: inline-block;
        vertical-align: middle;
        position: absolute;
        top: -2px;
        left: 0;
    }

    .text-block {
        display: inline-block;
        vertical-align: middle;
        font-size: 14px;
    }

    &:hover {
        color: $title-font-color;
    }
}
