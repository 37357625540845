//////////////////////////////
/// Component: Pop Confirm ///
//////////////////////////////

.ant-popover {
    border-top: 4px solid $primary-color;
    @include border-radius(3px);
    max-width: 300px;
    text-align: center;

    .ant-popover-message-title {
        font-size: 14px;
        font-weight: 500;
        color: $title-font-color;
        text-align: left;
    }

    .icon-block {
        position: absolute;
        left: 0;
        top: 7px;
        font-size: 16px;
        color: $sub-font-color;
    }

    .icon-warning {
        color: #FDC228;
    }

    .ant-popover-message>.anticon {
        position: relative;
        top: auto;
    }

    .ant-popover-message-title {
        display: inline-block;
        padding-left: 22px;
    }

    .ant-popover-buttons {
        text-align: center;
        padding-left: 0;
        display: flex;
        justify-content: center;

        .ant-btn {
            height: 24px;
            margin: 0 5px;
            font-size: 12px;
        }

        .ant-btn:not(.ant-btn-primary) {
            color: $sub-font-color;
            border: none;
            font-weight: 600;
            order: 2;

            &:hover {
                color: #666;
            }
        }

        .ant-btn-primary {
            order: 1;
            float: none;
            @include border-radius(0);
            font-weight: 600;
            padding: 0 15px;
        }
    }

    &.ant-popover-placement-bottom,
    .ant-popover-placement-bottomLeft,
    &.ant-popover-placement-bottomRight {
        padding-top: 0;
    }
}