//////////////////////////
/// Modal ///
//////////////////////////
.ant-modal-mask {
    background-color: rgba(#4d4d4d, .75);
    // z-index: 1058;
}
.ant-modal{
    @include transition(all 0.3s linear);       
}

.ant-modal-wrap {
    // z-index: 1059; 
    //android input focus issue solve on position fixed container
    border: solid 2px transparent;
    //ref: https://stackoverflow.com/questions/43793262/input-immediately-loses-focus-after-click-on-android
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.simple-modal {
    max-width: 90%;
    max-width: calc(84vw);

    .ant-modal-header {
        padding: 14px 20px;
        border-bottom-color: #dddddd;
    }

    .ant-modal-title {
        font-size: 14px;
        font-weight: 600;
        font-family: $title-font;
        color: $default-font-color;
    }

    .ant-modal-content {
        @include box-shadow(none);
        @include border-radius(0);
    }

    .ant-modal-close {
        color: #b4b4b4;

        &:hover {
            color: #666;
        }
    }

    .ant-modal-close-x {
        font-size: 18px;
    }

    .ant-modal-body {
        padding: 20px 20px;
    }

    .ant-modal-footer {
        padding: 20px 20px;
        text-align: left;
        border-top-color: #dddddd;

        >div {
            display: -webkit-flex;
            /* Safari */
            display: flex;
        }

        .ant-btn {
            height: 32px;
            padding: 0 20px;
            font-size: 14px;
        }

        .cancel-btn {
            -webkit-order: 2;
            order: 2;
            @include box-shadow(none);
            color: $sub-font-color;
            background-color: transparent;
            border: none;

            &:hover {
                color: #666;
            }
        }

        .ant-btn-primary {
            -webkit-order: 1;
            order: 1;
            min-width: 100px;
            margin-left: 0;
        }
    }

    .ant-modal-confirm-btns {
        float: none;
        display: -webkit-flex;
        /* Safari */
        display: flex;
        border-top: solid 1px #dddddd;
        margin: 30px -20px 0;
        padding: 25px 20px 0;

        .ant-btn {
            height: 32px;
            padding: 0 20px;
            @include border-radius(0);
            font-size: 14px;

            &:not(.ant-btn-primary) {
                -webkit-order: 2;
                order: 2;
                @include box-shadow(none);
                color: $sub-font-color;
                background-color: transparent;
                border: none;

                &:hover {
                    color: #666;
                }
            }
        }

        .ant-btn-primary {
            -webkit-order: 1;
            order: 1;
            font-weight: 600;
            min-width: 100px;
            margin-left: 0;
        }
    }
}

.image-viewer-modal {
    width: fit-content !important;
    max-width: 50%;
    min-width: 280px;
    $close-size: 40px;

    .ant-modal-content {
        @include border-radius(0);
    }

    .ant-modal-body {
        padding: 5px;
    }

    .ant-modal-close {
        right: -$close-size;
        width: $close-size;
        height: $close-size;
        line-height: normal;

        .ant-modal-close-x {
            width: $close-size;
            height: $close-size;
            background-color: #fff;
            color: #999;
            line-height: $close-size;
            @include transition(all 0.2s linear);
        }

        &:hover .ant-modal-close-x {
            color: #666;
        }
    }

    .image-holder {
        min-height: 120px;
        text-align: center;
        position: relative;
        background-color: #fafafa;

        img {
            display: inline-block;
            min-width: 260px;
        }

        .image-loader {
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            margin-top: -30px;

            .loader .logo-block {
                margin-bottom: 0;
            }

            .loader .text-block {
                display: none;
            }

            img {
                min-width: unset;
            }
        }
    }

    .image-options {
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 30px 15px 15px;
        text-align: right;
        width: 100%;
        background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#a6000000', GradientType=0);

        .icon-block {
            font-size: 16px;
            color: #fff;
            width: 16px;
        }

        .loading-icon-block {
            .ant-spin-dot {
                width: auto;
                height: auto;
                color: #fff;
                font-size: 20px;
            }
        }
    }

    @include max-xs {
        .ant-modal-close {
            top: -$close-size;
            right: 0;
        }
    }
}