////////////////////////////////////////////////
/// Components: Calendar List View Widget ///
////////////////////////////////////////////////  
.calendar-list-view-widget{
    padding-bottom: 60px;
    .calendar-list-view-item{
        cursor: pointer;
    }
    .calendar-list-view-header{
        background-color: rgba($primary-color, 0.1);
        padding: 8px 20px;
        font-size: 13px;
        font-weight: 600;
        font-family: $title-font;
        color: $primary-color;
        .calendar-list-view-header-left{
            margin-bottom: 5px;
        }
    }
    .calendar-list-view-content{
        padding: 8px 20px;
        padding-right: 50px;
        font-size: 14px;
        font-weight: 600;
        font-family: $title-font;
        color: $title-font-color;
        .link-block{
            color: $title-font-color;
            &:hover{
                text-decoration: underline;
            }
        }
        .icon-block{
            margin-right: 5px;
            color: $primary-color;
        }
        .right-option-block{
            float: right;
            margin-right: -30px;
        }
    }
    @include min-sm{
        .calendar-list-view-header{ 
            @include clearfloat();
            .calendar-list-view-header-left{
                margin-bottom: 0;
                float: left;
                width: 50%;
            }
            .calendar-list-view-header-right{ 
                float: right;
                text-align: right;
                width: 50%;
            }
        }
    }
}