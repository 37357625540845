//////////////////////////
/// Footer ///
//////////////////////////

.common-bottom-footer {
    text-align: center;
    font-size: 13px;
    color: $sub-font-color;
    padding: 15px 5px;
    font-weight: normal;

    .copy-right-text,
    .seperator,
    .copy-right-link {
        display: inline-block;
        vertical-align: middle;
        margin: 0 2px;
    }

    .copy-right-link {
        color: $sub-font-color;

        &:hover {
            text-decoration: underline;
        }
    }

    @include min-sm {
        font-size: 14px;
    }
}

.landing-main-wrapper {
    .landing-footer-section {
        padding: 30px 0;
        color: #414140;
        font-size: 15px;

        .copy-right-text,
        .seperator,
        .copy-right-link {
            color: #414140;
            margin: 0 5px;
        }
    }
}