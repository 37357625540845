///////////////////////////////
/// Component: Collapse ///
///////////////////////////////

.c-collapse {
    $collapse-spacing: 45px;

    .ant-collapse-item {
        border-bottom: none;

        .ant-collapse-header {
            padding-left: $collapse-spacing;
            color: $title-font-color;

            .ant-collapse-arrow {
                display: none;
            }
        }
    }

    .ant-collapse-header {
        &::before {
            content: "\e918";
            @include iconFont();
            position: absolute;
            left: 0;
            top: 10px;
            width: 30px;
            height: 30px;
            text-align: center;
            background-color: #eee;
            color: $sub-font-color;
            font-size: 12px;
            line-height: 30px;
            @include border-radius(3px);
        }

        .collapse-header {
            .search-icon-type {
                margin-right: 8px;
                display: inline-block;
                font-size: 16px;
                vertical-align: middle;
                color: $sub-font-color;

                &.icon-size-lg {
                    font-size: 18px;
                }

                &.icon-size-xl {
                    font-size: 22px;
                }
            }

            .title-text {
                font-size: 14px;
                margin-bottom: 0;
                font-family: $title-font;
                font-weight: bold;
                display: inline-block;
                vertical-align: middle;

                @include min-sm {
                    font-size: 15px;
                }
            }

            .title-count {
                display: inline-block;
                vertical-align: middle;
                width: 24px;
                height: 24px;
                min-width: 24px;
                @include border-radius(50%);
                line-height: 24px;
                background-color: #eee;
                font-size: 12px;
                font-weight: 600;
                text-align: center;
                margin-left: 5px;
            }
        }
    }

    .ant-collapse-item-active {
        .ant-collapse-header {
            &::before {
                content: "\e933";
            }
        }
    }

    .ant-collapse-content-box {
        padding-left: $collapse-spacing;
    }

    @include max-xs {
        $collapse-spacing: 0;

        .ant-collapse-content-box {
            padding-left: $collapse-spacing;
        }
    }
}