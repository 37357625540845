//////////////////////////////////
/// PAGE: EVENT DETAILS PAGE ///
////////////////////////////////// 

.event-details-widget {
    font-size: 0;

    .event-details-header {
        margin-bottom: 10px;
        font-size: 14px;

        .title-block {
            font-size: 18px;
            margin-bottom: 0;
            font-weight: 600;
        }

        .date-block {
            font-size: 14px;
            color: $sub-font-color;
        }
    }

    .left-event-content {
        .event-details-media {
            width: 100%;
            margin-bottom: 0;
            height: 130px;
            max-width: 200px;
            border: solid 4px white;
            @include box-shadow(0 2px 4px rgba(black, .27));
            @include border-radius(0);
            line-height: 66px;
            font-size: 30px;
            background-color: #e9e2e2;

            img {
                object-fit: contain;
            }
        }
        .event-attachment{
            margin-top: 20px;
        }


        .no-image {
            max-width: 100px;
            height: 100px;

            img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                display: inline-block;
                max-width: 30px;
            }
        }

        .content-text {
            font-size: 14px;
            line-height: 1.5;

            p {
                font-size: 14px;
                padding: 0;
            }
        }
    }

    .event-details-info {
        margin-bottom: 20px;
        position: relative;
        border-bottom: 1px solid #d4d4d4;


        .info-item {
            border-bottom: 1px solid #d4d4d4;
            margin-bottom: 10px;
            padding-bottom: 5px;

            .info-item-cover {
                position: relative;
                padding-left: 25px;
                margin-bottom: 5px;
            }

            .icon-block {
                position: absolute;
                left: 0;
                top: 1px;
                color: $primary-color;
                font-size: 14px;

                &.icon-size-md {
                    font-size: 16px;
                }
            }

            .text-block {
                font-size: 14px;
                font-family: $semibold-font;
                color: $title-font-color;
                font-weight: 500;
            }
        }
    }

    .who-attend-event {
        .label-block {
            font-size: 14px;
            color: $sub-font-color;
            margin-bottom: 5px;
            display: block;
        }

        .member-pic {
            width: 24px;
            height: 24px;
            line-height: 24px;
            font-size: 14px;
            margin-right: 10px;
            margin-bottom: 10px;
        }
    }

    @include min-sm {
        .left-event-content {
            font-size: 14px;
            width: 50%;
            display: inline-block;
            vertical-align: top;
            padding-left: 30px;

            // border-right: 1px solid #d4d4d4;
            .content-text {
                font-size: 16px;
                line-height: 1.5;

                p {
                    font-size: 16px;
                }
            }
        }

        .event-details-info {
            font-size: 14px;
            margin-left: -1px;
            display: inline-block;
            vertical-align: top;
            width: 50%;
            padding-bottom: 0;
            margin-bottom: 0;
            position: relative;
            border-bottom: none;
            padding-right: 30px;
            border-right: 1px solid #d4d4d4;
        }
    }
}

.create-event-widget {

    @include max-xs {
        .upload-wrap {
            margin-bottom: 20px;
        }
    }
}

.event-form-block {
    .date-block {
        flex: 1;
    }

    .wrap-date-time {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
    }

    .at-seperate {
        padding: 0 10px;
        font-size: 14px;
        font-weight: 500;
        color: $text-color;
        padding-bottom: 20px;
    }

    .time-block {
        width: 120px;
    }

    .timekeeper-pops {
        width: 260px;
        left: auto;
        right: 0;
    }

    .has-timer-convention {
        .ant-input-group .ant-input {
            min-width: 60px;
        }
    }
    .event-invited-members{
        margin-top: 5px;
        cursor: pointer;
        color: $primary-color;
        @include transition(all 0.2s linear);
        &:hover{
            text-decoration: underline;
            @include opacity(0.8);
        }
    }
    .attachment-block {
        margin-top: 10px;
    }

    .attachement-file-name {
        font-size: 12px;
        color: $sub-font-color;
        padding: 7px 0; 
        line-height: normal;
        word-break: break-all;

        .remove-item-btn {
            padding: 0;
            width: 24px;
            height: 24px;
            font-size: 12px;
            margin-left: 10px;
            line-height: 24px;
        }

        .file-name {
            display: inline-block;
            position: relative;
            padding-right: 32px;

            .remove-item-btn {
                position: absolute;
                right: 0;
                top: -4px;
            }
        }
    }
}

.manage-invited-members{
    .ant-list-item{
        padding: 6px 0;
        .ant-list-item-meta-title{
            margin-top: 4px;
        }
        h3{
            font-size: 14px;
            color: $text-color;
        }
        .delete-member-btn{
            font-size: 16px;
            color: $text-color;
            font-weight: normal;
            &:hover{
                color: $primary-color;
            }
        }
    }
}

.c-fullcalendar--mobile {
    margin-top: 30px;

    .fc-listWeek-view .fc-list-heading+.fc-list-item .fc-widget-content,
    .fc-list-view .fc-list-heading+.fc-list-item .fc-widget-content {
        border-top: none;
        word-break: break-all;
    }

    .fc-toolbar.fc-header-toolbar {
        display: none;
    }

    @include min-sm {
        display: none;
    }
}