///////////////////////////////
/// Component: Pagination ///
///////////////////////////////
.c-pagination{
    .ant-pagination-item{
        height: 28px;
        min-width: 28px;
        line-height: 28px;
        font-family: $font-family-base;
        @include border-radius(28px);
        background-color: #fff;
        border: none;
        font-weight: 500;
        a{
            color: #3F4549;
            text-decoration: none;
        }
        &:hover,
        &.ant-pagination-item-active{
            color: #fff;
            @include box-shadow(0 3px 7px rgba($primary-color, .49));
            background-color: $primary-color;
            a{
                color: #fff;
            }
        }
    }
    .ant-pagination-jump-prev,
    .ant-pagination-jump-next{
        .ant-pagination-item-link-icon{
            font-size: 16px !important;
            color: #3F4549;
        }
        .ant-pagination-item-ellipsis{
            font-size: 20px;
        }
    }
    .ant-pagination-prev,
    .ant-pagination-next{
        width: 28px;
        height: 28px;
        min-width: 28px;
        line-height: 28px;
        a,
        .pagination-arrow{
            font-family: $font-family-base;
            @include border-radius(50%);
            background-color: #E9EAEB;
            border: none;
            font-weight: 500;
            display: block;
            width: 28px;
            height: 28px;
            min-width: 28px;
            line-height: 28px;
            font-size: 10px;
            text-decoration: none;
            &:hover{
                color: #fff;
                @include box-shadow(0 3px 7px rgba($primary-color, .49));
                background-color: $primary-color;
            }
        }
        &.ant-pagination-disabled a,
        &.ant-pagination-disabled .pagination-arrow{
            background-color: #E9EAEB;
            @include box-shadow(none);
            color: inherit;
        }
        &:not(.ant-pagination-disabled) a,
        &:not(.ant-pagination-disabled) .pagination-arrow{
            color: #fff;
            @include box-shadow(0 3px 7px rgba($primary-color, .49));
            background-color: $primary-color;
        }
    }
    @include max-xs{
        .ant-pagination-item,
        .ant-pagination-jump-prev,
        .ant-pagination-jump-next{
            display: none;
        }
        .ant-pagination-item-active{
            display: inline-block;
        }
    }
}