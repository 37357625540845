////////////////////////////////////////////////
/// Components: Sidebar widget ///
////////////////////////////////////////////////

.sidebar-menu-widget {
    margin-bottom: 10px;

    li+li {
        border-top: 1px solid #dddddd;
    }

    .sidebar-menu-item {
        position: relative;
        padding: 10px 40px 10px 55px;
        background-color: #fff;
        display: block;
        font-size: 14px;
        font-weight: 500;
        font-family: $semibold-font;
        color: #666666;

        &:hover {
            background-color: #f5f5f5;
            text-decoration: none;
        }

        .prefix-icon-block {
            position: absolute;
            left: 25px;
            top: 50%;
            font-size: 18px;
            margin-top: -9px;
            color: $sub-font-color;
        }

        .affix-icon-block {
            position: absolute;
            right: 20px;
            top: 50%;
            font-size: 12px;
            margin-top: -6px;
            color: $sub-font-color;
        }
    }
}

.main-footer {
    padding-top: 10px;

    a.footer-item {
        &:hover {
            color: #666;
            text-decoration: underline;
        }
    }

    .footer-item {
        font-size: 14px;
        color: #757b89;
        margin-right: 5px;
        position: relative;
        display: inline-block;
        padding-right: 10px;

        &::after {
            content: '';
            position: absolute;
            right: 0;
            top: 50%;
            background-color: #cecdcd;
            height: 12px;
            width: 1px;
            margin-top: -6px;
        }

        &:last-child {
            &::after {
                display: none;
            }
        }

    }
}


.create-new-task-widget {
    background-color: #fff;
    padding: 25px;
    text-align: center;
    @include box-shadow(0 0 3px rgba(black, .03));

    .para-text {
        font-size: 14px;
        margin-bottom: 12px;
    }

    .widget-btn {
        font-size: 11px;
        height: 46px;
        display: block;
        width: 100%;
        font-weight: 700;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: normal;
        text-align: left;

        .icon-block {
            display: inline-block;
            vertical-align: middle;
            font-size: 22px;
            margin-right: 10px;

            &.icon-size-lg {
                font-size: 26px;
            }

            &.icon-size-xl {
                font-size: 30px;
            }
        }

        .text-block {
            display: inline-block;
            line-height: 1;
            vertical-align: middle;
        }
    }

    @include min-sm {
        .para-text {
            font-size: 16px;
        }
    }

    @media (min-width: 1366px) {
        .widget-btn {
            font-size: 14px;
        }
    }
}