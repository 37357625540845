/////////////////////////////////////////
/// PAGE: UNAVAILABILITY LISTING PAGE ///
/////////////////////////////////////////

.unavailability-listing-widget {
    .unavailability-listing-item {
        padding: 10px 20px;
        position: relative;
        border-bottom: 1px solid #ddd;

        .list-item-media {
            width: 104px;
            float: left;
            height: 74px;
            border: solid 4px white;
            @include box-shadow(0 2px 4px rgba(black, .27));
            @include border-radius(0);
            line-height: 66px;
            font-size: 30px;
            background-color: #e9e2e2;

            img {
                object-fit: cover;
            }
        }

        .no-image {
            img {
                object-fit: initial;
                width: 32px;
                height: auto;
                display: inline-block;
            }
        }

        .right-content {
            position: relative;
            padding-right: 60px;
            margin-left: 130px;
            min-height: 75px;
            padding-top: 10px;
        }

        .right-option-block {
            position: absolute;
            right: 15px;
            top: 10px;
            z-index: 2;

            .more-option-btn {
                color: $sub-font-color;
                font-size: 16px;
            }
        }

        .reason-label {
            color: #838995;
            font-size: 12px;
            margin-bottom: 5px;
        }

        .content-block {
            padding-right: 15px;
            font-size: 14px;
            color: $title-font-color;
            font-weight: 500;

            p {
                font-size: 14px;
                color: $title-font-color;
            }
        }
    }

    .unavailability-listing-footer {
        margin-top: 5px;

        .footer-item {
            display: inline-block;
            vertical-align: middle;
            margin-right: 15px;

            .icon-block {
                font-size: 16px;
                color: $sub-font-color;
                margin-right: 8px;
                display: inline-block;
                vertical-align: middle;
            }

            .text-block {
                font-size: 12px;
                font-weight: 500;
                color: #666666;
                display: inline-block;
                vertical-align: middle;
            }
        }

        .upcoming-event{
            background-color: rgba($primary-color, .2);
            border: solid 1px $primary-color;
            color:  $primary-color;
            font-size: 12px;
            font-weight: 500;
            padding: 1px 10px;
            @include border-radius(20px);
        }

        .members-list {
            margin-top: 10px;

            .label-text {
                font-size: 12px;
                font-weight: 500;
                color: $sub-font-color;
                margin-right: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .member-pic {
                width: 22px;
                height: 22px;
                font-size: 14px;
                margin-left: 5px;
                line-height: 22px;
            }
        }
    }

    @include min-md {

        .unavailability-listing-footer {
            .footer-item {
                margin-right: 30px;
            }

            .members-list {
                float: right;
                margin-top: 0;
            }
        }
    }
}