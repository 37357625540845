//////////////////////////
/// Component: Table// ///
//////////////////////////
.simple-table {
    .table {
        width: 100%;
        border: none;
        table-layout: initial;
    }

    tr {

        th,
        td {
            font-size: 14px;
            color: #838995;
            padding: 15px 20px;
            background-color: #fff;
            border-bottom: 1px solid #dddddd;
        }

        .text-right {
            text-align: right;
        }

        .text-center {
            text-align: center;
        }
    }

    &.bordered-table {

        th,
        td {
            border: 1px solid #dddddd;
        }
    }

    .table-footer {
        padding: 25px 20px;
        background-color: #fff;

        &.clear-padding {
            margin-left: 0;
            margin-right: 0;
            padding-left: 0;
            padding-right: 0;
        }

        .submit-btn {
            min-width: 100px;
            height: 32px;
            font-size: 14px;
            font-weight: 600;
            margin-right: 20px;
        }

        .cancel-btn {
            font-size: 14px;
            color: $sub-font-color;
            font-weight: 700;
            height: 32px;

            &:hover {
                color: #666;
            }
        }
    }
}

.c-table {

    &.c-table-th-uppercase{
        .ant-table-thead th{
            text-transform: uppercase;
        }
    }
    &.row-clickable{
        td{
            cursor: pointer;
        }
    }
    .ant-table-thead {
        >tr>th {
            padding: 12px 20px;
            font-size: 12px;
            font-weight: bold;
            font-family: $title-font;
            color: $sub-font-color;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
        }
    }

    .ant-table-tbody {
        >tr>td {
            padding: 10px 20px;
            font-size: 14px;
            font-weight: 500;
            color: #666666;
            background-color: #fff;
            @include transition(all 0.2s linear);
            border-bottom: 1px solid #ddd;
        }

        >tr:hover td {
            background-color: rgba($primary-color, .05);
            border-bottom-color: rgba($primary-color, .05);
        }

        >tr:last-child td {
            border-bottom: none;
        }

        .item-link {
            color: #666666;
        }

        >tr.ant-table-row-selected {
            td {
                background-color: #fafafa;
                color: #444444;
                border-bottom-color: #ddd;
            }

            .item-link {
                color: #444444;
            }
        }

        >tr:hover {
            .item-link {
                color: $primary-color;
                text-decoration: underline;
            }
        }
    }

    .table-action-btns {
        .action-btn {
            margin-right: 20px;
            font-size: 18px;
            color: #666666;
            @include opacity(0.6);

            &.icon-pencil {
                font-size: 16px;
            }

            &:hover {
                color: #666666;
                @include opacity(1);
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}

.c-table-block-max-xs {
    @include max-xs {
        table {
            display: block;
        }

        .ant-table-thead {
            display: none;
        }

        div.ant-table-body {
            padding-bottom: 15px;
        }

        .ant-table-tbody {
            display: block;

            .ant-table-row+.ant-table-row {
                td:first-child {
                    border-top: 1px solid #ddd;
                }
            }

            >tr {
                display: block;

                td:first-child {
                    padding-top: 15px;
                }

                td:last-child {
                    padding-bottom: 15px;
                }
            }

            >tr>td {
                display: block;
                line-height: normal;
                padding: 5px 20px;
                border-bottom: none;
            }
        }
    }
}

.responsive-table {
    overflow-x: auto;

    &.responsive-table-max-sm {

        .ant-table,
        .simple-table {
            min-width: 700px;
            width: 100%;
        }
    }

    &.responsive-table-max-md {

        .ant-table,
        .simple-table {
            min-width: 900px;
            width: 100%;
        }
    }
}

.table-footer {
    background-color: #fff;
    padding: 20px 20px 50px;

    .c-pagination {
        display: inline-block;
        width: 100%;
        text-align: center;
        margin-top: 10px;
    }

    .table-footer-actions {
        text-align: center;

        .table-footer-btn {
            display: inline-block;
            height: 32px;
            line-height: normal;
            padding: 0 16px 5px;

            .icon-block {
                font-size: 14px;
                display: inline-block;
                margin-right: 5px;
                vertical-align: middle;
            }

            .text-block {
                display: inline-block;
                vertical-align: middle;
                font-size: 12px;
                font-weight: 600;
                padding-top: 3px;
                font-family: $title-font;
            }

            // &:not(:hover){
            //     border-color: $sub-font-color;
            //     color: $sub-font-color; 
            // }  
        }
    }

    @include min-sm {
        .c-pagination {
            width: auto;
            float: right;
            margin-top: 0;
        }

        .table-footer-actions {
            display: inline-block;
        }
    }
}