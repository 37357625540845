/////////////////////////////////////
/// Components: Infinite Scroll ///
/////////////////////////////////////
.has-infinite-scroll{ 
    .listing-loading{
        padding: 30px 20px;
    }
    .loading-completed,
    .no-loading-items{
        padding: 15px 20px;
        text-align: center;
        font-size: 13px;
        font-weight: 700;
        color: $sub-font-color;
    }
}