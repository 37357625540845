/////////////////////////////////////////
/// Components: Circle Members Widget ///
/////////////////////////////////////////

.circle-members-widget{
    padding: 20px 20px 10px;
    $circle-size: 30px;
    .circle-list-item{
        @include box-shadow(0 0 0 2px #fff);
        width: $circle-size;
        height: $circle-size;
        line-height: $circle-size;
        display: inline-block;
        vertical-align: top;
        margin-right: 12px;
        font-size: 18px;
        margin-bottom: 10px;
        &.active{
            @include box-shadow(0 0 0 2px $primary-color);
        }
        &.has-border{
            cursor: pointer;
            &:hover{
                @include box-shadow(0 0 0 2px $primary-color);
            }
        }
    }
    .circle-list-item-count{
        border: solid 1px $sub-font-color;
        width: $circle-size;
        @include border-radius(50%);
        text-align: center;
        line-height: ($circle-size - 2);
        font-size: 14px;
        font-weight: 700;
        color: $sub-font-color;
        height: $circle-size;
        display: inline-block;
        vertical-align: top;
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm { 
        
        $circle-size: 46px;
        .circle-list-item{
            width: $circle-size;
            height: $circle-size;
            line-height: $circle-size;
        }
        .circle-list-item-count{
            width: $circle-size;
            line-height: ($circle-size - 2);
            height: $circle-size;
        }
    }
}