////////////////////////////
/// Components: Loader ///
///////////////////////////
.loader{
    text-align: center;
    .logo-block{
        font-size: 26px;
        color: $primary-color;
        text-align: center;
        display: inline-block;
        margin-bottom: 5px;
        @include opacity(0.7);
        img{
            $spin-speed: 1500ms;
            display: inline-block;
            -webkit-animation-name: spin;
            -webkit-animation-duration: $spin-speed;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;

            -moz-animation-name: spin;
            -moz-animation-duration: $spin-speed;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;

            -ms-animation-name: spin;
            -ms-animation-duration: $spin-speed;
            -ms-animation-iteration-count: infinite;
            -ms-animation-timing-function: linear;

            animation-name: spin;
            animation-duration: $spin-speed;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
    .text-block{
        display: block;
        font-size: 13px;
        @include opacity(0.7);
        font-weight: 500;
        font-family: $semibold-font;
    }
}
.loader-has-padding{
    padding: 40px 20px;
}
.page-loader{
    position: absolute;
    left: 0;
    top: 50%;
    text-align: center;
    width: 100%;
    .loader{
        display: inline-block;
    }
}
.notification-loader{
    .logo-block{ 
        text-align: left;
        width: auto;
        img{
            max-width: 20px;
        }
    }
}
 
//keyframes
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(360deg); }
}
@keyframes spin {
    from {transform:rotate(0deg);}
    to {transform:rotate(360deg);}
}