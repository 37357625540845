////////////////////////////////////// 
/// PAGE: LANDING PAGE ///
////////////////////////////////// /////

.landing-main-wrapper {
    margin-top: -15px;

    .landing-banner-section {
        min-height: calc(100vh - 60px);
        max-height: calc(100vh - 60px);

        .media-block {

            source,
            img {
                width: 100%;
                max-width: 100%;
                height: calc(100vh - 50px);
                object-fit: cover;
            }
        }

        .banner-wrapper {
            width: 100%;
            position: relative;
        }

        .banner-inner-wrap {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            z-index: 1;
        }

        .content-block {
            text-align: center;

            .title-block {
                font-size: 30px;
                color: #fff;
                font-weight: 500;
                line-height: 1.2;
                margin-bottom: 15px;
                text-shadow: 0 0 5px rgba(black, .2);
                font-family: $title-font;
            }

            .para-block {
                font-size: 16px;
                color: #fff;
                font-family: $paragraph;
                text-shadow: 0 0 5px rgba(black, .2);
            }

            .btn-block {
                font-size: 16px;
                margin-top: 20px;
                height: 50px;
                padding: 0 20px;
                margin-bottom: 30px;
                background: $primary-color-dark;
                font-weight: 500;
                border-radius: 4px;
            }

            .download-btn-block {
                display: block;
            }

            .download-btn {
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 10px;

                &:last-child {
                    margin-right: 0;
                }

                img {
                    max-height: 50px;
                    max-width: 100%;
                }
            }
        }
    }

    .landing-navigation-section {
        padding: 15px 0;
        background: #414140;

        ul {
            background: #414140;
            border: none;

            li {
                height: 50px;
                margin-bottom: 0;
                border-bottom: 1px solid #313131;

                &:hover .ant-anchor-link-title {
                    color: $link-color;
                }

                &:last-child {
                    border-bottom: none;
                }
            }
        }

        .ant-anchor-wrapper {
            background: transparent;
        }

        .ant-anchor-link-title {
            color: #fff;
            font-weight: 500;
            text-decoration: none;
        }

        .ant-anchor-ink:before {
            width: 0;
        }

        .nav-link {
            padding: 0;
            font-size: 15px;
            line-height: 40px;
            text-align: center;
            font-family: $title-font;

            &:hover {
                color: $link-color;
            }
        }
    }

    .landing-about-section {
        background: #fff;

        .section-inner-wrap {
            margin-bottom: 40px;

            picture {
                width: 100%;
                height: 100%;

                source,
                img {
                    object-fit: cover;
                }
            }
        }

        .content-block {
            padding: 40px 20px;
            background: $primary-color-dark;

            .paragraph {
                color: #fff;
                font-size: 15px;
                font-family: $paragraph;
            }

            .title-block {
                color: #fff;
                font-size: 30px;
                font-family: $paragraph;
            }
        }
    }

    .top-button-block {
        display: block;
        background: transparent;
        text-align: center;

        .top-btn {
            padding: 0;

            a {
                color: #414140;
                font-size: 18px;
                padding-bottom: 50px;

                &:hover {
                    color: rgba(#414140, 0.8);
                    text-decoration: none;
                }
            }
        }

        .ant-anchor-ink::before {
            background: transparent;
            width: 0;
        }
    }

    .landing-at-glance-section {
        background: #f2f3f3;

        .glance-item {
            padding: 20px 10px;
            text-align: center;
        }

        .title-block {
            color: #5f5f5f;
            font-size: 22px;
            font-family: $title-font;
        }

        .paragraph {
            color: #5f5f5f;
            font-size: 16px;
            font-family: $paragraph;
        }

        .icon-block {
            width: 100px;
            height: 100px;
            line-height: 100px;
            color: #fff;
            display: block;
            text-align: center;
            font-size: 50px;
            margin: auto;
            margin-bottom: 20px;
            @include border-radius(50%);
            background: $primary-color-dark;
        }

    }

    .landing-carers-say-section {
        background: #414140;

        .section-title {
            color: #ffffff;
        }

        .media-block {
            width: 180px;
            height: 180px;
            margin: auto;
            border-radius: 50%;

            .image {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .content-block {
            padding: 30px 0;
            text-align: center;
            border-bottom: 1px solid rgba($border-color, 0.5);

            .quotes-icon {
                font-size: 24px;
                color: $primary-color-dark;
            }

            .paragraph {
                color: #fff;
                display: block;
                padding: 15px 0;
            }
        }

        .top-button-block {
            .top-btn a {
                color: #ffffff;
            }
        }
    }

    .sub-header-block {
        margin: 45px 0;

        .sub-title {
            font-size: 26px;
            margin-bottom: 30px;
            color: #5f5f5f;
        }

        .top-separator {
            width: 80%;
            max-width: 400px;
            margin: auto;
            display: block;
            height: 2px;
            margin-bottom: 30px;
            background: #fff;
        }

        .bottom-separator {
            width: 140px;
            margin: auto;
            display: block;
            height: 2px;
            background: $primary-color-dark;
        }
    }

    .landing-professionals-section {
        text-align: center;

        .paragraph {
            color: #5f5f5f;
            margin-bottom: 20px;
            font-family: $paragraph;

            br {
                display: none;
            }
        }

        .carers-list-wrapper {
            display: block;
            margin-bottom: 80px;

            .carers-list-item {
                max-width: 400px;
                margin: auto;
                margin-bottom: 30px;
                overflow: hidden;
                @include border-radius(10px);
                box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
                background-color: #414140;
            }

            .media-block {
                overflow: hidden;
                @include border-radius(10px);

                .image {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            .content-block {
                padding: 20px;

                .item-title {
                    display: block;
                    color: #fff;
                    font-size: 20px;
                    margin-bottom: 20px;
                    font-family: $title-font;
                }

                .paragraph {
                    color: #fff;
                    font-size: 14px;
                    font-family: $paragraph;
                }
            }
        }

        .blocks-wrapper {
            .content-block {
                text-align: center;
                border-bottom: 1px solid #ffffff;
            }

            .paragraph {
                color: #5f5f5f;
                font-size: 15px;
                padding: 15px 0;
                font-family: $paragraph;
            }

            .media-block {
                text-align: center;
                margin-top: 20px;
                height: 160px;

                img {
                    max-width: 200px;
                    display: inline-block;
                    position: relative;
                    top: 50%;
                    -webkit-transform: translateY(-50%);
                    -ms-transform: translateY(-50%);
                    transform: translateY(-50%);
                }
            }

            .quotes-icon {
                font-size: 24px;
                color: #b2b1b0;
                margin: 0 10px;
                display: inline-block;
                position: relative;
            }

            .icon-sqaure-quote-left {
                bottom: 4px;
            }

            .icon-sqaure-quote-right {
                top: 6px;
            }

            .left-block {
                margin-bottom: 45px;
            }
        }
    }

    .landing-faq-section {
        background: #ffffff;

        .section-title {
            color: $primary-color-dark;
        }

        .collapse-wrapper {
            display: block;
            margin-bottom: 50px;

            .ant-collapse {
                background: #ffffff;
                border: none;
            }

            .ant-collapse-item {
                border: none;
                background: #f2f3f3;
                margin-bottom: 20px;
                @include border-radius(10px);
            }

            .ant-collapse-header {
                padding: 15px 40px 15px 15px;
                @include transition(all 0.2s ease-in-out);

                &:after {
                    position: absolute;
                    content: "\e954";
                    right: 20px;
                    color: #5f5f5f;
                    font-family: $icon-font;
                    @include transition(all 0.2s ease-in-out);
                }

                .anticon {
                    display: none;
                }
            }

            .ant-collapse-content {
                color: #5f5f5f;
                border: solid 1px #f2f3f3;
                @include border-radius(0 0 10px 10px);
                @include transition(all 0.2s ease-in-out);

                ul {
                    margin-top: 10px;
                    padding-left: 30px;

                    li {
                        list-style: disc;
                    }
                }
            }

            .ant-collapse-item-active {
                .ant-collapse-header:after {
                    -ms-transform: rotate(-90deg);
                    -webkit-transform: rotate(-90deg);
                    transform: rotate(-90deg);
                }
            }
        }

        .btn-block {
            display: block;
            text-align: center;
            margin-bottom: 45px;
        }

        .filled-btn {
            height: 44px;
            display: inline-block;
            line-height: 42px;
            text-decoration: none;
            color: #fff;
            background: $primary-color-dark;
            padding-left: 45px;
            padding-right: 45px;
            font-weight: 500;
            @include border-radius(32px);

            &:hover {
                color: #fff;
                background-color: #f02e31;
                border-color: #f02e31;
                box-shadow: 0 3px 7px rgba(226, 6, 18, 0.49);
            }
        }
    }

    .landing-brought-section {
        background: #f2f3f3;
        text-align: center;
        padding: 30px 0;

        .paragraph {
            font-size: 16px;
            margin-bottom: 30px;
            color: #414140;
            font-family: $paragraph;
        }

        .media-block {
            display: block;
            max-width: 250px;
            margin: auto;
        }
    }

    .landing-download-section {
        background: #414140;
        text-align: center;
        padding: 30px 0;

        .paragraph {
            font-size: 16px;
            color: #ffffff;
            margin-bottom: 20px;
            font-family: $paragraph;
        }

        .media-block {
            .download-btn {
                display: inline-block;
                margin-right: 10px;
                max-width: 130px;
                vertical-align: middle;
            }

            .android-btn {
                margin-right: 0;
            }
        }
    }




    //Media query
    @include min-xs-medium {
        .landing-about-section {
            .section-inner-wrap {
                margin-bottom: 50px;
                flex-direction: row-reverse;
            }

            .content-block {
                display: flex;
                align-items: center;

                br {
                    margin-bottom: 10px;
                }
            }
        }
    }

    @include min-sm {
        .landing-banner-section {
            position: relative;
            min-height: auto;
            max-height: 500px;

            &::before {
                position: absolute;
                content: '';
                width: 70%;
                top: 0;
                left: 0;
                bottom: 0;

                background: rgba(0, 0, 0, 1);
                background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                background: -webkit-gradient(left top, right top, color-stop(0%, rgba(0, 0, 0, 1)), color-stop(100%, rgba(0, 0, 0, 0)));
                background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                background: -o-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                background: -ms-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
                filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#000000', endColorstr='#000000', GradientType=1);
            }

            .media-block {

                source,
                img {
                    min-height: 100%;
                    max-height: 500px;
                }
            }

            .content-block {
                text-align: left;

                .title-block {
                    font-size: 36px;
                }

                .para-block {
                    font-size: 16px;
                }

                .btn-block {
                    margin-bottom: 40px;
                    padding: 0 30px;
                }

                .download-btn {
                    margin-right: 20px;
                    margin-bottom: 0;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }

        .landing-navigation-section {
            ul {
                display: flex;

                li {
                    border-bottom: none;
                    height: 40px;
                    margin-bottom: 0;
                    display: inline-block;
                    flex-grow: 1;

                    &:first-child .nav-link {
                        text-align: left;
                    }

                    &:last-child .nav-link {
                        text-align: right;
                    }
                }
            }

            .nav-link {
                font-size: 14px;
            }
        }

        .landing-about-section {
            .content-block {
                .paragraph {
                    font-size: 18px;
                }
            }
        }

        .landing-carers-say-section {
            .content-block {
                border-bottom: none;

                .paragraph {
                    min-height: 258px;
                    padding-left: 15px;
                    padding-right: 30px;
                    display: flex;
                    align-items: center;
                    border-right: 1px solid rgba($border-color, 0.5);
                }

                .border-rignt-none {
                    border-right: none;
                }
            }
        }

        .landing-professionals-section {
            .paragraph {
                br {
                    display: block;
                }
            }

            .carers-list-wrapper {
                .carers-list-item {
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                }

                .media-block {
                    width: 300px;
                    min-width: 300px;
                    margin-bottom: 0;
                    @include border-radius(10px 0 0 10px);

                    .image {
                        min-height: 280px;
                    }
                }

                .content-block {
                    text-align: left;
                }
            }

            .blocks-wrapper {
                .content-block {
                    border-bottom: none;
                    border-right: 1px solid #ffffff;
                }

                .paragraph {
                    font-size: 16px;
                }


                .border-rignt-none {
                    border-right: none;
                }

                .left-block {
                    padding-right: 0 !important;
                    margin-bottom: 0;

                    .content-block {
                        padding-right: 40px;
                        padding-left: 40px;
                    }
                }

                .right-block {
                    .content-block {
                        padding-left: 25px;
                        padding-right: 25px;
                    }
                }
            }
        }

        .landing-faq-section {
            .collapse-wrapper {
                .ant-collapse-item {
                    border: none;
                    background: #f2f3f3;
                    margin-bottom: 20px;
                    @include border-radius(10px);
                }

                .ant-collapse-header {
                    font-size: 16px;
                }
            }
        }

        .landing-brought-section {
            padding: 45px 0;

            .paragraph {
                font-size: 18px;
                margin-bottom: 40px;
            }
        }

        .landing-download-section {
            padding: 45px 0;

            .paragraph {
                font-size: 18px;
                margin-bottom: 30px;
            }

            .media-block {
                .download-btn {
                    margin-right: 30px;
                    max-width: auto;
                }

                .android-btn {
                    margin-right: 0;
                }
            }
        }
    }

    @include min-md {
        .landing-banner-section {
            max-height: 690px;

            .media-block {

                source,
                img {
                    max-height: 690px;
                }
            }

            .content-block {

                .title-block {
                    font-size: 40px;
                }

                .para-block {
                    font-size: 16px;
                }
            }
        }

        .landing-navigation-section {
            .nav-link {
                font-size: 18px;
            }
        }

        .landing-about-section {
            .content-block {
                padding: 100px;

                .paragraph {
                    font-size: 22px;
                }
            }
        }

        .landing-carers-say-section {
            .content-block {
                .paragraph {
                    min-height: 210px;
                }
            }
        }
    }

    @include min-lg {
        .landing-banner-section {
            .content-block {
                .title-block {
                    font-size: 50px;
                }

                .btn-block {
                    margin-bottom: 60px;
                }

                .download-btn {
                    margin-bottom: 0;
                }
            }
        }

        .landing-professionals-section {
            .carers-list-wrapper {
                .carers-list-item {
                    margin-bottom: 40px;
                }

                .media-block {
                    width: 350px;
                    min-width: 350px;

                    .image {
                        min-height: auto;
                    }
                }

                .content-block {
                    padding: 20px 40px;

                    .item-title {
                        font-size: 24px;
                    }

                    .paragraph {
                        font-size: 16px;
                    }
                }
            }

            .blocks-wrapper {
                .left-block {
                    .content-block {
                        padding-right: 60px;
                        padding-left: 60px;
                    }
                }

                .right-block {
                    .content-block {
                        padding-left: 45px;
                        padding-right: 45px;
                    }
                }
            }
        }

    }

    @include min-xlg {
        .landing-banner-section {
            .content-block {
                .title-block {
                    font-size: 58px;
                }

                .para-block {
                    font-size: 24px;
                }

                .btn-block {
                    padding: 0 40px;
                }
            }
        }

        .landing-navigation-section {
            .nav-link {
                font-size: 20px;
            }
        }

        .landing-at-glance-section {
            .title-block {
                font-size: 24px;
            }

            .paragraph {
                font-size: 18px;
            }
        }

        .landing-carers-say-section {
            .content-block {
                .paragraph {
                    font-size: 18px;
                    min-height: 225px;
                }
            }
        }

        .landing-professionals-section {
            .paragraph {
                font-size: 18px;
            }

            .carers-list-wrapper {
                .carers-list-item {
                    .content-block {
                        .item-title {
                            font-size: 26px;
                        }

                        .paragraph {
                            font-size: 18px;
                        }
                    }
                }
            }

            .blocks-wrapper {
                .paragraph {
                    font-size: 18px;
                }
            }
        }

        .sub-header-block {
            .sub-title {
                font-size: 28px;
            }
        }

        .landing-faq-section {
            .collapse-wrapper {
                .ant-collapse-header {
                    font-size: 18px;
                }

                .ant-collapse {
                    font-size: 17px;
                }
            }
        }



    }
}