//////////////////////////////////
/// PAGE: SETTINGS PAGES ///
//////////////////////////////////
.settings-widget-table-wrapper {
    padding: 20px;
    background-color: #fff;

    .label-has-tooltip {
        display: inline-block;

        .help-icon {
            display: inline-block;
            vertical-align: middle;
        }

        .help-icon {
            margin-left: 5px;
            font-size: 16px;
            color: #b3b3b3;
            @include transition(all 0.2s linear);

            &:hover {
                color: #999;
            }
        }
    }

    .simple-table {
        .table-title {
            font-size: 16px;
            font-family: $title-font;
            font-weight: 600;
            color: $title-font-color;
            vertical-align: middle;
        }

        .table-info-text {
            font-size: 14px;
            font-family: $title-font;
            font-weight: bold;
            color: $title-font-color;
            margin-right: 5px;
            display: inline-block;
            vertical-align: middle;
            text-align: left;

            .highlight {
                color: $primary-color;
                margin-right: 5px;
            }
        }

        td {
            font-weight: 500;
            color: #666666;
        }

        tr th,
        tr td {
            padding: 15px 0;
        }

        .table-th-icon {
            font-size: 22px;
            color: #b3b3b3;
        }

        .table-footer {
            padding: 25px 0 0;
        }
    }

    .checkbox-icon-group {
        width: 52px;
        padding: 4px 3px 1px;
        border: 1px solid #DFDADB;
        @include border-radius(3px);
        background-color: #F0F0F0;

        .table-th-icon,
        .c-checkbox {
            display: inline-block;
            vertical-align: middle;
        }

        .table-th-icon {
            font-size: 18px;
            margin-right: 5px;
        }
    }

    .checkbox-icon-group-xs {
        .table-th-icon {
            display: none;
        }

        @include max-xs {

            width: 52px;
            padding: 4px 3px 1px;
            border: 1px solid #eee;
            @include border-radius(3px);
            background-color: #fff;

            .table-th-icon,
            .c-checkbox {
                display: inline-block;
                vertical-align: middle;
            }

            .table-th-icon {
                font-size: 18px;
                margin-right: 5px;
            }
        }
    }
}


.members-settings-table-wrapper {
    padding-bottom: 60px;
    .settings-table-header{
        padding: 10px 20px;
        .title-block{
            font-size: 16px;
            margin-bottom: 0;
            font-weight: 600;
        }
    }
    .user-avatar-wrap {
        display: inline-block;
        vertical-align: middle;
        position: relative;
        margin-right: 14px;

        .owner-block {
            position: absolute;
            right: -12px;
            top: 8px;
            width: 20px;
            height: 20px;
            border: solid 3px white;
            @include border-radius(50%);
            background-color: #fff;
            background-color: $primary-color;
            font-size: 10px;
            text-align: center;
            color: #fff;
            line-height: 16px;
        }
    }

    .member-details-wrap {
        position: relative;
        padding-left: 40px;

        .user-avatar-wrap {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .user-avatar {
        display: table;
        vertical-align: middle;
        width: 26px;
        height: 26px;
        line-height: 26px;
        font-size: 16px;
        background-color: $primary-color;

        .ant-avatar-string {
            display: table-cell;
            vertical-align: middle;
        }
    }

    .username-block {
        font-weight: 600;
        display: block;
        padding-top: 4px;
        color: $title-font-color;
    }

    .members-settings-table {
        tr>th {
            padding: 12px 15px;
            font-size: 12px;
            font-weight: bold;
            font-family: $title-font;
            text-align: left;
            color: $sub-font-color;
            background-color: #fff;
            border-bottom: 1px solid #ddd;
            &:last-child{
                text-align: center;
            }
        }

        tr>td {
            padding: 15px 20px;
            font-size: 14px;
            font-weight: 600;
            color: $title-font-color;
            background-color: #fff;
            @include transition(all 0.2s linear);
            border-bottom: 1px solid #ddd;
           
        }
        .c-checkbox{
            margin-top: 2px; 
        } 
        .table-action-btns{
            text-align: center;
        }
        .th-text {
            display: inline-block;
            vertical-align: middle;

            span {
                display: block;
            }
        }

        .align-th-text {
            margin-left: 32px;
        }
        .ml-10{
            margin-left: 10px;
        }
        .th-icon-block {
            font-size: 18px;
            width: 24px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 8px;

            &.icon-admin {
                font-size: 24px;
            }
        }

        .link-item {
            color: $title-font-color;

            &:hover {
                color: $primary-color;
                text-decoration: underline;
            }
        }

        tr:hover {
            td {
                background-color: rgba($primary-color, .05);
                border-bottom-color: rgba($primary-color, .05);
            }

            .table-action-btns {
                .action-btn {
                    color: $primary-color;
                    @include opacity(0.8);

                    &:hover {
                        @include opacity(1);
                    }
                }
            }
        }

        .table-action-btns {
            .action-btn {
                margin-right: 15px;
                font-size: 20px;
                color: #666666;
                @include opacity(0.6);

                &.icon-pencil {
                    font-size: 16px;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }

    .question-icon-block {
        display: inline-block;
        margin-left: 10px;
    }
}

.circle-admin-info {
    .list-style {
        margin: 25px 0 0;
    }
}

.list-style {
    li {
        margin-bottom: 10px;
        color: $default-font-color;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.unorder-list {
    li {
        list-style: none;
        position: relative;
        padding-left: 25px;

        &::before {
            position: absolute;
            left: 0;
            top: 5px;
            content: '';
            width: 8px;
            height: 8px;
            @include border-radius(50%);
            background-color: rgba($default-font-color, .8);
        }
    }
}

.settings-widget-table-wrapper {
    .single-notification-table {
        @include max-xs {

            tr th,
            tr td {
                display: block;
            }

            tr {
                .table-select-title {
                    border-bottom: none;
                    text-align: left;
                    padding-bottom: 0;
                }

                .table-head-checkbox {
                    display: inline-block;
                    vertical-align: middle;
                    border-bottom: none;
                }
            }

            tbody tr {

                td,
                th {
                    border-top: 1px solid #dddddd;
                    border-bottom: none;
                }

                .table-item-text {
                    padding-bottom: 10px;
                }

                .table-item-empty {
                    display: none;
                }

                .table-item-checkbox {
                    display: inline-block;
                    vertical-align: middle;
                    border-top: none;
                    width: 75px;
                    padding-top: 0;
                    text-align: left;
                }
            }
        }
    }
}