// use: @include clearfloat();
@mixin clearfloat() {
    &::after {
        content: "";
        clear: both;
        display: table;
    }
}

//use: @include size(20px, 50px);
@mixin size($width, $height) {
    width: $width;
    height: $height;
}

//use: @include border-radius(5px);
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}

//use: @include equal-size(50px);
@mixin equal-size($equalSize) {
    width: $equalSize;
    height: $equalSize;
}

//use: @include opacity(50px);
@mixin opacity($opacity) {
    -webkit-opacity: $opacity;
    -moz-opacity: $opacity;
    opacity: $opacity;
}

//transition
//use: @include transition(all 0.2s ease-in-out);
@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    transition: $transition;
}

//box-shadow
//use: @include box-shadow(0 2px 5px black);
@mixin box-shadow($box-shadow) {
    -webkit-box-shadow: $box-shadow;
    -moz-box-shadow: $box-shadow;
    box-shadow: $box-shadow;
}

//box-shadow
//use: @include transform(translateY(-50%));
@mixin transform($transform) {
    -webkit-transform: $transform;
    -moz-transform: $transform;
    transform: $transform;
}

//Font icons
//use: @include iconFont();
@mixin iconFont() {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $icon-font !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

//placeholder color
//use: @include placeholder($color, $opacity);
@mixin placeholder($color, $opacity) {
    &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $color;
        opacity: $opacity;
    }

    &::-moz-placeholder {
        /* Firefox 19+ */
        color: $color;
        opacity: $opacity;
    }

    &:-ms-input-placeholder {
        /* IE 10+ */
        color: rgba($color, $opacity);
        /*color: $color;
        opacity: $opacity;*/
    }

    &::-ms-input-placeholder {
        /* Firefox 18- */
        color: $color;
        opacity: $opacity;
    }

    &:-moz-placeholder {
        /* Firefox 18- */
        color: $color;
        opacity: $opacity;
    }
}

//caret use: @include caret(left, 5px, red);
@mixin caret($point, $border-width, $color) {
    $opposite: opposite-position($point);
    border: $border-width solid transparent;
    border-#{$opposite}: $border-width solid $color;
    border-#{$point}: 0;
    height: 0;
    width: 0;
}

//no text select
//@include no-select();
@mixin no-select() {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Chrome/Safari/Opera */
    -khtml-user-select: none;
    /* Konqueror */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  not supported by any browser */
}

//truncate text
//@include ellipsis();
@mixin ellipsis() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

//visibility
//use: @include visibility(hidden|visible);
@mixin visibility($visibility) {
    @if $visibility=='hidden' {
        @include opacity(0);
        visibility: hidden;
        @include transition(all 0.2s ease-in-out);
    }

    @else {
        @include opacity(1);
        visibility: $visibility;
    }
}