////////////////////////////////////////
/// Component: Scroll top button// ///
////////////////////////////////////////

.scroll-top-button{
    width: 40px;
    height: 40px;
    background-color: #BFBEBE;
    @include border-radius(50%);
    text-align: center;
    line-height: 40px;
    display: block;
    color: #fff;
    font-size: 18px;
    @include transition(all 0.2s linear);
    &:hover{
        background-color: rgba($primary-color, .1);
        color: $primary-color;
    }
}