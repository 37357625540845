///////////////////////////////////////
/// Components: Filter By Category ///
///////////////////////////////////////

.filter-by-category{
    .filter-category-item{
        padding: 13px 20px;
        padding-right: 60px;
        position: relative;
        background-color: #fff; 
        border-bottom: 1px solid #dddddd;
        &:last-child{
            border-bottom: none;
        }
        .c-checkbox{
            position: relative;
            padding-left: 30px;
            .ant-checkbox{
                position: absolute;
                left: 0;
                top: 2px;
            }
            .ant-checkbox + span{
                padding: 0;
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: $title-font-color;
            }
        }
        .category-count{
            position: absolute;
            right: 20px;
            top: 10px;
            border: solid 1px $primary-color;
            padding: 3px 8px;
            font-size: 12px;
            font-weight: 600;
            @include border-radius(20px);
            color: $primary-color;
        }
    }
}