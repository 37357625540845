/////////////////////////////////////////
/// Components: CIRCLE PREVIEW MODAL ///
/////////////////////////////////////////

.circle-preview-modal {
    .circle-preview-side-block {
        padding: 30px;

        .profile-pic-wrapper {
            margin-bottom: 15px;
        }

        .circle-user-pic {
            width: 80px;
            height: 80px;
            display: inline-block;
            vertical-align: middle;
            line-height: 80px;
        }

        .edit-btn {
            height: 28px;
            font-size: 12px;
            font-weight: 600;
            margin-left: 15px;
            display: inline-block;
            vertical-align: middle;
        }

        .profile-details-block {
            padding-bottom: 30px;
            border-bottom: 1px solid #ddd;
        }

        .created-by {
            .create-label {
                font-weight: normal;
                font-size: 13px;
            }

            .created-user-name {
                text-decoration: none;
                font-size: 13px;
                font-weight: 600;
                color: $title-font-color;
            }

            a.created-user-name {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        .date-wrapper {
            margin-top: 5px;
            position: relative;
            padding-left: 25px;
            color: $sub-font-color;

            .icon-block {
                position: absolute;
                left: 0;
                top: 3px;
                font-size: 14px;
            }

            .text-block {
                font-size: 12px;
            }
        }
    }

    .circle-preview-side-menu {
        padding-bottom: 40px;

        @include max-xs {
            padding-bottom: 0;
        }

        .circle-preview-side-menu-item {
            padding: 10px 35px;
            padding-right: 50px;
            border-bottom: 1px solid #ddd;
            position: relative;

            .icon-block {
                position: absolute;
                left: 0;
                font-size: 20px;
                color: $sub-font-color;
                top: 9px;
            }

            .menu-label {
                display: block;
                font-size: 14px;
                font-weight: 500;
                color: $title-font-color;
            }

            .count-badge {
                position: absolute;
                right: 10px;
                top: 10px;

                .ant-badge-count {
                    background-color: transparent;
                    border: solid 1px $primary-color;
                    color: $primary-color;
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 18px;

                    .ant-scroll-number-only p {
                        color: $primary-color;
                        font-weight: 600;
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .circle-preview-right-content {
        padding: 0 30px 30px;

        .title-block {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
        }

        .circle-memebers-list {
            margin-bottom: 30px;

            .circle-user-pic {
                width: 44px;
                margin-right: 10px;
                height: 44px;
                line-height: 44px;
                margin-bottom: 10px;
            }

            .active {
                @include box-shadow(0 0 0 2px $primary-color);
            }
        }

        .content-wrap {
            margin-bottom: 25px;

            .title-block {
                margin-bottom: 5px;
            }

            p {
                font-size: 14px;
                line-height: 1.4;
                color: $title-font-color;
                margin-bottom: 0;
            }
        }
    }

    @include min-sm {
        .ant-modal-body {
            padding: 0;
        }

        .circle-preview-modal-wrapper {
            @include clearfloat();
        }

        .circle-preview-side-block {
            border-right: 1px solid #ddd;
            float: left;
            width: 280px;
        }

        .circle-preview-right-content {
            margin-left: 279px;
            border-left: 1px solid #ddd;
            padding: 30px;
        }
    }

    @include min-md {
        .circle-preview-side-block {
            width: 350px;
        }

        .circle-preview-right-content {
            margin-left: 349px;
            padding: 30px;
        }
    }
}